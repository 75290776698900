import React from 'react';
import { useState, useEffect } from 'react';
import {
  DataGrid,
  ColDef,
  ValueGetterParams,
  GridColumnHeaderTitle,
  GridColumnHeaderSortIcon
} from '@mui/x-data-grid';
// import data from "./data.json";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';

// import useNavigate from "react-router-dom";
import { Link, useNavigate } from 'react-router-dom';
const columns: ColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 250,
    disableColumnMenu: false,
    headerAlign: 'left',
    align: 'left',
    renderHeader: (params) => {
      const { field, api, colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={colDef.width}
          />
        </>
      );
    },
    // ColumnFilter:
    disableColumnFilter: true,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'teachercode',
    headerName: 'Teacher Code',
    minWidth: 150,
    headerAlign: 'left',
    align: 'left',
    sortable: false,
    disableColumnMenu: true
  },
  // {
  //   field: 'email',
  //   headerName: 'Email ID',
  //   minWidth: 270,
  //   headerAlign: 'left',
  //   align: 'left',
  //   sortable: false,
  //   disableColumnMenu: true
  // },
  // {
  //   field: 'phone',
  //   headerName: 'Phone',
  //   minWidth: 200,
  //   headerAlign: 'left',
  //   align: 'left',
  //   sortable: false,
  //   disableColumnMenu: true
  // },
  {
    field: 'country',
    headerName: 'Country',
    minWidth: 170,
    headerAlign: 'left',
    align: 'left',
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'teachingStatus',
    headerName: 'Teacher Status',
    minWidth: 120,
    headerAlign: 'left',
    align: 'left',
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'action',
    headerName: 'Action',
    minWidth: 100,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    disableColumnMenu: true
    // renderCell: (rowData) => (
    //   <strong>
    //     {/* {console.log(rowData)} */}
    //     <Button
    //       variant="text"
    //       onClick={() => {
    //         // navigate(`/program-types/${rowData.id}/view`);
    //         navigate('/section1/section2/section3', { state: rowData[id] });
    //       }}>
    //       View
    //     </Button>
    //   </strong>
    // )
  }
];
var teachersData = [];
export default function TSP_Data_table(props) {
  const [selection, setselection] = useState([]);
  var navigate = useNavigate();

  var userData = (e) => {
    for (let i = 0; i < props.data.length; i++) {
      if (e == props.data[i].id) {
        navigate('/section1/section2/section3', { state: props.data[i] });
      }
    }
  };
  useEffect(() => {
    teachersData = props.data;
    // setUsersData(location.state);
  });
  const rows = props.data.map((row) => {
    return {
      name: row.firstname + ' ' + row.lastname,
      id: row.id,
      teachercode: row.teachercode,
      email: row.email,
      phone: '+' + row.mobile1,
      country: row.country,
      teachingStatus: row.teacheractivitystatus,
      action: 'View'
    };
  });

  return (
    <div
      style={{
        display: 'flex',
        height: '520px',
        width: '100%',
        alignItems: 'centre'
      }}>
      <DataGrid
        rows={rows}
        columns={columns}
        localeText={{
          noRowsLabel: 'No records found'
        }}
        onSelectionModelChange={userData}
        sx={{
          cursor: 'pointer',

          '& .MuiDataGrid-cell--textCenter': {
            color: 'blue',
            textDecoration: 'underline',
            cursor: 'pointer'
          },

          '.MuiDataGrid-columnSeparator': {
            display: 'none'
          },
          '&.MuiDataGrid-root': {
            border: 'none'
          },
          '.MuiDataGrid-columnHeader': {
            fontSize: 16,
            fontWeight: 'bold'
            // color:"blue"
          },
          '.MuiDataGrid-sortIcon': {
            opacity: 'inherit !important',
            color: 'black'
          },
          '& .MuiDataGrid-iconButtonContainer': {
            visibility: 'visible !important'
          },
          '& .css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
            fontWeight: 700,
            fontSize: '14px'
          },
          '& .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root': {
            color: 'black'
          },
          '& .MuiDataGrid-iconButtonContainer': {
            display: 'none'
          },
          '& .MuiDataGrid-iconButtonContainer1': {
            paddingLeft: '5px',
            color: 'black'
          }
        }}
        hideFooter={true}
      />
    </div>
  );
}

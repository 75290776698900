/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/prop-types */
import React, { useEffect, useCallback } from 'react';
import UiButton from '../../../UiCore/FormComponent/UiButton/UiButton';
import './HomePage.css';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, useLocation } from 'react-router-dom';
import Data_table from '../../Data_table/data_table';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import debounce from 'lodash/debounce';
import { getDataByRegion } from '../../../../assets/apiCalls/ApiHomePage';
import { useQuery } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import AllReg from '../All_register/Alreg';
import { OutlinedInput } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ToolBar from './Toolbar.js';
const regions = [
  'All Regions',
  'Africa',
  'Brazil',
  'Central Asia',
  'Europe',
  'Far East',
  'Middle East',
  'North America',
  'Oceania',
  'Russia',
  'South America',
  'South Asia'
];
const addTotalTeachersFeild = (data) => {
  var temp = data.map((d) => {
    return {
      ...d,
      'Total Teachers':
        parseInt(d.Active || 0) + parseInt(d.Inactive || 0) + parseInt(d.ViewOnly || 0)
    };
  });
  return temp;
};
function HomePage(props) {
  const [quickFilterValue, setQuickFilterValue] = React.useState('');
  const [quickSearch, setQuickSearch] = React.useState('');

  const location = useLocation();
  console.log(location, 'major debug location');
  const searchInput = React.useRef(null);

  const [selectedRegion, setSelectedRegion] = React.useState(
    (location.state && regions.findIndex((region) => region === location.state.selectedRegion)) || 0
  );
  const [selectedRows, setSelectedRows] = React.useState(
    (location.state && location.state.selectedRows) || []
  );

  useEffect(() => {
    setSelectedRegion(0);
    setSelectedRows([]);
    props.setApplyClearFilters(false);
    setQuickFilterValue('');
    setQuickSearch('');
  }, [props.applyClearFilters]);

  useEffect(() => {
    searchInput.current && searchInput.current.focus();
  }, [quickFilterValue]);

  const queryClient = useQueryClient();
  /**
   * fetching data table data using react query.
   */
  const { data: regionData } = useQuery(['region-data', regions[selectedRegion]], getDataByRegion, {
    select: (data) => {
      return addTotalTeachersFeild(data.data);
    }
  });
  const handleChangeSelect = (e) => {
    setSelectedRegion(e.target.value);
    queryClient.invalidateQueries(['region-data']);
  };

  /**
   * @returns it returns a toolbar for home page data table.
   */
  const HomePageToolBar = () => {
    const navigate = useNavigate();
    const delayedSearch = useCallback(
      debounce((q) => setQuickSearch(q), 600),
      []
    );
    return (
      // <div>
      //   <div>
      //     <div className="home-icon">
      //       <div className="home-icon-left-container">
      //         <div>
      //           <h3>Home</h3>
      //         </div>
      //         <div className="slect-dropdown-container">
      //           <Select
      //             sx={{ height: 40 }}
      //             className="select-dropdown"
      //             labelId="demo-simple-select-label"
      //             id="demo-simple-select"
      //             name="region"
      //             value={selectedRegion}
      //             onChange={handleChangeSelect}
      //             defaultValue={10}>
      //             {regions.map((region, index) => (
      //               <MenuItem value={index} key={index}>
      //                 {region}
      //               </MenuItem>
      //             ))}
      //           </Select>

      //           <OutlinedInput
      //             className="quick-search"
      //             // id="outlined-basic"
      //             ref={searchInput}
      //             placeholder="Search By Country"
      //             variant="outlined"
      //             sx={{ marginLeft: '16px', width: '250px' }}
      //             // onChange={(e) => {
      //             //   setQuickFilterValue(e.target.value);
      //             //   delayedSearch(e.target.value);
      //             // }}
      //             // value={quickFilterValue}
      //             startAdornment={<SearchIcon />}
      //           />
      //         </div>
      //       </div>
      //       <div className="home-export-add-container">
      //         <UiButton
      //           text="Export"
      //           onClick={() => {
      //             navigate('/home/export', {
      //               state: {
      //                 selectedRegion: regions[selectedRegion],
      //                 selectedRows: selectedRows
      //               }
      //             });
      //           }}></UiButton>
      //         <div>
      //           <UiButton onClick={() => navigate('/teachers/new')} text={'Add Teacher'}></UiButton>
      //         </div>
      //       </div>
      //     </div>
      //     <hr className="hr-line"></hr>
      //   </div>
      // </div>
      <HomePageToolBarComponent
        selectedRegion={selectedRegion}
        handleChangeSelect={handleChangeSelect}
        selectedRows={selectedRows}
        // quickFilterValue={quickFilterValue}
        // setQuickFilterValue={setQuickFilterValue}
        // setQuickSearch={setQuickSearch}
        // searchInput={searchInput}
      />
    );
  };
  return (
    <div>
      <div className="home-inner-container1">
        <div className="home-inner-container2">
          {/* {regionData ? ( */}
          <Data_table
            data={regionData || []}
            height={550}
            renderToolbarMaterial
            toolBar={HomePageToolBar}
            // toolBar={
            //   <ToolBar
            //     selectedRegion={selectedRegion}
            //     handleChangeSelect={handleChangeSelect}
            //     selectedRows={selectedRows}
            //     quickFilterValue={quickFilterValue}
            //     setQuickFilterValue={setQuickFilterValue}
            //     setQuickSearch={setQuickSearch}
            //     searchInput={searchInput} 
            //   />
            // }
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            quickSearch={quickSearch}
          />
          {/* // ) : (
          //   <div>Loading...</div>
          // )} */}
        </div>
      </div>
      <div className="summary-container">
        <div>
          <AllReg />
        </div>
      </div>
    </div>
  );
}
export default HomePage;

const HomePageToolBarComponent = ({
  selectedRegion,
  handleChangeSelect,
  selectedRows,
  quickFilterValue,
  setQuickFilterValue,
  setQuickSearch,
  searchInput
}) => {
  const navigate = useNavigate();
  const delayedSearch = useCallback(
    debounce((q) => setQuickSearch(q), 600),
    []
  );

  return (
    <div>
      <div>
        <div className="home-icon">
          <div className="home-icon-left-container">
            <div>
              <h3>Home</h3>
            </div>
            <div className="slect-dropdown-container">
              <Select
                sx={{ height: 40 }}
                className="select-dropdown"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="region"
                value={selectedRegion}
                onChange={handleChangeSelect}
                defaultValue={10}>
                {regions.map((region, index) => (
                  <MenuItem value={index} key={index}>
                    {region}
                  </MenuItem>
                ))}
              </Select>
              <GridToolbarQuickFilter
                sx={{ height: '10px !important' }}
                className="home-search"
                placeholder="Search by Country"
                variant="outlined"
              />

              {/* <OutlinedInput
                className="quick-search"
                // id="outlined-basic"
                ref={searchInput}
                placeholder="Search By Country"
                variant="outlined"
                sx={{ marginLeft: '16px', width: '250px' }}
                onChange={(e) => {
                  setQuickFilterValue(e.target.value);
                  delayedSearch(e.target.value);
                }}
                value={quickFilterValue}
                startAdornment={<SearchIcon />}
              /> */}
            </div>
          </div>
          <div className="home-export-add-container">
            <UiButton
              text="Export"
              onClick={() => {
                navigate('/home/export', {
                  state: {
                    selectedRegion: regions[selectedRegion],
                    selectedRows: selectedRows
                  }
                });
              }}></UiButton>
            <div>
              <UiButton onClick={() => navigate('/teachers/new')} text={'Add Teacher'}></UiButton>
            </div>
          </div>
        </div>
        <hr className="hr-line"></hr>
      </div>
    </div>
  );
};

import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import UiButton from 'Components/UiCore/FormComponent/UiButton/UiButton';
import Box from '@mui/material/Box';
import {
  Chip,
  InputBase,
  Tooltip,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';

import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControlLabel from '@mui/material/FormControlLabel';

import Checkbox from '@mui/material/Checkbox';

import { useForm, Controller } from 'react-hook-form';
import PageTemplate from 'Components/UiCore/pageTemplate/PageTemplate';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextareaAutosize from 'react-textarea-autosize';
import healthCategoryMapping from '../healthCategoryMapping.json';

import * as axios from 'axios';
// import config from '../../../.s3Config.json'

function TeachersNewPage(props) {
  const [programTypesData, setProgramTypesData] = React.useState({});
  const [openSnack, setOpenSnack] = React.useState(false);
  const [fieldValidationErrors, setFieldValidationErrors] = useState({});

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: useMemo(() => {
      // console.log('im trigerred',teachersData &&  Object.keys(teachersData.length), teachersData);
      if (programTypesData && Object.keys(programTypesData).length > 0) {
        const basicInfo = programTypesData;
        const defaultValuesData = {
          firstName: basicInfo.firstname
        };
        return defaultValuesData;
      }
    }, [programTypesData])
  });
  const navigate = useNavigate();
  const location = window.location.href;
  const programTypedId = location.split('/').at(-2);

  const [pageLoader, setPageLoader] = useState(true);
  const [org1State, setOrg1State] = useState(false);
  const [org2State, setOrg2State] = useState(false);
  const [org3State, setOrg3State] = useState(false);
  const [isKriyaStateValue, setIsKriyaStateValue] = useState(false);
  const [nameValidationError, setNameValidationError] = React.useState(false);
  const [createdBy, setCreatedBy] = useState(false);
  const [createdDate, setCreatedDate] = useState(false);
  const [formData, setFormData] = useState({});
  const [openNameChangePopUp, setOpenNameChangePopup] = useState(false);

  // useEffect(() => {
  //   if (Object.keys(programTypesData).length > 0) {
  //     const defaultValues = {
  //       programTypeName: programTypesData.ProgramTypeName,
  //       healthCategory: programTypesData.healthcategory,
  //       notes: programTypesData.notes,
  //       org: programTypesData.org.split(','),
  //       IsKriyaTaught: programTypesData.isKriyaTaught === 1 ? 'yes' : 'no'
  //     };

  //     console.log(defaultValues, 'im default value');

  //     reset({ ...defaultValues });
  //   }
  // }, [programTypesData]);

  useEffect(() => {
    const token = sessionStorage.getItem('user');

    let mounted = true;
    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/pgm/viewprogram`, {
        params: {
          id: programTypedId
        },
        headers: { authorization: `Bearer ${token}` }
      })
      .then((response) => {
        const programTypesData = response.data[0];
        setProgramTypesData({
          org: programTypesData.organization,
          programTypeName: programTypesData.programtypename,
          healthCategory: programTypesData.healthcategory,
          notes: programTypesData.notes,
          isKriyaTaught: programTypesData.iskriyataught
        });
        setCreatedBy(programTypesData.createdby);
        setCreatedDate(programTypesData.createddate);
        setPageLoader(false);
      });

    return () => (mounted = false);
  }, []);

  const onSubmit = async (data) => {
    if (
      data.programTypeName !== programTypesData.programTypeName
    ) {
      setOpenNameChangePopup(true);
      setFormData(data);
    } else {
      submitData(data);
    }
  };

  const submitData = async (data) => {
    const token = sessionStorage.getItem('user');
    const entries = Object.entries(data).filter((entry) => entry[0] !== 'files');
    event.preventDefault();

    const orgArray = [];
    if (data.org1) {
      orgArray.push('Org 1');
    }
    if (data.org2) {
      orgArray.push('Org 2');
    }
    if (data.org3) {
      orgArray.push('Org 3');
    }

    let fieldValidationError = false;

    let fieldValidationErrors = {};

    if (orgArray.length === 0) {
      fieldValidationError = true;
      fieldValidationErrors.org = true;
    }

    if (data.programTypeName === '') {
      fieldValidationError = true;
      fieldValidationErrors.programTypeName = true;
    }

    if (!data.healthCategory) {
      fieldValidationError = true;
      fieldValidationErrors.healthCategory = true;
    }

    if (!isKriyaStateValue) {
      fieldValidationError = true;
      fieldValidationErrors.isKriyaTaught = true;
    }
    console.log(fieldValidationErrors, data, 'payload debug');

    setFieldValidationErrors(fieldValidationErrors);
    if (!fieldValidationError) {
      const payload = {
        Organization: orgArray.toString(),
        ProgramType: 'Course Type',
        ProgramTypeName: data.programTypeName.trim(),
        HealthCategory: data.healthCategory,
        Notes: data.notes,
        id: programTypedId,
        IsKriyaTaught: data.isKriyaTaught === 'yes' || data.isKriyaTaught === 1 ? 1 : 0,
        createdby: createdBy,
        createddate: createdDate,
        HealthCategoryType: healthCategoryMapping[data.healthCategory]
      };

      console.log(data, payload, isKriyaStateValue, 'payload');
      setNameValidationError(false);

      axios
        .put(
          `${process.env.REACT_APP_BASE_API_URL}/pgm/editprogram?id=${programTypedId}`,
          payload,
          { headers: { authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          if (response.data.name !== 'error') {
            navigate(`/program-types/${programTypedId}/view`);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setNameValidationError(true);
            setOpenNameChangePopup(false);
          }
        });
    }
  };

  useEffect(() => {
    if (Object.keys(programTypesData).length > 0) {
      // let org1 = programTypesData.org.split(',').find((i) => i === 'Org 1') ? true: false;
      // let org2 = programTypesData.org.split(',').find((i) => i === 'Org 2') ? true: false;
      // let org3 = programTypesData.org.split(',').find((i) => i === 'Org 3') ? true: false;
      if (programTypesData.org.split(',').find((i) => i === 'Org 1')) {
        setOrg1State(true);
      }
      if (programTypesData.org.split(',').find((i) => i === 'Org 2')) {
        setOrg2State(true);
      }
      if (programTypesData.org.split(',').find((i) => i === 'Org 3')) {
        setOrg3State(true);
      }

      const defaultValues = {
        // org1,
        // org2,
        // org3,
        programTypeName: programTypesData.programTypeName,
        healthCategory: programTypesData.healthCategory,
        notes: programTypesData.notes
        // isKriyaTaught: programTypesData.isKriyaTaught === 1 ? 'yes' : 'no'
      };

      setIsKriyaStateValue(programTypesData.isKriyaTaught === 1 ? 'yes' : 'no');
      reset({ ...defaultValues });
    }
  }, [programTypesData]);

  useEffect(() => {
    reset(programTypesData);
  }, [programTypesData]);

  return (
    <div>
      <PageTemplate tabBar navbar>
        {Object.keys(programTypesData).length > 0 && (
          <Box sx={{ width: '100%', marginTop: '40px' }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* <Paper
              sx={{ paddingLeft: '16px' }}
              className="navbar-full-container-2"
              variant="outlined"
              elevation={9}>


            </Paper> */}
              <Paper sx={{ paddingBottom: '40px', marginLeft: '10px', marginRight: '10px' }}>
                <Grid sx={{ marginLeft: '32px', paddingTop: '32px' }}>
                  <Grid contaier sx={{ marginTop: '16px', marginBottom: '16px' }}>
                    <h2>Edit Program Type </h2>
                  </Grid>
                </Grid>
                <hr className="hr-line"></hr>
                <Grid container sx={{ marginTop: '0px', marginLeft: '40px' }} spacing={2}>
                  <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    Organization <span className="required">*</span>
                  </Grid>
                  <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
                    <span>
                      <RadioGroup
                        sx={{ width: 450, marginLeft: 0 }}
                        row
                        {...register('org')}
                        required="true"
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group">
                        <FormControlLabel
                          value="Org 1"
                          {...register('org1')}
                          control={
                            <Checkbox
                              onChange={(e, value) => {
                                setOrg1State(value);
                              }}
                              checked={org1State}
                            />
                          }
                          label="Org 1"
                        />
                        <FormControlLabel
                          value="Org 2"
                          {...register('org2')}
                          control={
                            <Checkbox
                              onChange={(e, value) => setOrg2State(value)}
                              checked={org2State}
                            />
                          }
                          label="Org 2"
                        />
                        <FormControlLabel
                          value="Org 3"
                          {...register('org3')}
                          control={
                            <Checkbox
                              onChange={(e, value) => setOrg3State(value)}
                              checked={org3State}
                            />
                          }
                          label="Org 3"
                        />
                      </RadioGroup>
                    </span>
                    <span style={{ color: 'red', marginLeft: '16px' }}>
                      {fieldValidationErrors.org &&
                        !org1State &&
                        !org2State &&
                        !org3State &&
                        'Required'}
                    </span>
                  </Grid>
                </Grid>
                <Grid container sx={{ marginTop: '0px', marginLeft: '40px' }} spacing={2}>
                  <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    Program Type
                  </Grid>
                  <Grid item xs={6}>
                    Course Type
                  </Grid>
                </Grid>
                <Grid container sx={{ marginTop: '0px', marginLeft: '40px' }} spacing={2}>
                  <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    Program Type Name <span className="required">*</span>
                  </Grid>
                  <Grid item xs={6}>
                    <span>
                      {' '}
                      <OutlinedInput
                        {...register('programTypeName')}
                        id="programTypeName"
                        defaultValue={programTypesData.programTypeName}
                        type="text"
                        name="programTypeName"
                        placeholder="Enter Program Type Name"
                        sx={{ width: 450, marginLeft: 0 }}
                        size="small"
                      />
                    </span>
                    <span style={{ color: 'red', marginLeft: '16px' }}>
                      {fieldValidationErrors.programTypeName &&
                        watch('programTypeName') === '' &&
                        'Required'}
                      {nameValidationError && 'Course Name Already Exists'}
                    </span>
                  </Grid>
                </Grid>
                <Grid container sx={{ marginTop: '0px', marginLeft: '40px' }} spacing={2}>
                  <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    Health Category <span className="required">*</span>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      id="healthCategory"
                      // value={age}
                      {...register('healthCategory')}
                      sx={{ width: 450, marginLeft: 0 }}
                      size="small"
                      defaultValue={programTypesData.healthCategory}
                      required="true"
                      // onChange={handleChange}
                      label="Enter Health Category">
                      {Object.keys(healthCategoryMapping).map((hcat) => {
                        return (
                          <MenuItem key={hcat} value={hcat}>
                            {' '}
                            {hcat} - Type {healthCategoryMapping[hcat]}{' '}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                </Grid>{' '}
                <Grid container sx={{ marginTop: '0px', marginLeft: '40px' }} spacing={2}>
                  <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    Is Kriya Taught <span className="required">*</span>
                  </Grid>
                  <Grid item xs={6}>
                    <RadioGroup
                      sx={{ width: 450, marginLeft: 0 }}
                      row
                      {...register('isKriyaTaught')}
                      onChange={(e) => {
                        setIsKriyaStateValue(e.target.value);
                      }}
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      value={isKriyaStateValue}
                      name="row-radio-buttons-group">
                      <FormControlLabel
                        value="yes"
                        {...register('isKriyaTaught')}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        {...register('isKriyaTaught')}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
                <Grid container sx={{ marginTop: '0px', marginLeft: '40px' }} spacing={2}>
                  <Grid item xs={2} sx={{ display: 'flex', alignItems: 'top' }}>
                    Notes
                  </Grid>
                  <Grid item xs={6}>
                    <TextareaAutosize
                      {...register('notes')}
                      placeholder="Enter Notes"
                      name="notes"
                      id="notes"
                      minRows={3}
                      style={{ width: 450, marginLeft: 0 }}
                    />
                  </Grid>
                </Grid>
                <Snackbar
                  sx={{ marginBottom: '80px', marginLeft: '32px' }}
                  message={'Fill all the Required Fields'}
                  open={openSnack}
                  onRequestClose={() => setOpenSnack(false)}
                  autoHideDuration={200}
                  action={
                    <React.Fragment>
                      <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => setOpenSnack(false)}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </React.Fragment>
                  }></Snackbar>
                <Grid container spacing={2} sx={{ marginTop: '16px', marginLeft: '40px' }}>
                  <Grid item>
                    <Grid item>
                      <Button type="submit" variant="contained">
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Button
                      sx={{ bottom: 0 }}
                      onClick={() => navigate('/program-types')}
                      variant="text">
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </form>
            <Dialog
              open={openNameChangePopUp}
              onClose={() => setOpenNameChangePopup(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth={'xs'}>
              <DialogContent style={{ padding: '25px', paddingBottom: '0px' }}>
                <DialogContentText>
                If you change Course Type name for existing Course Type present in teacher profile, existing Course Type will get deleted from "Add/Edit Teacher Country". Are you sure you want to continue?
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ padding: '15px' }}>
                <Link
                  // to={link_url}
                  style={{ textDecoration: 'none' }}>
                  <Button
                    variant="contained"
                    style={{ paddingLeft: '20px', paddingRight: '20px' }}
                    onClick={() => submitData(formData)}
                    autoFocus>
                    Yes
                  </Button>
                  <Button
                    variant="contained"
                    style={{ paddingLeft: '20px', paddingRight: '20px', marginLeft: '12px' }}
                    onClick={() => setOpenNameChangePopup(false)}
                    autoFocus>
                    No
                  </Button>
                </Link>
              </DialogActions>
            </Dialog>
          </Box>
        )}
      </PageTemplate>
    </div>
  );
}

export default TeachersNewPage;

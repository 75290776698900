import styles from '../Global.module.css';
import AOLlogo from '../AOLlogo.png';
import React, { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment } from '@mui/material';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import Form from '../../../UiCore/FormComponent/FormFeild/FormFeild';
import InputFormFeild from '../../../UiCore/FormComponent/InputFormFeild/InputFormFeild';
import CheckBoxFeild from '../../../UiCore/FormComponent/CheckBoxFeild/CheckBoxFeild';
import UiButton from '../../../UiCore/FormComponent/UiButton/UiButton';
import * as axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { blue } from '@mui/material/colors';
import './index.css';

var msg, link_url;
const Login = () => {
  var navigate = useNavigate();

  //for dialogue box
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [emailId, setEmailId] = React.useState('');
  const [passwordId, setPasswordId] = React.useState('');

  let onSubmit = (data) => {
    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/ad/findone`, {
        params: { email: data.Username.toLowerCase(), password: data.Password }
      })
      .then((response) => {
        {
          if (response.data.message === 'yes') {
            const token = response.data.token;
            sessionStorage.setItem('user', token);
            sessionStorage.setItem('username', response.data.username);

            //Redirect to home page
            navigate('/home');
          } else {
            msg = 'Invalid username or password';
            link_url = '/login';
            setOpen(true);
          }
        }
      });
  };

  const loginSchema = yup.object().shape({
    Username: yup.string().required('Username is required'),
    Password: yup.string().required('Password is required')
  });
  const [show, setShow] = useState(true);
  return (
    <div className={styles.outerContainer}>
      <div className={styles.container}>
        <img src={AOLlogo} className={styles.img} />
        <div className={styles.heading}>Login</div>
        <Form onSubmit={onSubmit} schema={loginSchema}>
          <label className={styles.label}>USERNAME</label>
          <InputFormFeild
            name="Username"
            className={styles.input_sm}
            variant={'outlined'}
            onChange={(e) => setEmailId(e.target.value)}
            value={emailId}
          />
          <label className={styles.label}>PASSWORD</label>
          <InputFormFeild
            name="Password"
            type={show ? 'password' : 'text'}
            className={styles.input_sm}
            variant={'outlined'}
            onChange={(e) => setPasswordId(e.target.value)}
            value={passwordId}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShow(!show)}>
                    {show ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <br />
          <Link
            to="/forgot-password"
            style={{
              textDecoration: 'none',
              color: 'blue',
              marginRight: 'auto',
              marginTop: '10px',
              color: '#94a6fb',
              fontWeight: '600'
            }}>
            {/* {" "} */}
            <div className={styles.link}>Forgot password?</div>
          </Link>
          <Button
            type="submit"
            variant="contained"
            style={{
              marginTop: '20px',
              marginBottom: '20px',
              height: '50px',
              fontWeight: '700',
              textTransform: 'uppercase'
            }}
            className={styles.button}>
            Log&nbsp;In
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={'xs'}>
            <DialogContent style={{ padding: '25px', paddingBottom: '0px' }}>
              <DialogContentText>{msg}</DialogContentText>
            </DialogContent>
            <DialogActions style={{ padding: '15px' }}>
              <Link to={link_url} style={{ textDecoration: 'none' }}>
                <Button
                  variant="contained"
                  style={{ paddingLeft: '20px', paddingRight: '20px' }}
                  onClick={handleClose}
                  autoFocus>
                  Ok
                </Button>
              </Link>
            </DialogActions>
          </Dialog>
        </Form>
      </div>
    </div>
  );
};
export default Login;

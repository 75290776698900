import React, { useEffect } from 'react';
import image from '../../assets/images/profile.jpeg';
import Navbar from '../../UiCore/TSP_Navbar/TSP_Navbar';
import './TSP_Section3.css';
import UiButton from '../../UiCore/FormComponent/UiButton/UiButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function TSP_Section3(props) {
  let location = useLocation();
  let navigate = useNavigate();
  function handleClick() {
    navigate(-1);
  }
  useEffect(() => {
    // setUsersData(location.state);
    // console.log("my ud",usersData);
  });
  return (
    <div className="s3-outer-container">
      <Navbar />

      <div className="s3-inner-container1">
        <div className="s3-tabbar">
          <div className="s3-home">
            <Link to="/tsp-section1" style={{ textDecoration: 'none', color: 'black' }}>
              <p>Home</p>
            </Link>
          </div>
        </div>
        <div className="s3-tm">
          <div className="s3-tm-inner-container">
            <div>
              <h2>{location.state.firstname + ' ' + location.state.lastname}</h2>
            </div>
            <div className="s3-search-teacher">
              <div className="s3-search-teacher-inner-container">
                <div className="s3-st-text">
                  <p>Name</p>
                  <p>Teacher Code</p>
                  <p>Country</p>
                  <p>Teacher Status</p>
                </div>
                <div className="s3-st-input">
                  <p>{location.state.firstname + ' ' + location.state.lastname}</p>
                  <p>{location.state.teachercode}</p>
                  <p>{location.state.country}</p>
                  <p>{location.state.teacheractivitystatus}</p>
                </div>
                <div className="s3-profile-pic">
                  <img
                    src={`https://tptm-aold.s3.ap-south-1.amazonaws.com/${location.state.uploadphoto}`}
                    alt="Profile pic"
                    style={{ height: 300, width: 300 }}></img>
                </div>
              </div>
              <div className="s3-go-back-button">
                {/* <Link to="/section1/section2" > */}
                <UiButton
                  text="Go back"
                  type="submit"
                  // className={styles.button}
                  onClick={handleClick}></UiButton>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TSP_Section3;

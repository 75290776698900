import { request } from '../../Utils/axios-utils.ts';

export const getDataByRegion = ({ queryKey }) => {
  const token = sessionStorage.getItem('user');
  const region = queryKey[1];
  return request({
    url: '/home/countriesdata',
    method: 'GET',
    params: { region: region },
    headers: { authorization: `Bearer ${token}` }
  });
};

export const getAllRegionSummaryData = () => {
  const token = sessionStorage.getItem('user');
  return request({
    url: '/home/masterdata',
    method: 'GET',
    headers: { authorization: `Bearer ${token}` }
  });
};

export const regionCountryData = () => {
  const token = sessionStorage.getItem('user');
  return request({
    url: '/home/regions',
    method: 'GET',
    headers: { authorization: `Bearer ${token}` }
  });
};

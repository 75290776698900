import React, { useEffect, useState } from 'react';
import image from '../../assets/images/profile.jpeg';
import Navbar from '../../UiCore/TSP_Navbar/TSP_Navbar';
import './TSP_Section2.css';
import UiButton from '../../UiCore/FormComponent/UiButton/UiButton';
import TSP_Data_table from '../TSP_Data_Table/TSP_data_table.js';
import { Link, useLocation } from 'react-router-dom';

function TSP_Section2(props) {
  const [usersData, setUsersData] = useState([]);
  // console.log(props);
  let location = useLocation();
  useEffect(() => {
    setUsersData(location.state);
    // console.log("location data",location.state);
  });
  return (
    <div className="s2-outer-container">
      <Navbar />

      <div className="s2-inner-container1">
        <div className="s2-tabbar">
          <div className="s2-home">
            <Link to="/tsp-section1" style={{ textDecoration: 'none', color: 'black' }}>
              <p>Home</p>
            </Link>
          </div>
        </div>
        <div className="s2-tm">
          <div className="s2-tm-inner-container">
            <div>
              <h2>Teacher Management</h2>
            </div>
            <div className="s2-tm-table-container">
              <TSP_Data_table data={usersData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TSP_Section2;

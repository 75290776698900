import React, { useEffect, useState, useCallback } from 'react';
import HomePage from '../../Components/Templates/Home/HomePage/HomePage';
import PageTemplate from '../../Components/UiCore/pageTemplate/PageTemplate';
function Home() {
  const [applyClearFilters, setApplyClearFilters] = React.useState(false);



  return (
    <div>
      <PageTemplate tabBar navbar applyClearFilters={() => setApplyClearFilters(true)}>
        <HomePage
          setApplyClearFilters={(val) => setApplyClearFilters(val)}
          applyClearFilters={applyClearFilters}
        />
      </PageTemplate>
    </div>
  );
}
export default Home;

import Export from "../../Components/Templates/Home/ExportPage/Export";
import PageTemplate from "../../Components/UiCore/pageTemplate/PageTemplate";
const ExportPage = () => {
  return (
    <PageTemplate navbar tabBar>
      <Export />
    </PageTemplate>
  );
};

export default ExportPage;

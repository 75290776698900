/* eslint-disable react/prop-types */
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import * as axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import './styles.css';
import { Menu, MenuItem } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

function format(inputDate) {
  let date, month, year;

  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;
  year = inputDate.getFullYear();

  date = date.toString().padStart(2, '0');

  month = month.toString().padStart(2, '0');

  return `${date}-${month}-${year}`;
}

export default function Data_table(props) {
  console.log(props, 'export props');
  const [pageLoader, setPageLoader] = useState(true);
  const [listOrder, setListOrder] = React.useState('ASC');

  const [teachersData, setTeachersData] = React.useState([]);
  const [unfilteredTeachersData, setUnfilteredTeachertsData] = React.useState([]);
  const token = sessionStorage.getItem('user');
  const navigate = useNavigate();
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isParentSelectedRows, setIsParentSelectedRows] = useState(true);

  function handleSortHover(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  useEffect(() => {
    if (textElementRef.current) {
      setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
    }
  }, [pageLoader]);
  const [countryNameData, setCountryNameData] = React.useState([]);
  const countryNameId = (id) =>{
   const c_name = countryNameData.find(
                              (item) => item.countryid == id
                            ).countryname;
    return c_name;
  }
  useEffect(()=>{
    const token = sessionStorage.getItem('user');

    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/teachers/countries`, {
        headers: { authorization: `Bearer ${token}` }
      })
      .then((response) => {
        setCountryNameData(response.data);
      })
      .catch((error) => console.error(error));
  },[])

  useEffect(() => {
    const token = sessionStorage.getItem('user');
    let mounted = true;
    axios
      .get(
        `${process.env.REACT_APP_BASE_API_URL}/teachers/findall`,

        { headers: { authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setTeachersData(
          response.data.sort((a, b) => {
            const nameA = a.basicinfo.firstname.toUpperCase(); // ignore upper and lowercase
            const nameB = b.basicinfo.firstname.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          })
        );
        // setTeachersData(response.data);
        setUnfilteredTeachertsData(response.data);
        setPageLoader(false);
        setIsParentSelectedRows(false);
      });
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    var dupTeachersData = unfilteredTeachersData;
    var finalDataObj = [];

    setPageLoader(true);

    var filters = props.filters;

    if (Object.keys(filters).length) {
      dupTeachersData.forEach((el) => {
        var status = true;

        // name filter

        if (
          !(el.basicinfo.firstname.toLowerCase() + el.basicinfo.lastname.toLowerCase()).includes(
            filters.name.toLowerCase().split(' ').join('')
          )
        ) {
          status = false;
        }

        // alias filter

        if (
          !(el.basicinfo.aliasname1.toLowerCase() + el.basicinfo.aliasname2.toLowerCase()).includes(
            filters.alias.toLowerCase().split(' ').join('')
          )
        ) {
          status = false;
        }

        // emailID

        if (!el.basicinfo.email.toLowerCase().includes(filters.emailID.toLowerCase())) {
          status = false;
        }

        // teacher code

        if (
          el.basicinfo.teachercode
            ? !el.basicinfo.teachercode.toLowerCase().includes(filters.teacherCode.toLowerCase())
            : false
        ) {
          status = false;
        }

        // harmony code

        if (
          el.basicinfo.harmonycode
            ? !el.basicinfo.harmonycode.includes(filters.harmonyTeacherCode)
            : false
        ) {
          status = false;
        }

        // current country

        if (filters.currentCountry.length !== 0) {
          if (!filters.currentCountry.includes('All Countries')) {
            if (!filters.currentCountry.includes(el.basicinfo.country)) {
              status = false;
            }
          }
        }

        // ttp country

        if (filters.ttpCountry.length !== 0) {
          if (!filters.ttpCountry.includes('All Countries')) {
            let arrayOfTTPCountries = [];
            el?.Courseinfo?.map((singleCourse) => {
              arrayOfTTPCountries.push(singleCourse.ttpapplicationcountry);
            });
            let contains = false;
            filters.ttpCountry.forEach((filterCountry) => {
              if (arrayOfTTPCountries.includes(filterCountry)) {
                contains = true;
              }
            });
            status = contains;
          }
        }

        // teaching country

        if (filters.teachingCountry.length !== 0) {
          if (!filters.teachingCountry.includes('All Countries')) {
            let arrayOfTeaachingCountries = [];
            el?.Countries?.map((singleCountry) => {
              arrayOfTeaachingCountries.push(singleCountry.countryname);
            });
            let contains = false;
            filters.teachingCountry.forEach((filterCountry) => {
              if (arrayOfTeaachingCountries.includes(filterCountry)) {
                contains = true;
              }
            });
            status = contains;
          }
        }

        // course types

        if (filters.courseTypes.length !== 0) {
          if (!filters.courseTypes.includes('All Courses')) {
            let arrayOfCourses = [];
            el?.Courseinfo?.map((singleCourse) => {
              arrayOfCourses.push(singleCourse.coursetype);
            });
            let contains = false;
            filters.courseTypes.forEach((filterCourses) => {
              if (arrayOfCourses.includes(filterCourses)) {
                contains = true;
              }
            });
            status = contains;
          }
        }

        // Certification Type

        let certificationTypes = [];
        for (const key in filters.certificationType) {
          if (filters.certificationType[key]) {
            certificationTypes.push(key);
          }
        }

        if (certificationTypes.length !== 0) {
          let arrayOfCertifications = [];
          el?.Courseinfo?.map((singleCourse) => {
            arrayOfCertifications.push(singleCourse.ttpcertificationtype);
          });
          let contains = false;
          certificationTypes.forEach((filterCertificationTypes) => {
            if (arrayOfCertifications.includes(filterCertificationTypes)) {
              contains = true;
            }
          });
          status = contains;
        }

        // Teacher Status

        let teacherStatus = [];
        for (const key in filters.teacherStatus) {
          if (filters.teacherStatus[key]) {
            teacherStatus.push(key);
          }
        }

        //  need to add case insensitiveness hence the split and join
        // need to have teacherActivitySubstatus as well hence the ternary operator

        if (teacherStatus.length !== 0) {
          if (
            !teacherStatus
              .join('###')
              .toLocaleLowerCase()
              .split('###')
              .includes(
                el.basicinfo.teacheractivitysubstatus
                  ? el.basicinfo.teacheractivitystatus.toLocaleLowerCase() +
                      ' - ' +
                      el.basicinfo.teacheractivitysubstatus
                  : el.basicinfo.teacheractivitystatus.toLocaleLowerCase()
              )
          ) {
            status = false;
          }
        }

        // Teacher Type

        let teacherTypes = [];
        for (const key in filters.teacherType) {
          if (filters.teacherType[key]) {
            teacherTypes.push(key == 'Full Time' ? 1 : 0);
          }
        }

        if (teacherTypes.length !== 0) {
          if (!teacherTypes.includes(el.basicinfo.fulltimeofficebearer)) {
            status = false;
          }
        }

        // Teacher agreement signed

        let agreementSigned = [];
        for (const key in filters.agreementSigned) {
          if (filters.agreementSigned[key]) {
            agreementSigned.push(key == 'Yes' ? 1 : 0);
          }
        }

        if (agreementSigned.length !== 0) {
          if (!agreementSigned.includes(el.basicinfo.isteacheragreementsigned)) {
            status = false;
          }
        }

        // signed before filter

        if (filters.agreementSignedOn !== '') {
          const d1 = new Date(filters.agreementSignedOn);
          const d2 = new Date(
            el.basicinfo.teacheragreementsignedon &&
              el.basicinfo.teacheragreementsignedon.substring(0, 10)
          );
          if (!(d1 < d2)) {
            status = false;
          }
        }

        // expired on

        if (filters.agreementExpiresOn !== '') {
          const d1 = new Date(filters.agreementExpiresOn);
          const d2 = new Date(
            el.basicinfo.teacheragreementexpiredon &&
              el.basicinfo.teacheragreementexpiredon.substring(0, 10)
          );
          if (!(d1 < d2)) {
            status = false;
          }
        }

        let defaultFilters = {
          name: '',
          alias: '',
          emailID: '',
          teacherCode: '',
          harmonyTeacherCode: '',
          currentCountry: 'All Countries',
          ttpCountry: 'All Countries',
          teachingCountry: 'All Countries',
          courseTypes: 'All Courses',
          certificationType: {
            Certified: false,
            'Certified Online': false,
            'Co-teach': false,
            'Moved out of country': false
          },
          teacherStatus: {
            Active: false,
            'Inactive - Deceased': false,
            'Inactive - Not with Org': false,
            'Inactive - Suspended': false,
            'Inactive - Returned Kriya Tape and Manual': false,
            'Inactive not teaching': false
          },
          teacherType: {
            'Full Time': false,
            'Part Time': false
          },
          agreementSigned: {
            Yes: false,
            No: false
          },
          agreementSignedOn: '',
          agreementExpiresOn: ''
        };

        if (JSON.stringify(defaultFilters) == JSON.stringify(filters)) {
          status = true;
        }

        if (status) {
          finalDataObj.push(el);
        }
      });
    } else {
      finalDataObj = unfilteredTeachersData;
    }

    setTimeout(() => {
      setPageLoader(false);
    }, 800);
    setTeachersData(finalDataObj);
  }, [props.filters]);

  const numberToBoolean = (num) => {
    return num == 1 ? true : false;
  };

  // var regionsData=()

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1.3,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => {
        return (
          <span className="MuiDataGrid-columnHeaderTitle css-cc8tf1" >
            Name{' '}
            <IconButton>
              {' '}
              <UnfoldMoreIcon onMouseOver={handleSortHover} />{' '}
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{ marginTop: '8px' }}
                MenuListProps={{
                  onMouseLeave: handleClose
                  // onClick: () => {
                  //   setTeachersData(
                  //     teachersData.sort((a, b) => {
                  //       const nameA = a.basicinfo.firstname.toUpperCase(); // ignore upper and lowercase
                  //       const nameB = b.basicinfo.firstname.toUpperCase(); // ignore upper and lowercase
                  //       if (nameA < nameB) {
                  //         return -1;
                  //       }
                  //       if (nameA > nameB) {
                  //         return 1;
                  //       }

                  //       // names must be equal
                  //       return 0;
                  //     })
                  //   );
                  // }
                }}>
                <MenuItem
                  sx={
                    listOrder === 'ASC'
                      ? {
                          '&:hover': {
                            backgroundColor: '#1976D2'
                          },
                          '&': {
                            backgroundColor: '#1976D2'
                          }
                        }
                      : {
                          '&:hover': {
                            backgroundColor: '#1976D2'
                          }
                        }
                  }
                  onClick={() => {
                    setTeachersData(
                      teachersData.sort((a, b) => {
                        const nameA = a.basicinfo.firstname.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.basicinfo.firstname.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                          return -1;
                        }
                        if (nameA > nameB) {
                          return 1;
                        }

                        // names must be equal
                        return 0;
                      })
                    );
                    setListOrder('ASC');
                  }}>
                  A <ArrowForwardIcon sx={{ transform: 'scale(0.8,0.8)' }} /> Z
                </MenuItem>
                <MenuItem
                  sx={
                    listOrder === 'DESC'
                      ? {
                          '&:hover': {
                            backgroundColor: '#1976D2'
                          },
                          '&': {
                            backgroundColor: '#1976D2'
                          }
                        }
                      : {
                          '&:hover': {
                            backgroundColor: '#1976D2'
                          }
                        }
                  }
                  onClick={() => {
                    setTeachersData(
                      teachersData.sort((a, b) => {
                        const nameA = a.basicinfo.firstname.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.basicinfo.firstname.toUpperCase(); // ignore upper and lowercase
                        if (nameA > nameB) {
                          return -1;
                        }
                        if (nameA < nameB) {
                          return 1;
                        }

                        // names must be equal
                        return 0;
                      })
                    );
                    setListOrder('DESC');
                  }}>
                  {' '}
                  Z <ArrowForwardIcon sx={{ transform: 'scale(0.8,0.8)' }} /> A
                </MenuItem>
              </Menu>
            </IconButton>
          </span>
        );
      }
    },
    {
      field: 'code',
      headerName: 'Teacher Code',
      flex: 1.5,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      disableColumnMenu: true
    },
    {
      headerName: 'Teaching Country(ies)',
      field: 'countries',
      flex: 2,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      disableColumnMenu: true,

      renderCell: (rowData, data) => {
        return (
          <>
            <Tooltip
              title={
                rowData &&
                rowData.formattedValue.map((item, i) => {
                  if (i !== rowData.formattedValue.length - 1) return `${item}, `;
                  return item;
                })
              }>
              <div
                ref={textElementRef}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                {rowData.formattedValue &&
                  rowData.formattedValue.map((item, i) => {
                    if (i !== rowData.formattedValue.length - 1) return `${item}, `;
                    return item;
                  })}
              </div>
            </Tooltip>
          </>
        );
      }
    },
    {
      headerName: 'Teacher Activity Status',
      field: 'status',
      flex: 2,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      disableColumnMenu: true
    },
    {
      headerName: 'Email ID',
      field: 'email',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (rowData) => {
        return (
          <Tooltip
            title={rowData.formattedValue}
            // disableHoverListener={!isOverflowed}
          >
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>
              {rowData.formattedValue}
            </div>
          </Tooltip>
        );
      }
    },
    {
      headerName: 'DOB',
      field: 'dob',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (rowData) => {
        const result = format(new Date(rowData.formattedValue));
        return result;
      }
    },

    {
      headerName: 'Country',
      field: 'country',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (rowData) => {
        return (
          <Tooltip
            title={rowData.formattedValue}
            // disableHoverListener={!isOverflowed}
          >
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>
              {rowData.formattedValue}
            </div>
          </Tooltip>
        );
      }
    }

    // {
    //   headerName: 'Actions',
    //   field: 'Actions',
    //   flex: 1,
    //   headerAlign: 'center',
    //   align: 'center',
    //   sortable: false,
    //   disableColumnMenu: true,
    //   renderCell: (rowData) => (
    //     <strong>
    //       <Button
    //         variant="text"
    //         onClick={() => {
    //           navigate(`/teachers/${rowData.id}/view`);
    //         }}>
    //         View
    //       </Button>
    //       <Button
    //         onClick={() => {
    //           navigate(`/teachers/${rowData.id}/edit`);
    //         }}
    //         variant="text">
    //         Edit
    //       </Button>
    //     </strong>
    //   )
    // }
  ];
  const rows = teachersData.map((row) => {
    return {
      name: row.basicinfo.firstname,
      code: row.basicinfo.teachercode,
      country:countryNameId(row.basicinfo.country),

      countries: row.Countries.map((item) => countryNameId(item.countryname)),
      status: row.basicinfo.teacheractivitystatus,
      type: row.basicinfo.fulltimeofficebearer === 1 ? 'Full Time' : 'Part Time',
      travelling: row.basicinfo.istravellingteacher === 1 ? 'Yes' : 'No',
      id: row.basicinfo.id,
      email: row.basicinfo.email,
      dob: row.basicinfo.dob.substring(0, 10)

      // name: row.firstname,
      // code: row.teachercode,
      // country: row.country,
      // status: row.teacheractivitystatus,
      // type: row.fulltimeofficebearer === 1 ? 'Full Time' : 'Part Time',
      // travelling: row.istravellingteacher === 1 ? 'Yes' : 'No',
      // id: row.id
    };
  });

  let filteredRows = rows;

  useEffect(() => {
    let dupRows = [];
    rows.forEach((row) => {
      if (
        props.filters.name
          ? row.name == props.filters.name
          : true && props.filters.alias
          ? row.alias == props.filters.alias
          : true && props.filters.emailID
          ? row.emailID == props.filters.alias
          : true
      ) {
        dupRows.push(row);
      }
    });
    filteredRows = dupRows;
  }, [props.filters]);

  return (
    <div
      style={{
        height: props.height ? props.height : '600px',
        // height: '600px',
        width: '100%'
        // overflowY: "scroll",
      }}>
      <DataGrid
        rows={filteredRows}
        columns={columns}
        components={{
          Toolbar: props.toolBar
        }}
        // pageSize={5}
        // rowsPerPageOptions={[10]}
        localeText={{
          noRowsLabel: 'No records found'
        }}
        loading={pageLoader}
        checkboxSelection
        selectionModel={props.selectionModel}
        initialState={{ pinnedColumns: { right: ['Actions'] } }}
        onSelectionModelChange={(data) => {
          console.log(data);
          !isParentSelectedRows && props.onSelectionModelChange(data, teachersData);
        }}
        sx={{
          '.MuiDataGrid-columnSeparator': {
            display: 'none'
          },
          '&.MuiDataGrid-root': {
            border: 'none'
          },
          '.MuiDataGrid-columnHeader': {
            fontSize: 16,
            fontWeight: 'bold'
          },
          '.MuiDataGrid-sortIcon': {
            opacity: 'inherit !important',
            color: 'black'
          },
          '& .MuiDataGrid-iconButtonContainer': {
            visibility: 'visible !important'
          },
          '& .css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
            fontWeight: 700,
            fontSize: '14px'
          },
          '& .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root': {
            color: 'black'
          },
          '& .MuiDataGrid-iconButtonContainer': {
            display: 'none'
          },
          '& .MuiDataGrid-iconButtonContainer1': {
            paddingLeft: '5px',
            color: 'black'
          }
        }}
        hideFooter={false}
        selectedRowCount="25"
      />
    </div>
  );
}

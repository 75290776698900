import React, { useState } from 'react';
import './TSP_Section1.css';
import UiButton from '../../UiCore/FormComponent/UiButton/UiButton';
import TextField from '@mui/material/TextField';
import { Height } from '@mui/icons-material';
import Navbar from '../../UiCore/TSP_Navbar/TSP_Navbar';
import * as axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import TSP_Section2 from '../TSP_Section2/TSP_Section2';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { blue } from '@mui/material/colors';

function TSP_Section1(props) {
  var [inputData, setInputData] = useState('');
  var [data, setData] = useState([]);
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    window.location.reload(false);
  };

  let onSearch = () => {
    // console.log(data);
    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/teachers/teachersearch`, {
        params: { value: inputData.toLowerCase() }
      })
      .then((response) => {
        if (response.data.length) {
          setData(response.data);
          navigate('/section1/section2', { state: response.data });
        } else {
          setOpen(true);
        }
      });
  };

  return (
    <div className="s1-outer-container">
      <Navbar />

      <div className="s1-inner-container1">
        <div className="s1-tabbar">
          <div className="s1-home">
            <Link to="/tsp-section1" style={{ textDecoration: 'none', color: 'black' }}>
              <p>Home</p>
            </Link>
          </div>
        </div>
        <div className="s1-tm">
          <div className="s1-tm-inner-container">
            <div>
              <h2>Teacher Management</h2>
            </div>
            <div className="s1-search-teacher">
              <div className="s1-search-teacher-inner-container">
                <div className="s1-st-text">
                  <p>
                    Search Teacher<sup style={{ color: 'red' }}>*</sup>
                  </p>
                </div>
                <div className="s1-st-input">
                  <div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Search by Teacher ID / Name / Email ID / Phone No."
                      type="search"
                      variant="outlined"
                      sx={{
                        height: '10px',
                        width: '500px',
                        '& .MuiInputBase-root': { height: 50 }
                      }}
                      onChange={(e) => {
                        setInputData(e.target.value);
                        // console.log(inputData);
                      }}
                    />

                    {/* <input className='s1-input-feild' type="text" placeholder='Search by Teacher ID / Name / Email ID / Phone no.'></input> */}
                  </div>
                  <div className="s1-searchNcancel">
                    {/* <UiButton text="Search" type="submit" onClick={onSearch}></UiButton> */}
                    <Button
                      type="submit"
                      variant="contained"
                      className="s1-search-button"
                      onClick={onSearch}>
                      Search
                    </Button>
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description">
                      <DialogTitle id="alert-dialog-title">No Teacher Found</DialogTitle>
                      <DialogActions>
                        <Link to="/tsp-section1" style={{ textDecoration: 'none' }}>
                          <Button variant="contained" onClick={handleClose} autoFocus>
                            Ok
                          </Button>
                        </Link>
                      </DialogActions>
                    </Dialog>
                    <div className="s1-cancel-text" onClick={handleCancel}>
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TSP_Section1;

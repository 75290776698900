import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import UiButton from 'Components/UiCore/FormComponent/UiButton/UiButton';
import Box from '@mui/material/Box';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';

import Paper from '@mui/material/Paper';

import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControlLabel from '@mui/material/FormControlLabel';

import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { useForm, Controller } from 'react-hook-form';
import PageTemplate from 'Components/UiCore/pageTemplate/PageTemplate';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Textarea from 'react-expanding-textarea';
import TextareaAutosize from 'react-textarea-autosize';

import * as axios from 'axios';
import healthCategoryMapping from '../healthCategoryMapping.json';
// import config from '../../../.s3Config.json'

function TeachersNewPage(props) {
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors }
  } = useForm();
  const navigate = useNavigate();
  const [openSnack, setOpenSnack] = React.useState(false);
  const [nameValidationError, setNameValidationError] = React.useState(false);

  const [fieldValidationErrors, setFieldValidationErrors] = useState({});

  const onSubmit = async (data) => {
    const entries = Object.entries(data).filter((entry) => entry[0] !== 'files');
    event.preventDefault();
    const orgArray = [];
    if (data.org1) {
      orgArray.push('Org 1');
    }
    if (data.org2) {
      orgArray.push('Org 2');
    }
    if (data.org3) {
      orgArray.push('Org 3');
    }

    let fieldValidationError = false;

    let fieldValidationErrors = {};

    if (orgArray.length === 0) {
      fieldValidationError = true;
      fieldValidationErrors.org = true;
    }

    if (data.programTypeName === '') {
      fieldValidationError = true;
      fieldValidationErrors.programTypeName = true;
    }

    if (!data.healthCategory) {
      fieldValidationError = true;
      fieldValidationErrors.healthCategory = true;
    }

    if (!data.isKriyaTaught) {
      fieldValidationError = true;
      fieldValidationErrors.isKriyaTaught = true;
    }

    setFieldValidationErrors(fieldValidationErrors);

    if (!fieldValidationError) {
      const payload = {
        Organization: orgArray.toString(),
        ProgramType: 'Course Type',
        ProgramTypeName: data.programTypeName.trim(),
        HealthCategory: data.healthCategory,
        Notes: data.notes,
        IsKriyaTaught: data.isKriyaTaught === 'yes' ? 1 : 0,
        HealthCategoryType: healthCategoryMapping[data.healthCategory],

      };
      const token = sessionStorage.getItem('user');
      setNameValidationError(false);
      axios
        .post(`${process.env.REACT_APP_BASE_API_URL}/pgm/createprogram`, payload, {
          headers: { authorization: `Bearer ${token}` }
        })
        .then((response) => {
          if (response.data.name !== 'error') {
            navigate(`/program-types/${response.data.id}/view`);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setNameValidationError(true);
          }
        });
    }
  };

  return (
    <div>
      <PageTemplate tabBar navbar>
        <Box sx={{ width: '100%', marginTop: '40px' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <Paper
              sx={{ paddingLeft: '16px' }}
              className="navbar-full-container-2"
              variant="outlined"
              elevation={9}>


            </Paper> */}
            <Paper sx={{ paddingBottom: '40px' }}>
              <Grid sx={{ marginLeft: '32px', paddingTop: '32px' }}>
                <Grid contaier sx={{ marginTop: '16px' }}>
                  <h2>New Program Type </h2>
                </Grid>
              </Grid>
              <Grid container sx={{ marginTop: '0px', marginLeft: '40px' }} spacing={2}>
                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                  Organization <span className="required">*</span>
                </Grid>
                <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
                  <span>
                    <RadioGroup
                      sx={{ width: 450, marginLeft: 0 }}
                      row
                      {...register('org')}
                      required="true"
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group">
                      <FormControlLabel
                        value="Org 1"
                        {...register('org1')}
                        control={<Checkbox />}
                        label="Org 1"
                      />
                      <FormControlLabel
                        value="Org 2"
                        {...register('org2')}
                        control={<Checkbox />}
                        label="Org 2"
                      />
                      <FormControlLabel
                        value="Org 3"
                        {...register('org3')}
                        control={<Checkbox />}
                        label="Org 3"
                      />
                    </RadioGroup>
                  </span>
                  <span style={{ color: 'red', marginLeft: '16px' }}>
                    {fieldValidationErrors.org &&
                      !watch('org1') &&
                      !watch('org2') &&
                      !watch('org3') &&
                      'Required'}
                  </span>
                </Grid>
              </Grid>
              <Grid container sx={{ marginTop: '0px', marginLeft: '40px' }} spacing={2}>
                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                  Program Type
                </Grid>
                <Grid item xs={6}>
                  Course Type
                </Grid>
              </Grid>
              <Grid container sx={{ marginTop: '0px', marginLeft: '40px' }} spacing={2}>
                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                  Program Type Name <span className="required">*</span>
                </Grid>
                <Grid item xs={10}>
                  <span>
                    {' '}
                    <OutlinedInput
                      {...register('programTypeName')}
                      id="programTypeName"
                      type="text"
                      name="programTypeName"
                      placeholder="Enter Program Type Name"
                      sx={{ width: 450, marginLeft: 0 }}
                      size="small"
                      // required="true"
                    />
                  </span>
                  <span style={{ color: 'red', marginLeft: '16px' }}>
                    {fieldValidationErrors.programTypeName &&
                      watch('programTypeName') === '' &&
                      'Required'}
                    <br/>
                    {nameValidationError && 'Course Name Already Exists'}
                  </span>
                </Grid>
              </Grid>
              <Grid container sx={{ marginTop: '0px', marginLeft: '40px' }} spacing={2}>
                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                  Health Category <span className="required">*</span>
                </Grid>
                <Grid item xs={6}>
                  <span>
                    {' '}
                    <Select
                      id="healthCategory"
                      // value={age}
                      {...register('healthCategory')}
                      sx={{ width: 450, marginLeft: 0 }}
                      size="small"
                      // required="true"
                      // onChange={handleChange}
                      label="Enter Health Category">
                      {Object.keys(healthCategoryMapping).map((hcat) => {
                        return (
                          <MenuItem key={hcat} value={hcat}>
                            {' '}
                            {hcat} - Type {healthCategoryMapping[hcat]}{' '}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </span>
                  <span style={{ color: 'red', marginLeft: '16px' }}>
                    {fieldValidationErrors.healthCategory && !watch('healthCategory') && 'Required'}
                  </span>
                </Grid>
              </Grid>{' '}
              <Grid container sx={{ marginTop: '0px', marginLeft: '40px' }} spacing={2}>
                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                  Is Kriya Taught <span className="required">*</span>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                  <span>
                    {' '}
                    <RadioGroup
                      sx={{ width: 450, marginLeft: 0 }}
                      row
                      {...register('isKriyaTaught')}
                      // required="true"
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group">
                      <FormControlLabel
                        value="yes"
                        {...register('isKriyaTaught')}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        {...register('isKriyaTaught')}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </span>
                  <span style={{ color: 'red', marginLeft: '16px' }}>
                    {fieldValidationErrors.isKriyaTaught && !watch('isKriyaTaught') && 'Required'}
                  </span>
                </Grid>
              </Grid>{' '}
              <Grid container sx={{ marginTop: '0px', marginLeft: '40px' }} spacing={2}>
                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'top' }}>
                  Notes
                </Grid>
                <Grid item xs={6}>
                  <TextareaAutosize
                    {...register('notes')}
                    placeholder="Enter Notes"
                    name="notes"
                    id="notes"
                    minRows={3}
                    style={{ width: 450, marginLeft: 0 }}
                  />
                  {/* <Textarea
                    {...register('notes')}
                    className="textarea"
                    // defaultValue="Lorem ipsum dolor sit amet, ..."
                    id="my-textarea"
                    maxHeight="30"
                    name="notes"
                    // styles={{ width: 450, marginLeft: 0 }}
                    // onChange={handleChange}
                    placeholder="Enter additional notes..."
                    // ref={textareaRef}
                  /> */}
                  {/* <OutlinedInput
                    {...register('notes')}
                    id="notes"
                    type="text"
                    name="notes"
                    placeholder="Enter Notes"
                    // error={!!errors.firstName}
                    // helperText={errors?.firstName?.message}
                    sx={{ width: 450, marginLeft: 0 }}
                    size="small"
                    multiline
                  /> */}
                </Grid>
              </Grid>
              <Snackbar
                sx={{ marginBottom: '80px', marginLeft: '32px' }}
                message={'Fill all the Required Fields'}
                open={openSnack}
                onRequestClose={() => setOpenSnack(false)}
                autoHideDuration={200}
                action={
                  <React.Fragment>
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={() => setOpenSnack(false)}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </React.Fragment>
                }></Snackbar>
              <Grid container spacing={2} sx={{ marginTop: '16px', marginLeft: '40px' }}>
                <Grid item>
                  <Grid item>
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>
                  <Button
                    sx={{ bottom: 0 }}
                    onClick={() => navigate('/program-types')}
                    variant="text">
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </form>
        </Box>
      </PageTemplate>
    </div>
  );
}

export default TeachersNewPage;

import React, { useState, useEffect, useRef } from 'react';

import UiButton from 'Components/UiCore/FormComponent/UiButton/UiButton';
// import './styles.css';
import Box from '@mui/material/Box';
import ReactLoading from 'react-loading';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { InputBase, Tooltip } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Navbar from 'Components/UiCore/Navbar/Navbar';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import FilterListIcon from '@mui/icons-material/FilterList';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InfoIcon from '@mui/icons-material/Info';
import ImgsViewer from 'react-images-viewer';
import {
  DataGrid,
  ColDef,
  ValueGetterParams,
  GridColumnHeaderTitle,
  GridColumnHeaderSortIcon
} from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
// import { useData } from './DataContext';
import { useForm, Controller } from 'react-hook-form';
// import RenderCourseInformation from './renderCourseInformation';
import { useNavigate, useLocation, matchRoutes, Navigate } from 'react-router-dom';

import S3FileUpload from 'react-s3/lib/ReactS3';
import PageTemplate from 'Components/UiCore/pageTemplate/PageTemplate';
import * as axios from 'axios';
// import config from '../../../.s3Config.json'
import { upload } from '@testing-library/user-event/dist/upload';
import './styles.css';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function format(inputDate) {
  let date, month, year;

  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;
  year = inputDate.getFullYear();

  date = date.toString().padStart(2, '0');

  month = month.toString().padStart(2, '0');

  return `${date}/${month}/${year}`;
}

const config = {
  bucketName: 'tptm-aold',
  dirName: 'user-images ',
  region: 'ap-south-1',
  accessKeyId: 'AKIAZ5Y75QNNSZIU627W',
  secretAccessKey: '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'
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const steps = ['Personal Info', 'Contact Info', 'Teaching Info', 'Course Info'];

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const regions = ['All Regions', 'Latam', 'Europe', 'Oceania', 'Far East'];
function TeachersNewPage(props) {
  const [teachersData, setTeachersData] = React.useState({});
  const [pageLoader, setPageLoader] = useState(true);

  const [teacherStatus, setTeacherStatus] = React.useState(null);

  const [value, setValue] = React.useState(0);
  // const { data } = useData();
  const [selectedRegion, setSelectedRegion] = React.useState(0);
  const [openImage, setOpenImage] = React.useState(false);
  const [stateData, setStateData] = React.useState([]);
  const [countryData, setCountryData] = React.useState([]);

  const [courseTypeData, setCourseTypeData] = React.useState([]);
  const [ttpCertificationTypeData, setTtpCertiicationTypeData] = React.useState([]);
  const [ttpTypeData, setTtpTypeData] = React.useState([]);
  const [ttpLocationData, setTtpLocationData] = React.useState([]);
  const handleChangeSelect = (event) => {
    setSelectedRegion(event.target.value);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getCountryName = (id)=>{
    if(id && id !="" && countryData.length >0 ){
      const countryname = countryData.find(
        (item) => item.countryid == id
      ).countryname;
      return countryname;
    }
    return "";

  }
  const getCourseNameById = (id,lookupdata)=>{
    if(courseTypeData.length > 0 &&id && id !="" ){
      const cname = courseTypeData.find(
        (item) => item.id == id
      ).programtypename;
      return cname;
    }
    return "";


  }
  const getTppNameById = (id,lookupdata)=>{
    if(lookupdata.length >0 &&id && id !=""){
      const ttp = lookupdata.find(
        (item) => item.id == id
      ).lookupdesc;
      return ttp;
    }


  }
  const getStateNameById = (id)=>{
    if (stateData.length > 0){
      const s_name = stateData.find(
        (item) => item.id == id
      ).name;
      return s_name;
    }else{
      return id;
    }

  }

  const location = window.location.href;
  const teacherId = location.split('/').at(-2);
  const navigate = useNavigate();
  useEffect(() => {
    const token = sessionStorage.getItem('user');
    let mounted = true;
    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/teachers/countries`, {
        headers: { authorization: `Bearer ${token}` }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      })
      .then((response) => {
        setCountryData(response.data);
      });
    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/teachers/courseTypes`, {
        headers: { authorization: `Bearer ${token}` }
      })
      .then((response) => {
        setCourseTypeData(response.data);
      });
    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/teachers/ttpcertificationtype`, {
        headers: { authorization: `Bearer ${token}` }
      })
      .then((response) => {
        setTtpCertiicationTypeData(response.data);
      });
    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/teachers/ttptype`, {
        headers: { authorization: `Bearer ${token}` }
      })
      .then((response) => {
        setTtpTypeData(response.data);
      });
    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/teachers/ttplocation`, {
        headers: { authorization: `Bearer ${token}` }
      })
      .then((response) => {
        setTtpLocationData(response.data);
      });
      // axios
      //   .get(`${process.env.REACT_APP_BASE_API_URL}/teachers/getstatebycountryid`, {
      //     headers: { authorization: `Bearer ${token}` }
      //   })
      //   .then((response) => {
      //     setStateData(response.data);
      //   });




    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/teachers/viewteacher`, {
        params: {
          id: teacherId
        },
        headers: { authorization: `Bearer ${token}` }
      })
      .then((response) => {
        let country_code = response.data.basicinfo[0].country;
        axios
        .get(`${process.env.REACT_APP_BASE_API_URL}/teachers/getstatebycountryid`, {
          params: {
            id: country_code
          },
          headers: { authorization: `Bearer ${token}` }
        })
        .then((res) => {
          setStateData(res.data);
        });
        console.log('ccccccccccccccccccccccccccc'+country_code)
        setTeachersData(response.data);
        setPageLoader(false);
      });

    return () => (mounted = false);
  }, []);

  const teachingcountry =
    teachersData && teachersData.teachingcountry ? teachersData.teachingcountry : [];
  const basicInfo = teachersData && teachersData.basicinfo ? teachersData.basicinfo[0] : {};
  // const teachingcourse = [];
  const teachingcourse =
    teachersData && teachersData.teachingcourse ? teachersData.teachingcourse : [];

  const myRef = useRef(null);
  const personalInfoRef = useRef(null);
  const contactRef = useRef(null);
  const teachingRef = useRef(null);

  const stepsRef = [personalInfoRef, contactRef, teachingRef, myRef];

  console.log(basicInfo, 'basic info debug');

  return (
    <div>
      <PageTemplate tabBar navbar>
        {' '}
        <Box sx={{ width: '100%', marginTop: '40px' }}>
          <form>
            {/* <Paper sx={{ paddingLeft: '48px' }} variant="outlined" elevation={9}> */}
            {/* <Grid container sx={{ marginLeft: '0px', marginBottom: '0px' }}>
                <Grid item xs={11} sx={{ marginTop: '48px' }}>
                  <h2>View Teacher </h2>
                </Grid>
                <Grid item xs={1}>
                  {' '}
                  <Button
                    sx={{ marginRight: '48px', marginTop: '48px', marginBottom: '32px' }}
                    onClick={() => {
                      navigate(`/teachers/${teacherId}/edit`);
                    }}
                    variant="contained">
                    Edit
                  </Button>
                </Grid>
              </Grid> */}
            {pageLoader ? (
              <ReactLoading type="bars" color="#fff" />
            ) : (
              <>
                <Paper
                  className="navbar-full-container-2"
                  sx={{
                    marginLeft: '0px',
                    marginRight: '200px',
                    marginBottom: '10px',
                    marginTop: '40px',
                    // backgroundColor: '#4286d4',
                    // color: 'white',
                    borderRadius: '8px',
                    paddingTop: '30px',
                    paddingBottom: '30px'
                  }}>
                  <Grid
                    container
                    spacing={0}
                    // xs={12}
                    sx={{ marginLeft: '32px' }}>
                    <Grid xs={2} item>
                      <h2>View Teacher </h2>
                    </Grid>
                    <Grid xs={6} sx={{ width: '400' }}>
                      <Stepper alternativeLabel>
                        <Step
                          active="true"
                          className="step"
                          onClick={() => {
                            // personalInfoRef.current.scrollIntoView()
                            personalInfoRef.current.scrollIntoView({
                              behavior: 'smooth',
                              block: 'end',
                              inline: 'nearest'
                            });
                          }}>
                          <StepLabel>Personal Info</StepLabel>
                        </Step>
                        <Step
                          active="true"
                          className="step"
                          onClick={() =>
                            contactRef.current.scrollIntoView({
                              behavior: 'smooth'
                            })
                          }>
                          <StepLabel>Contact Info</StepLabel>
                        </Step>
                        <Step
                          active="true"
                          className="step"
                          onClick={() =>
                            teachingRef.current.scrollIntoView({
                              behavior: 'smooth'
                            })
                          }>
                          <StepLabel>Teaching Info</StepLabel>
                        </Step>
                        <Step
                          active="true"
                          className="step"
                          onClick={() =>
                            myRef.current.scrollIntoView({
                              behavior: 'smooth'
                              // block: 'end',
                              // inline: 'nearest'
                            })
                          }>
                          <StepLabel>Course Info</StepLabel>
                        </Step>
                      </Stepper>
                    </Grid>
                    <Grid xs={2}></Grid>
                    <Grid item sx={{ display: 'flex', alignItems: 'center' }} xs={2}>
                      {' '}
                      <Button
                        // sx={{ marginRight: '48px', marginTop: '48px', marginBottom: '32px' }}
                        onClick={() => {
                          navigate(`/teachers/${teacherId}/edit`);
                        }}
                        variant="contained">
                        Edit
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper className="form-container" variant="outlined">
                  <Paper
                    ref={personalInfoRef}
                    container
                    sx={{
                      marginTop: '32px',
                      marginLeft: '48px',
                      marginRight: '48px',
                      marginBottom: '10px',
                      backgroundColor: '#4286d4',
                      color: 'white',
                      borderRadius: '8px',
                      padding: '10px'
                    }}>
                    <h3>Personal Information</h3>
                  </Paper>
                  <Paper
                    variant="outlined"
                    sx={{
                      paddingBottom: '20px',
                      marginLeft: '48px',
                      marginRight: '48px'
                    }}>
                    <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        First Name
                      </Grid>
                      <Grid item xs={6}>
                        {basicInfo.firstname}
                      </Grid>
                      {basicInfo.uploadphoto && (
                        <img
                          width="200"
                          style={{ position: 'absolute', right: '140px' }}
                          height="200"
                          src={`https://tptm-aold.s3.ap-south-1.amazonaws.com/${basicInfo.uploadphoto}`}
                        />
                      )}
                    </Grid>
                    <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        Last Name
                      </Grid>
                      <Grid item xs={6}>
                        {basicInfo.lastname}
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        Alias Name 1
                      </Grid>
                      <Grid item xs={6}>
                        {basicInfo.aliasname1}
                      </Grid>
                    </Grid>
                    <Grid
                      ref={contactRef}
                      container
                      sx={{ marginTop: '0px', marginLeft: '20px' }}
                      spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        Alias Name 2
                      </Grid>
                      <Grid item xs={6}>
                        {basicInfo.aliasname2}
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        Gender
                      </Grid>
                      <Grid item xs={6}>
                        {basicInfo.gender}
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        Date Of Birth
                      </Grid>
                      {basicInfo.dob && (
                        <Grid item xs={6}>
                          {format(new Date(basicInfo.dob.substring(0, 10)))}
                        </Grid>
                      )}
                    </Grid>
                    {/* <Grid
                      ref={contactRef}
                      container
                      sx={{ marginTop: '0px', marginLeft: '20px' }}
                      spacing={2}>
                      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'top' }}>
                        Profile Photo
                      </Grid>
                      {basicInfo.uploadphoto && (
                        <Grid item xs={6}>
                          <img
                            width="400"
                            height="200"
                            src={`https://tptm-aold.s3.ap-south-1.amazonaws.com/${basicInfo.uploadphoto}`}
                          />
                        </Grid>
                      )}
                    </Grid> */}
                  </Paper>
                  <Paper
                    container
                    sx={{
                      marginTop: '32px',
                      marginLeft: '48px',
                      marginRight: '48px',
                      marginBottom: '10px',
                      backgroundColor: '#4286d4',
                      color: 'white',
                      borderRadius: '8px',
                      padding: '10px'
                    }}>
                    <h3>Contact Information</h3>
                  </Paper>
                  <Paper sx={{ paddingBottom: '20px', marginLeft: '48px', marginRight: '48px' }}>
                    <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        Email ID
                      </Grid>
                      <Grid item xs={6}>
                        {basicInfo.email}
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        Mobile
                      </Grid>
                      <Grid item xs={6}>
                        + {basicInfo.mobile1}
                      </Grid>
                    </Grid>

                    <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        Alternate Mobile
                      </Grid>
                      {basicInfo.mobile2 && (
                        <Grid item xs={6}>
                          + {basicInfo.mobile2}
                        </Grid>
                      )}
                    </Grid>

                    <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        Address Line 1
                      </Grid>
                      <Grid item xs={6}>
                        {basicInfo.address1}
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        Address Line 2
                      </Grid>
                      <Grid item xs={6}>
                        {basicInfo.address2}
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        Country
                      </Grid>
                      <Grid item xs={6}>
                        {getCountryName(basicInfo.country)}
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        State
                      </Grid>
                      <Grid item xs={6}>
                        {getStateNameById(basicInfo.state)}
                      </Grid>
                    </Grid>
                    <Grid
                      ref={teachingRef}
                      container
                      sx={{ marginTop: '0px', marginLeft: '20px' }}
                      spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        City
                      </Grid>
                      <Grid item xs={6}>
                        {basicInfo.city}
                      </Grid>
                    </Grid>

                    <Grid
                      ref={teachingRef}
                      container
                      sx={{ marginTop: '0px', marginLeft: '20px' }}
                      spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        ZIP / PIN Code
                      </Grid>
                      <Grid item xs={6}>
                        {basicInfo.pincode}
                      </Grid>
                    </Grid>
                  </Paper>
                  <Paper
                    ref={myRef}
                    container
                    sx={{
                      marginTop: '32px',
                      marginLeft: '48px',
                      marginRight: '48px',
                      marginBottom: '10px',
                      backgroundColor: '#4286d4',
                      color: 'white',
                      borderRadius: '8px',
                      padding: '10px'
                    }}>
                    <h3>Course Information</h3>
                  </Paper>

                  {/* <Paper sx={{ paddingBottom: '20px', marginLeft: '48px' }}> */}
                  {teachingcourse.map((item, i) => {
                    return (
                      <Paper
                        key={item}
                        sx={{
                          paddingBottom: '20px',
                          marginBottom: '32px',
                          marginLeft: '48px',
                          marginRight: '48px'
                        }}>
                        <Grid key={item}>
                          <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                            <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                              Course Type
                            </Grid>

                            <Grid item xs={6}>
                              {getCourseNameById(item.coursetypeid)}
                            </Grid>
                          </Grid>
                          <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                            <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                              Certificate Type
                            </Grid>
                            <Grid item xs={6}>
                              {getTppNameById(item.ttpcertificationtype,ttpCertificationTypeData) }
                            </Grid>
                          </Grid>
                          <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                            <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                              TTP Type
                            </Grid>
                            <Grid item xs={6}>
                            {getTppNameById(item.ttptype,ttpTypeData) }
                            </Grid>
                          </Grid>

                          <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                            <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                              TTP Application Country
                            </Grid>
                            <Grid item xs={6}>
                              {getCountryName(item.ttpapplicationcountry)}
                            </Grid>
                          </Grid>
                          <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                            <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                              TTP Location
                            </Grid>
                            <Grid item xs={6}>
                              {getTppNameById(item.ttplocation,ttpLocationData)}
                            </Grid>
                          </Grid>

                          <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                            <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                              TTP Graduation Date
                            </Grid>
                            <Grid item xs={6}>
                              <Grid item xs={6} sx={{ width: 450, marginLeft: 0 }}>
                                {item.ttpgraduationdate &&
                                  format(new Date(item.ttpgraduationdate.substring(0, 10)))}
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                            <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                              Notes
                            </Grid>
                            <Grid item xs={6}>
                              {item.notes}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    );
                  })}
                  {/* </Paper> */}
                  <Paper
                    container
                    sx={{
                      marginTop: '32px',
                      marginLeft: '48px',
                      marginRight: '48px',
                      marginBottom: '10px',
                      backgroundColor: '#4286d4',
                      color: 'white',
                      borderRadius: '8px',
                      padding: '10px'
                    }}>
                    <h3>Teaching Information</h3>
                  </Paper>
                  <Paper sx={{ paddingBottom: '20px', marginLeft: '48px', marginRight: '48px' }}>
                    <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        Teacher Code
                      </Grid>
                      <Grid item xs={6}>
                        {basicInfo.teachercode}
                      </Grid>
                    </Grid>

                    <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        Teacher Activity Status
                      </Grid>
                      <Grid item xs={8}>
                        {basicInfo.teacheractivitystatus}
                        <span>
                          {basicInfo.teacheractivitystatus === 'Inactive' && (
                            <>
                              {' '}
                              {` - `} {basicInfo.teacheractivitysubstatus}
                            </>
                          )}
                        </span>
                      </Grid>
                      {/* <Grid item xs={4}>

                      </Grid> */}
                    </Grid>
                    <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        Harmony Code
                      </Grid>
                      <Grid item xs={3}>
                        {basicInfo.harmonycode}
                      </Grid>
                    </Grid>
                    {/* <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Current Country
                    </Grid>
                    <Grid item xs={6}>
                      {basicInfo.currentcountry}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Teacher Type
                    </Grid>
                    <Grid item xs={6}>
                      {basicInfo.teachertype}
                    </Grid>
                  </Grid> */}
                    <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        Swami Ji / Sadhvi Ji
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl>{basicInfo.isswamiorsadhvi === 1 ? 'Yes' : 'No'}</FormControl>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        Full Time Office Bearer
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl>
                          {basicInfo.fulltimeofficebearer === 1 ? 'Yes' : 'No'}
                        </FormControl>
                      </Grid>
                    </Grid>
                    {/* <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        Travelling Teacher
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl>
                          {basicInfo.istravellingteacher === 1 ? 'Yes' : 'No'}
                        </FormControl>
                      </Grid>
                    </Grid> */}

                    <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        Launguages
                      </Grid>
                      <Grid item xs={6}>
                        {basicInfo.languages &&
                          basicInfo.languages.split(',').map((item, i) => {
                            if (i !== basicInfo.languages.split(',').length - 1) return `${item}, `;
                            return item;
                          })}
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        Teacher Agreement Signed ?
                      </Grid>
                      <Grid item xs={6}>
                        {basicInfo.isteacheragreementsigned === 1 ? 'Yes' : 'No'}
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        Teacher Agreement Signed On
                      </Grid>
                      <Grid item xs={6}>
                        {basicInfo.teacheragreementsignedon &&
                          format(new Date(basicInfo.teacheragreementsignedon.substring(0, 10)))}
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        Teacher Agreement Expired On
                      </Grid>
                      <Grid item xs={6}>
                        {basicInfo.teacheragreementexpiredon &&
                          format(new Date(basicInfo.teacheragreementexpiredon.substring(0, 10)))}
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        Teaching Countries Details
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: '12px', marginLeft: '20px' }}>
                      <TableContainer sx={{ width: 800 }}>
                        <Table sx={{}} size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 'bold' }} align="left">
                                Country Name
                              </TableCell>
                              <TableCell sx={{ fontWeight: 'bold' }} align="left">
                                Teaching Start Date
                              </TableCell>
                              <TableCell sx={{ fontWeight: 'bold' }} align="left">
                                {' '}
                                <Tooltip
                                  xs={2}
                                  title={
                                    <span>
                                      1. Leave Teaching Expiry Date field empty if there is no
                                      expiry date
                                      <br />
                                      2. For Selected Country Course type Status will change to
                                      "Inactive" in Harmony after "Teaching Expiry Date"
                                    </span>
                                  }>
                                  <span>
                                    Teaching Expiry Date {/* <IconButton> */}
                                    {/* </IconButton> */}
                                    <span>{/* <InfoIcon /> */}</span>
                                  </span>
                                </Tooltip>
                              </TableCell>
                              <TableCell sx={{ fontWeight: 'bold' }} align="left">
                                Country Level Status
                              </TableCell>
                              {/* <TableCell align="right">Country  Type</TableCell> */}
                              <TableCell sx={{ fontWeight: 'bold' }} align="left">
                                Teacher Type
                              </TableCell>
                              <TableCell sx={{ fontWeight: 'bold' }} align="left">
                                Course Types
                              </TableCell>
                              <TableCell sx={{ fontWeight: 'bold' }} align="left">
                                Primary Country
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody ref={myRef}>
                            {teachingcountry.map((row) => (
                              <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="left">{getCountryName(row.countryname)}</TableCell>
                                <TableCell align="left">
                                  {row.teachingstartdate &&
                                    format(new Date(row.teachingstartdate.substring(0, 10)))}
                                </TableCell>
                                <TableCell align="left">
                                  {row.teachingexpirydate &&
                                    format(new Date(row.teachingexpirydate.substring(0, 10)))}
                                </TableCell>
                                <TableCell align="left">{row.countrylevelstatus}</TableCell>
                                <TableCell align="left">
                                  {row.teachertype === 0 ? 'Part Time' : 'Full Time'}
                                </TableCell>
                                <TableCell align="left">{
                                row.coursetypes.split(',').map((item,i) => {
                                  if (i !== row.coursetypes.split(',').length - 1) return `${item ? getCourseNameById(item,courseTypeData):item} , `;
                                  return item ? getCourseNameById(item,courseTypeData) : item;
                                })}</TableCell>

                                <TableCell align="left">
                                  {+row.primarycountry === 1 ? 'Yes' : ''}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Paper>

                  <Button
                    sx={{ marginLeft: '48px', marginTop: '16px', marginBottom: '50px' }}
                    onClick={() => {
                      navigate(`/teachers/${teacherId}/edit`);
                    }}
                    variant="contained">
                    Edit
                  </Button>
                  <Button
                    sx={{ marginLeft: '48px', marginTop: '16px', marginBottom: '50px' }}
                    onClick={() => {
                      navigate('/teachers/');
                    }}
                    variant="outlined"
                    startIcon={<SearchIcon />}>
                    Find Teachers
                  </Button>
                </Paper>
              </>
            )}
            {/* </Paper> */}
          </form>
        </Box>
      </PageTemplate>
    </div>
  );
}

export default TeachersNewPage;

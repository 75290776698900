import logo from './logo.svg';
import { Routes, Route, Link } from 'react-router-dom';
import './App.css';
import ForgotPassword from './pages/ForgotPassword/index';
import NewPassword from './pages/NewPassword';
import LoginPage from './pages/login';
import Teachers from './Containers/Teachers/index';
import TeachersExport from './Containers/Teachers/Export/index';

import NewTeacher from './Containers/Teachers/New/index';
import EditTeacher from './Containers/Teachers/Edit/index';
import ViewTeacher from './Containers/Teachers/View/index';

import NewProgramType from './Containers/ProgramTypes/New/index';
import ProgramTypeListing from './Containers/ProgramTypes/Listing/index';
import ProgramTypeView from './Containers/ProgramTypes/View/index';
import ProgramTypeEdit from './Containers/ProgramTypes/Edit/index';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import Change_Password from './Components/Templates/Auth/ChangePassword/index';
import ExportPage from './pages/home/export';
import Home from './pages/home/home';
import TSP_Section1 from './Components/Templates/TSP_Section1/TSP_Section1';
import TSP_Section2 from './Components/Templates/TSP_Section2/TSP_Section2';
import TSP_Section3 from './Components/Templates/TSP_Section3/TSP_Section3';
import TeacherSearchView from './Containers/Teachers/TeacherSearchView';


function App() {
  const location = useLocation();

  useEffect(() => {
    !location.pathname.includes('/teachers') ? localStorage.setItem('teachersAdvSearch', '') : null;
    !location.pathname.includes('/program-types')
      ? localStorage.setItem('programsAdvSearch', '')
      : null;
  }, [location]);

  useEffect(() => {
    !location.pathname.includes('/teachers') ? localStorage.setItem('teachersAdvSearch', '') : null;
    !location.pathname.includes('/program-types')
      ? localStorage.setItem('programsAdvSearch', '')
      : null;
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/new-password/:id" element={<NewPassword />} />
        <Route path="/changepassword" element={<Change_Password />} />
        <Route path="/home/export" element={<ExportPage />} />
        <Route path="/home" element={<Home />} />
        <Route path="/teachers" element={<Teachers />} />
        <Route path="/teachers/export" element={<TeachersExport />} />

        <Route path="/teachers/new" element={<NewTeacher />} />
        <Route path="/teachers/:id/edit" element={<EditTeacher />} />
        <Route path="/teachers/:id/view" element={<ViewTeacher />} />
        <Route path="/program-types/new" element={<NewProgramType />} />
        <Route path="/program-types/" element={<ProgramTypeListing />} />
        <Route path="/program-types/:id/view" element={<ProgramTypeView />} />
        <Route path="/program-types/:id/edit" element={<ProgramTypeEdit />} />

        <Route path="about" element={<div />} />
        <Route path="about" element={<div />} />
        <Route path="/tsp-section1" exact element={<TSP_Section1 />} />
        <Route path="/section1/section2" exact element={<TSP_Section2 />} />
        <Route path="/section1/section2/section3" exact element={<TSP_Section3 />} />
        <Route path="/teacher-search/:id" exact element={<TeacherSearchView />} />
      </Routes>
    </div>
  );
}

export default App;

import React, { useState, useEffect, useRef } from 'react';

import UiButton from 'Components/UiCore/FormComponent/UiButton/UiButton';
import './styles.css';
import Box from '@mui/material/Box';
import ReactLoading from 'react-loading';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { InputBase, Tooltip } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Navbar from 'Components/UiCore/Navbar/Navbar';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import FilterListIcon from '@mui/icons-material/FilterList';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InfoIcon from '@mui/icons-material/Info';
import ImgsViewer from 'react-images-viewer';
import {
  DataGrid,
  ColDef,
  ValueGetterParams,
  GridColumnHeaderTitle,
  GridColumnHeaderSortIcon
} from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
// import { useData } from './DataContext';
import { useForm, Controller } from 'react-hook-form';
// import RenderCourseInformation from './renderCourseInformation';
import { useNavigate, useLocation, matchRoutes, Navigate } from 'react-router-dom';

import S3FileUpload from 'react-s3/lib/ReactS3';
import PageTemplate from 'Components/UiCore/pageTemplate/PageTemplate';
import * as axios from 'axios';
// import config from '../../../.s3Config.json'
import { upload } from '@testing-library/user-event/dist/upload';
import './styles.css';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function format(inputDate) {
  let date, month, year;

  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;
  year = inputDate.getFullYear();

  date = date.toString().padStart(2, '0');

  month = month.toString().padStart(2, '0');

  return `${date}/${month}/${year}`;
}

const config = {
  bucketName: 'tptm-aold',
  dirName: 'user-images ',
  region: 'ap-south-1',
  accessKeyId: 'AKIAZ5Y75QNNSZIU627W',
  secretAccessKey: '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'
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const steps = ['Personal Info', 'Contact Info', 'Teaching Info', 'Course Info'];

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const regions = ['All Regions', 'Latam', 'Europe', 'Oceania', 'Far East'];
function TeachersNewPage(props) {
  const [teachersData, setTeachersData] = React.useState({});
  const [pageLoader, setPageLoader] = useState(true);

  const [teacherStatus, setTeacherStatus] = React.useState(null);

  const [value, setValue] = React.useState(0);
  // const { data } = useData();
  const [selectedRegion, setSelectedRegion] = React.useState(0);
  const [openImage, setOpenImage] = React.useState(false);

  const handleChangeSelect = (event) => {
    setSelectedRegion(event.target.value);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const location = window.location.href;
  const teacherId = location.split('/').at(-1);
  const navigate = useNavigate();
  useEffect(() => {
    const token = sessionStorage.getItem('user');
    let mounted = true;
    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/teachers/teacher-search`, {
        params: {
          id: teacherId
        },
        headers: { authorization: `Bearer ${token}` }
      })
      .then((response) => {
        setTeachersData(response.data);
        setPageLoader(false);
      });

    return () => (mounted = false);
  }, []);

  const teachingcountry =
    teachersData && teachersData.teachingcountry ? teachersData.teachingcountry : [];
  const basicInfo = teachersData && teachersData.basicinfo ? teachersData.basicinfo : {};
  // const teachingcourse = [];
  const teachingcourse =
    teachersData && teachersData.teachingcourse ? teachersData.teachingcourse : [];

  const myRef = useRef(null);
  const personalInfoRef = useRef(null);
  const contactRef = useRef(null);
  const teachingRef = useRef(null);

  const stepsRef = [personalInfoRef, contactRef, teachingRef, myRef];

  console.log(basicInfo, 'basic info debug');

  return (
    <>
      {pageLoader ? (
        <></>
      ) : (
        <div className="s3-outer-container">
          <Navbar />

          <div className="s3-inner-container1">
            <div className="s3-tabbar">
              <div className="s3-home">
                <Link to="/tsp-section1" style={{ textDecoration: 'none', color: 'black' }}>
                  <p>Home</p>
                </Link>
              </div>
            </div>
            <div className="s3-tm">
              <div className="s3-tm-inner-container">
                <div>
                  <h2>{basicInfo.firstname + ' ' + basicInfo.lastname}</h2>
                </div>
                <div className="s3-search-teacher">
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                      Name
                    </Grid>
                    <Grid item xs={6}>
                      {basicInfo.firstname + ' ' + basicInfo.lastname}
                    </Grid>
                    {basicInfo.uploadphoto && (
                      <img
                        width="200"
                        style={{ position: 'absolute', right: '140px' }}
                        height="200"
                        src={`https://tptm-aold.s3.ap-south-1.amazonaws.com/${basicInfo.uploadphoto}`}
                      />
                    )}
                  </Grid>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                      Teacher Code
                    </Grid>
                    <Grid item xs={6}>
                      {basicInfo.teachercode}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                      Teacher Status
                    </Grid>
                    <Grid item xs={6}>
                      {basicInfo.teacheractivitystatus}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                      Country of Residence
                    </Grid>
                    <Grid item xs={6}>
                      {basicInfo.country}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                      Primary Country
                    </Grid>
                    <Grid item xs={6}>
                      {basicInfo.lastname}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                      Teahing Countries
                    </Grid>
                    <Grid item xs={6}>
                      {basicInfo.teaching_countries.map((item, i) => {
                        if (i !== basicInfo.teaching_countries.length - 1) return `${item}, `;
                        return item;
                      })}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                      Program Types
                    </Grid>
                    <Grid item xs={6}>
                      {basicInfo.teaching_courses.map((item, i) => {
                        if (i !== basicInfo.teaching_courses.length - 1) return `${item}, `;
                        return item;
                      })}
                    </Grid>
                  </Grid>
                  {/* <div className="s3-search-teacher-inner-container">
                    <div className="s3-st-text">
                      <p>Name</p>
                      <p>Teacher Code</p>
                      <p>Teacher Status</p>
                      <p>Country</p>
                     
                      <p>Primary Country</p>
                      <p>Teaching Countries</p>
                      <p>Program Types</p>
                    </div>
                    <div className="s3-st-input">
                      <p>{basicInfo.firstname + ' ' + basicInfo.lastname}</p>
                      <p>{basicInfo.teachercode}</p>
                      <p>{basicInfo.teacheractivitystatus}</p>
                      <p>{basicInfo.country}</p>
                     
                      <p> {basicInfo.primarycountryname}</p>
                      <p style={{ width: '10px'}} >
                        {basicInfo.teaching_countries.map((item, i) => {
                          if (i !== basicInfo.teaching_countries.length - 1) return `${item}, `;
                          return item;
                        })}
                      </p>
                      <p>
                        {basicInfo.teaching_courses.map((item, i) => {
                          if (i !== basicInfo.teaching_courses.length - 1) return `${item}, `;
                          return item;
                        })}
                      </p>
                    </div>
                    <div className="s3-profile-pic">
                      <img
                        src={`https://tptm-aold.s3.ap-south-1.amazonaws.com/${basicInfo.uploadphoto}`}
                        alt="Profile pic"
                        style={{ height: 300, width: 300 }}></img>
                    </div>
                  </div> */}
                  <div className="s3-go-back-button">
                    {/* <Link to="/section1/section2" > */}
                    <UiButton
                      text="Go back"
                      type="submit"
                      // className={styles.button}
                      //   onClick={handleClick}
                    ></UiButton>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TeachersNewPage;

import React, { useState } from 'react';
import styles from '../Global.module.css';
import AOLlogo from '../AOLlogo.png';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { InputAdornment, IconButton, DialogContent, DialogContentText } from '@mui/material';
import Form from '../../../UiCore/FormComponent/FormFeild/FormFeild';
import InputFormFeild from '../../../UiCore/FormComponent/InputFormFeild/InputFormFeild2';
import UiButton from '../../../UiCore/FormComponent/UiButton/UiButton';
import * as axios from 'axios';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Popup from 'reactjs-popup';
import PasswordChecklist from 'react-password-checklist';
import { Tooltip } from '@mui/material';

var msg, link_url;
const regex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]).{8,}$/;
const newPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('New Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Password must be at least 8 characters in length containing One Uppercase Letter, One Lowercase Letter, Special Character and One Number'
    ),
  // .oneOf([yup.ref('confirmpassword'), null], 'Password and Confirm password must be same'),
  // .matches(/\w*[a-z]\w*/, 'Password must have a small letter')
  // .matches(/\w*[A-Z]\w*/, 'Password must have a capital letter')
  // .matches(/\d/, 'Password must have a number')
  // .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, 'Password must have a special character')
  // .min(8, ({ min }) => `Password must be at least ${min} characters`),

  confirmpassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Password and Confirm password must be same')
    .required('Confirm Password is required')
});
export default function NewPassword() {
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [newPwdInputed, setNewPwdInputed] = useState(false);
  const [confirmPwdInputed, setConfirmPwdInputed] = useState(false);

  //   const onSubmit = (data) => {
  //     console.log("data is", data);
  //   };
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    // console.log('cancel');
    window.location.reload(false);
  };

  let onSubmit = (data) => {
    let error = false;

    if (!newPassword.match(regex)) {
      error = true;
    }
    if (newPassword !== confirmPassword) {
      error = true;
    }
    if (!error) {
      axios
        .put(`${process.env.REACT_APP_BASE_API_URL}/ad/changepwd`, {
          password: newPassword,
          id: location.pathname.split('/')[2]
        })
        .then((response) => {
          if (response.data.message === 'Password Updated Successfully') {
            msg = 'Changes have been saved successfully. Please login to Continue';
            link_url = '/login';
            setOpen(true);
          } else {
            // setOpen(true)
            msg =
              'You have tried to use a one-time password reset link that has either been used or is no longer valid.Please request a new one using forgot password page';
            link_url = '/forgot-password';
            setOpen(true);
          }
        });
    }
  };

  // new passWord validation
  let newPasswordErrorMessage = null;

  console.log(newPassword, confirmPassword);

  if (!newPassword.match(regex) && newPwdInputed) {
    console.log('im here');
    newPasswordErrorMessage =
      'Password must be at least 8 characters in length containing One Uppercase Letter, One Lowercase Letter, Special Character and One Number';
  }
  //confirm password validation
  let confirmPasswordErrorMessage = null;
  if (newPasswordErrorMessage !== '' && confirmPwdInputed) {
    if (newPassword !== confirmPassword) {
      confirmPasswordErrorMessage = 'Password and Confirm password must be same';
    }
  }

  return (
    <div className={styles.outerContainer}>
      <div className={styles.container}>
        <img src={AOLlogo} className={styles.img} />
        <div className={styles.heading}>New Password</div>
        <Form
          onSubmit={onSubmit}
          // schema={newPasswordSchema}
        >
          <label className={styles.label}>Enter New Password</label>
          {/* <Tooltip title="Please fill pswd" placement="right-end" arrow leaveDelay={200}> */}
          <InputFormFeild
            // label="Enter new password"
            placeholder="Enter new password"
            name="password"
            type={showPassword ? 'password' : 'text'}
            className={styles.input}
            variant={'outlined'}
            value={newPassword}
            onChange={(e) => {
              if (e.target.value !== '') {
                setNewPwdInputed(true);
              }
              setNewPassword(e.target.value);
            }}
            errorMessage={newPasswordErrorMessage}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          {/* </Tooltip> */}
          {/* <div style={{ position: 'relative' }}>
            <PasswordChecklist
              rules={['minLength', 'specialChar', 'number', 'capital']}
              minLength={8}
              value={password}
              valueAgain={confirmPassword}
              messages={{
                minLength: 'Minimum of 8 characters in length',
                specialChar: 'Must have at least one special character',
                number: 'Must have at least one number',
                capital: 'Must have at least one capital letter'
              }}
            />
          </div> */}
          <label className={styles.label}>Confirm Password</label>
          <InputFormFeild
            // label="Confirm Password"
            placeholder="Confirm Password"
            name="confirmpassword"
            type={showConfirmPassword ? 'password' : 'text'}
            className={styles.input}
            variant={'outlined'}
            value={confirmPassword}
            errorMessage={confirmPasswordErrorMessage}
            onChange={(e) => {
              if (e.target.value !== '') {
                setConfirmPwdInputed(true);
              }
              setConfirmPassword(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <br />

          {/* <UiButton
            text="Save"
            type="submit"
          ></UiButton> */}
          <Button
            onClick={() => {
              setNewPwdInputed(true);
              setConfirmPwdInputed(true);
            }}
            type="submit"
            variant="contained"
            className={styles.button}>
            Save
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            sx={{ height: '200px' }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent style={{ padding: '25px', paddingBottom: '0px' }}>
              <DialogContentText style={{ color: 'black' }}>{msg}</DialogContentText>
            </DialogContent>{' '}
            <DialogActions>
              <Link to={link_url} style={{ textDecoration: 'none' }}>
                <Button variant="contained" onClick={handleClose} autoFocus>
                  Ok
                </Button>
              </Link>
            </DialogActions>
          </Dialog>
          {/* <div className={styles.link} onClick={handleCancel}> */}
          <div
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              color: 'blue',
              textAlign: 'center',
              paddingTop: '10px'
            }}
            onClick={handleCancel}>
            Cancel
          </div>
        </Form>
      </div>
    </div>
  );
}

import React, { createContext, useState, useContext } from 'react';
import TextField from '@mui/material/TextField';
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  InputBase,
  TextareaAutosize,
  Tooltip
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Navbar from 'Components/UiCore/Navbar/Navbar';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import CancelIcon from '@mui/icons-material/Cancel';

import { useForm, Controller } from 'react-hook-form';
import { InsertEmoticonOutlined } from '@mui/icons-material';

const renderCourseInformation = (props) => {
  const [data, setData] = useState({});
  const [open, setOpen] = React.useState(false);
  const [ctypeopen, setCtypOpen] = React.useState(false);
  const [deleteCourseKey, setDeleteCourseKey] = React.useState(null);
  const [updateCourseType, setUpdateCourseType] = React.useState({});
  console.log(deleteCourseKey,'delete_key');
  const {
    courseInfoData,
    setCourseInfoData,
    countryData,
    courseTypeData,
    ttpCertificationTypeData,
    ttpTypeData,
    ttpLocationData,
    courseInfoRowKey,
    setCourseInfoRowKey
  } = props;

  const setValues = (values) => {
    setData((prevData) => ({
      ...prevData,
      ...values
    }));
  };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const updateCTypeOpen = () => {
    setCtypOpen(true);
  };
  const updateCTypeClose = () => {
    setCtypOpen(false);
    setUpdateCourseType({});
  };
  const getCountryName = (id)=>{
    if(countryData.length >0){
          const countryname = countryData.find(
                                  (item) => item.countryid == id
                                ).countryname;
          return countryname;
    }

  }
  const getTppNameById = (id,lookupdata)=>{
    if(lookupdata.length >0 && id){
        const ttp = lookupdata.find(
                                  (item) => item.id == id
                                ).lookupdesc;
        return ttp;
    }
  }
  const handleClose = () => {
    setOpen(false);
  };
  const courseTypeSelectedArray = courseInfoData.map((item) => item.courseType);

  const courseTypeOptions = courseTypeData.sort((a, b) => {
    const nameA = a.programtypename.toUpperCase(); // ignore upper and lowercase
    const nameB = b.programtypename.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  return (
    <>
      <Paper
        container
        sx={{
          marginTop: '32px',
          marginLeft: '48px',
          marginRight: '48px',
          marginBottom: '10px',
          backgroundColor: '#4286d4',
          color: 'white',
          borderRadius: '8px',
          padding: '10px'
        }}>
        <h3>Course Information</h3>
      </Paper>
      {console.log(courseInfoData, 'index major debug')}
      {/* <Paper sx={{ paddingBottom: '20px', marginLeft: '48px', marginRight: '48px' }}> */}
      {courseInfoData.map((item, i) => {
        return (
          <Paper
            key={item}
            sx={{
              paddingBottom: '20px',
              marginBottom: '32px',
              marginLeft: '48px',
              marginRight: '48px'
            }}>
            <Grid key={item}>
              <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                  Course Type <span className="required">*</span>
                </Grid>
                <Grid item xs={10}>
                  <span>
                    <Select
                      id={`${item.key}coursetype`}
                      key={`${item.key}coursetype`}
                      // value={age}
                      {...register('courseType')}
                      sx={{ width: 450, marginLeft: 10 }}
                      size="small"
                      MenuProps={{ sx: { height: '500px' } }}
                      error={item.isFormSubmitted && (!item.courseType || item.courseType === '')}
                      // required="true"
                      value={item.courseType}
                      onChange={(e) => {
                        let is_value_changed =true;
                        if(item.courseType != undefined){
                          is_value_changed =false;
                          // let msg ='If you change Course Type name for existing Course Type present in teacher profile, existing Course Type will get deleted from "Add/Edit Teacher Country". Are you sure you want to continue?';
                          // if(confirm(msg)){
                            // is_value_changed =false;
                          // updateCTypeClose();
                            setUpdateCourseType({key:item.key,value:e.target.value,prev_value:item.courseType});
                             updateCTypeOpen();
                          // }
                        }
                        if(is_value_changed){
                          const rowKey = item.key;
                          const rowIndex = courseInfoData.findIndex((i) => i.key === rowKey);
                          const rowData = courseInfoData[rowIndex];
                          rowData.courseType = e.target.value;
                          const finalRowData = courseInfoData;
                          finalRowData[rowIndex] = rowData;
                          setCourseInfoData([...finalRowData]);
                          let courseInfoarray = [];
                          courseInfoData.map(ctype => {
                            courseInfoarray.push(ctype.courseType ? (ctype.courseType).toString():null);
                          });
                          let teacherCountryUpdate = [];
                          props.teachingCountryData.map(t_country => {
                            const intersection = courseInfoarray.filter(element => t_country.courseTypes.includes(element));
                            t_country.courseTypes = intersection || [];
                            teacherCountryUpdate.push(t_country)
                          });
                          props.setTeachingCountryData(teacherCountryUpdate);
                        }
                      }}
                      label="Enter Course Type">
                      {courseTypeOptions
                        // .filter((row) => !courseTypeArray.includes(row.courseType))
                        .map((item) => {
                          return (
                            <MenuItem
                              disabled={courseTypeSelectedArray.includes(item.id)}
                              key={item.programtypename}
                              value={item.id}>
                              {item.programtypename}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <span>
                      <span style={{ color: 'red', marginLeft: '16px' }}>
                        {item.isFormSubmitted &&
                          (!item.courseType || item.courseType === '') &&
                          'Required'}
                      </span>
                    </span>
                  </span>
                </Grid>
              </Grid>
              <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                  TTP Certification Type <span className="required">*</span>
                </Grid>
                <Grid item xs={10}>
                  <span>
                    <Select
                      id={`${item.key}certificateType`}
                      key={`${item.key}certificateType`}
                      {...register('certificateType')}
                      sx={{ width: 450, marginLeft: 10 }}
                      size="small"
                      // required="true"
                      value={item.certificationType}
                      error={
                        item.isFormSubmitted &&
                        (!item.certificationType || item.certificationType === '')
                      }
                      onChange={(e) => {
                        const rowKey = item.key;
                        const rowIndex = courseInfoData.findIndex((i) => i.key === rowKey);
                        const rowData = courseInfoData[rowIndex];
                        rowData.certificationType = e.target.value;
                        const finalRowData = courseInfoData;
                        finalRowData[rowIndex] = rowData;
                        setCourseInfoData([...finalRowData]);
                      }}
                      label="Enter Certificate Type">
                      {ttpCertificationTypeData.map((item) => {
                        return (
                          <MenuItem key={item.lookupdesc} value={item.id}>
                            {item.lookupdesc}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <span style={{ color: 'red', marginLeft: '16px' }}>
                      {item.isFormSubmitted &&
                        (!item.certificationType || item.certificationType === '') &&
                        'Required'}
                    </span>
                  </span>
                </Grid>
              </Grid>

              <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                  TTP Type
                </Grid>
                <Grid item xs={6}>
                  <Select
                    id={`${item.key}ttpType`}
                    key={`${item.key}ttpType`}
                    MenuProps={{ sx: { height: '300px' } }}
                    // {...register('certificateType')}
                    sx={{ width: 450, marginLeft: 10 }}
                    size="small"
                    value={item.ttpType ? getTppNameById(item.ttpType,ttpTypeData) : null}
                    onChange={(e) => {
                      const rowKey = item.key;
                      const rowIndex = courseInfoData.findIndex((i) => i.key === rowKey);
                      const rowData = courseInfoData[rowIndex];
                      rowData.ttpType = e.target.value;
                      const finalRowData = courseInfoData;
                      finalRowData[rowIndex] = rowData;
                      setCourseInfoData([...finalRowData]);
                    }}
                    label="Enter Certificate Type"
                    renderValue={(value) => (
                      <div
                      //  className={classes.chips}
                      >
                        <Chip
                          key={value}
                          label={value}
                          clickable
                          deleteIcon={
                            <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
                          }
                          // className={classes.chip}
                          onDelete={(e) => {
                            const rowKey = item.key;
                            const rowIndex = courseInfoData.findIndex((i) => i.key === rowKey);
                            const rowData = courseInfoData[rowIndex];
                            rowData.ttpType = null;
                            const finalRowData = courseInfoData;
                            finalRowData[rowIndex] = rowData;
                            setCourseInfoData([...finalRowData]);
                          }}
                        />
                      </div>
                    )}>
                    {ttpTypeData.map((item) => {
                      return (
                        <MenuItem key={item.lookupdesc} value={item.id}>
                          {item.lookupdesc}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>

              <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                  TTP Application Country
                </Grid>
                <Grid item xs={6}>
                  <Select
                    id={`${item.key}appCountry`}
                    key={`${item.key}appCountry`}
                    // {...register('ttpAppCountry')}
                    sx={{ width: 450, marginLeft: 10 }}
                    MenuProps={{ sx: { height: '500px' } }}
                    size="small"
                    // onChange={handleChange}

                    value={item.applicationCountry ?  getCountryName(item.applicationCountry)  : null}
                    onChange={(e) => {
                      const rowKey = item.key;
                      const rowIndex = courseInfoData.findIndex((i) => i.key === rowKey);
                      const rowData = courseInfoData[rowIndex];
                      rowData.applicationCountry =  e.target.value;
                      const finalRowData = courseInfoData;
                      finalRowData[rowIndex] = rowData;
                      setCourseInfoData([...finalRowData]);
                    }}
                    label="Enter TTP Application Country"
                    renderValue={(value) => (
                      <div
                      //  className={classes.chips}
                      >
                        <Chip
                          key={value}
                          label={value}
                          clickable
                          deleteIcon={
                            <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
                          }
                          // className={classes.chip}
                          onDelete={(e) => {
                            const rowKey = item.key;
                            const rowIndex = courseInfoData.findIndex((i) => i.key === rowKey);
                            const rowData = courseInfoData[rowIndex];
                            rowData.applicationCountry = null;
                            const finalRowData = courseInfoData;
                            finalRowData[rowIndex] = rowData;
                            setCourseInfoData([...finalRowData]);
                          }}
                        />
                      </div>
                    )}>
                    {countryData.map((item) => {
                      return (
                        <MenuItem key={item.countryid} value={item.countryid}>
                          {item.countryname}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <Grid item xs={10} sx={{ width: 450, marginLeft: 10 }}>
                    <FormHelperText>
                      Country from where the applicant has applied for TTP
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                  TTP Location
                </Grid>
                <Grid item xs={6}>
                  <Select
                    id={`${item.key}location`}
                    key={`${item.key}location`}
                    // {...register('ttpLocation')}
                    sx={{ width: 450, marginLeft: 10 }}
                    size="small"
                    // onChange={handleChange}
                    MenuProps={{ sx: { height: '300px' } }}
                    value={item.location ? getTppNameById(item.location,ttpLocationData) : null}
                    onChange={(e) => {
                      const rowKey = item.key;
                      const rowIndex = courseInfoData.findIndex((i) => i.key === rowKey);
                      const rowData = courseInfoData[rowIndex];
                      rowData.location = e.target.value;
                      const finalRowData = courseInfoData;
                      finalRowData[rowIndex] = rowData;
                      setCourseInfoData([...finalRowData]);
                    }}
                    label="Enter TTP Location"
                    renderValue={(value) => (
                      <div
                      //  className={classes.chips}
                      >
                        <Chip
                          key={value}
                          label={value}
                          clickable
                          deleteIcon={
                            <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
                          }
                          // className={classes.chip}
                          onDelete={(e) => {
                            const rowKey = item.key;
                            const rowIndex = courseInfoData.findIndex((i) => i.key === rowKey);
                            const rowData = courseInfoData[rowIndex];
                            rowData.location = null;
                            const finalRowData = courseInfoData;
                            finalRowData[rowIndex] = rowData;
                            setCourseInfoData([...finalRowData]);
                          }}
                        />
                      </div>
                    )}>
                    {

                    ttpLocationData.map((item) => {
                      return (
                        <MenuItem key={item.lookupdesc} value={item.id}>
                          {item.lookupdesc}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>

              <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                  TTP Graduation Date
                </Grid>
                <Grid item xs={6}>
                  <Grid item xs={6} sx={{ width: 450, marginLeft: 10 }}>
                    <TextField
                      type="date"
                      id={`${item.key}date`}
                      key={`${item.key}date`}
                      value={item.graduationDate}
                      onChange={(e) => {
                        const rowKey = item.key;
                        const rowIndex = courseInfoData.findIndex((i) => i.key === rowKey);
                        const rowData = courseInfoData[rowIndex];
                        rowData.graduationDate = e.target.value;
                        const finalRowData = courseInfoData;
                        finalRowData[rowIndex] = rowData;
                        setCourseInfoData([...finalRowData]);
                      }}
                      id="birthday"
                      name="birthday"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'top' }}>
                  Notes
                </Grid>
                <Grid item xs={6}>
                  <TextareaAutosize
                    {...register(`${item.key}notes`)}
                    placeholder="Enter Notes"
                    name={`${item.key}notes`}
                    minRows={3}
                    style={{ width: 450, marginLeft: 80 }}
                    onChange={(e) => {
                      const rowKey = item.key;
                      const rowIndex = courseInfoData.findIndex((i) => i.key === item.key);
                      const rowData = courseInfoData[rowIndex];
                      rowData.notes = e.target.value;
                      const finalRowData = courseInfoData;
                      finalRowData[rowIndex] = rowData;
                      setCourseInfoData([...finalRowData]);
                    }}
                    value={item.notes}
                    id={`${item.key}notes`}
                    key={`${item.key}notes`}
                  />
                  {/* <OutlinedInput
                    // {...register('notes')}
                    // {...register('notes')}
                    id={`${item.key}notes`}
                    key={`${item.key}notes`}
                    type="text"
                    name="notes"
                    value={item.notes}
                    onChange={(e) => {
                      const rowKey = item.key;
                      const rowIndex = courseInfoData.findIndex((i) => i.key === rowKey);
                      const rowData = courseInfoData[rowIndex];
                      rowData.notes = e.target.value;
                      const finalRowData = courseInfoData;
                      finalRowData[rowIndex] = rowData;
                      setCourseInfoData([...finalRowData]);
                    }}
                    placeholder="Enter Notes"
                    // error={!!errors.firstName}
                    // helperText={errors?.firstName?.message}
                    sx={{ width: 450, marginLeft: 10 }}
                    size="small"
                    multiline
                  /> */}
                </Grid>
              </Grid>
            </Grid>
            <Dialog
                    open={ctypeopen}
                    onClose={updateCTypeClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description2">
                    {/* <DialogTitle id="alert-dialog-title">
                        {"Use Google's location service?"}
                      </DialogTitle> */}
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description2">
                      If you change Course Type name for existing Course Type present in teacher profile, existing Course Type will get deleted from "Add/Edit Teacher Country". Are you sure you want to continue?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={updateCTypeClose}>No</Button>
                      <Button
                        onClick={() => {
                          const rowKey = updateCourseType.key;
                          const rowIndex = courseInfoData.findIndex((i) => i.key === rowKey);
                          const rowData = courseInfoData[rowIndex];
                          rowData.courseType = updateCourseType.value;
                          const finalRowData = courseInfoData;
                          finalRowData[rowIndex] = rowData;
                          setCourseInfoData([...finalRowData]);
                          let courseInfoarray = [];
                          courseInfoData.map(ctype => {
                            courseInfoarray.push(ctype.courseType ? (ctype.courseType).toString():null);
                          });
                          let teacherCountryUpdate = [];
                          props.teachingCountryData.map(t_country => {
                            const intersection = courseInfoarray.filter(element => t_country.courseTypes.includes(element));
                            t_country.courseTypes = intersection || [];
                            teacherCountryUpdate.push(t_country)
                          });
                          props.setTeachingCountryData(teacherCountryUpdate);
                          updateCTypeClose();
                        }}
                        autoFocus>
                        Yes
                      </Button>
                    </DialogActions>
                  </Dialog>
            {courseInfoData.length > 1 ? (
              <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}></Grid>
                <Grid item xs={6}>
                  <Button
                    sx={{ textTransform: 'none', marginLeft: 10 }}
                    variant="contained"
                    onClick={() => {
                      handleClickOpen();
                      setDeleteCourseKey(item.key);
                    }}>
                    Delete Course Type
                  </Button>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    {/* <DialogTitle id="alert-dialog-title">
                        {"Use Google's location service?"}
                      </DialogTitle> */}
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this course type?{' '}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>No</Button>
                      <Button
                        onClick={() => {
                          const rowKey = deleteCourseKey;
                          const rowIndex = courseInfoData.findIndex((i) => i.key === rowKey);
                          courseInfoData.splice(rowIndex, 1);
                          setCourseInfoData([...courseInfoData]);
                          let courseInfoarray = [];
                          courseInfoData.map(ctype => {
                            courseInfoarray.push((ctype.courseType).toString());
                          });
                          let teacherCountryUpdate = [];
                          props.teachingCountryData.map(t_country => {
                            const intersection = courseInfoarray.filter(element => t_country.courseTypes.includes(element));
                            t_country.courseTypes = intersection || [];
                            console.log('intersect');
                            console.log(t_country.courseTypes);
                            teacherCountryUpdate.push(t_country)
                          });
                          props.setTeachingCountryData(teacherCountryUpdate);
                          setOpen(false);
                        }}
                        autoFocus>
                        Yes
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant="contained"
                    sx={{ textTransform: 'none' }}
                    onClick={() => {
                      const data = courseInfoData;
                      const index = courseInfoData.findIndex((i) => +i.key === +item.key);

                      data.push({ key: courseInfoRowKey + 1, notes: '' });
                      setCourseInfoRowKey(courseInfoRowKey + 1);

                      setCourseInfoData([...data]);
                      // window.scrollTo(10000, 10000);
                    }}>
                    Add New Course Type
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}></Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={3}>
                  <Button
                    variant="contained"
                    sx={{ textTransform: 'none' }}
                    onClick={() => {
                      const data = courseInfoData;
                      const index = courseInfoData.findIndex((i) => +i.key === +item.key);
                      data.push({ key: courseInfoRowKey + 1, notes: '' });
                      setCourseInfoRowKey(courseInfoRowKey + 1);
                      setCourseInfoData([...data]);
                      // window.scrollTo(10000, 10000);
                    }}>
                    Add New Course Type
                  </Button>
                </Grid>
              </Grid>
            )}
          </Paper>
        );
      })}
      {/* <Button
          variant="contained"
          sx={{ textTransform: 'none', marginLeft: '80vw' }}
          onClick={() => {
            const data = courseInfoData;
            data.push({ key: courseInfoRowKey + 1, notes: '' });
            setCourseInfoRowKey(courseInfoRowKey + 1);
            setCourseInfoData([...data]);
          }}>
          Add Course Type
        </Button> */}
      {/* </Paper> */}
    </>
  );
};

export default renderCourseInformation;

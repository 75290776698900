import React, { useState } from 'react';
import './Navbar.css';
import MenuIcon from '@mui/icons-material/Menu';
import Aol from '../../assets/images/aol logo.png';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import TabBar from '../TabBar/TabBar';
import { Link, useNavigate } from 'react-router-dom';

function Navbar(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  let navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const user_name = sessionStorage.getItem('username');

  const handleLogout = (event) => {
    sessionStorage.clear();
    navigate('/login');
  };

  return (
    <div className="navbar-full-container">
      <div className="navbar-container">
        <div className="navbar-content">
          <div className="navbar-left">
            {/* <div>
              <MenuIcon className="menu-icon" />
            </div> */}
            <div>
              <img src={Aol} onClick={() => navigate('/home')} alt="" className="navbar-aolimage" />
            </div>
          </div>
          <div style={{ marginRight: 'auto', marginLeft: '50px' }} className="navbar-center">
            <TabBar
              isFindTeachersClicked={props.isFindTeachersClicked}
              setIsFindTeachersClicked={props.setIsFindTeachersClicked}
              setIsFindPTClicked={props.setIsFindPTClicked}         
              applyClearFilters={() => props.applyClearFilters &&  props.applyClearFilters()}
            />
          </div>
          <div className="navbar-right">
            <div>
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}>
                <Avatar sx={{ backgroundColor: 'whitesmoke', color: 'gray' }}>
                  {user_name && user_name[0].toUpperCase()}
                </Avatar>
              </IconButton>

              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0
                    }
                  }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                <Link to="/changepassword" style={{ textDecoration: 'none', color: 'black' }}>
                  <MenuItem>Change Password</MenuItem>
                </Link>
                {/* <Link to="/login" style={{ textDecoration: 'none', color: 'black' }}> */}
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                {/* </Link> */}
              </Menu>
            </div>
            <div className="navbar-right-content">
              {user_name && user_name.charAt(0).toUpperCase() + user_name.slice(1)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;

import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router';
import UiButton from 'Components/UiCore/FormComponent/UiButton/UiButton';
import Box from '@mui/material/Box';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import SearchIcon from '@mui/icons-material/Search';

import Paper from '@mui/material/Paper';

import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControlLabel from '@mui/material/FormControlLabel';

import Checkbox from '@mui/material/Checkbox';
import * as axios from 'axios';

import { useForm, Controller } from 'react-hook-form';
import PageTemplate from 'Components/UiCore/pageTemplate/PageTemplate';
import healthCategoryMapping from '../healthCategoryMapping.json';



function TeachersNewPage(props) {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm();
  const navigate = useNavigate();
  const [programTypesData, setProgramTypesData] = React.useState({});
  const [pageLoader, setPageLoader] = useState(true);

  const location = window.location.href;
  const programTypedId = location.split('/').at(-2);

  useEffect(() => {
    const token = sessionStorage.getItem('user');
    let mounted = true;
    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/pgm/viewprogram`, {
        params: {
          id: programTypedId
        },
        headers: { authorization: `Bearer ${token}` }
      })
      .then((response) => {
        setPageLoader(false);
        console.log(response.data[0], 'major debug');
        setProgramTypesData(response.data[0]);
      });

    return () => (mounted = false);
  }, []);

  const onSubmit = async (data) => {
    const entries = Object.entries(data).filter((entry) => entry[0] !== 'files');
    event.preventDefault();
    // axios
    //   .post(`${process.env.REACT_APP_BASE_API_URL}/teachers/createteacher`, payload)
    //   .then((response) => {
    //     {
    //       navigate('/teachers');
    //     }
    //   });
  };
  const data = programTypesData;

  return (
    <div>
      <PageTemplate tabBar navbar>
        {Object.keys(data).length > 0 && (
          <Box sx={{ width: '100%', marginTop: '40px' }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Paper sx={{ paddingBottom: '40px' }}>
                <Grid sx={{ marginLeft: '32px', paddingTop: '32px' }}>
                  <Grid contaier sx={{ marginTop: '16px', marginBottom: '16px' }}>
                    <h2> View Program Type </h2>
                  </Grid>
                </Grid>
                <hr className="hr-line"></hr>
                <Paper
                  sx={{
                    marginTop: '32px',
                    paddingTop: '16px',
                    marginLeft: '32px',
                    marginRight: '32px',
                    paddingBottom: '16px'
                  }}>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '40px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Organization
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              data.organization.split(',').find((item) => item === 'Org 1') !==
                              undefined
                            }
                            disabled
                            {...register('org1')}
                          />
                        }
                        label="Org 1"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              data.organization.split(',').find((item) => item === 'Org 2') !==
                              undefined
                            }
                            disabled
                            {...(register('org2') !== undefined)}
                          />
                        }
                        label="Org 2"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              data.organization.split(',').find((item) => item === 'Org 3') !==
                              undefined
                            }
                            disabled
                            {...(register('org3') !== undefined)}
                          />
                        }
                        label="Org 3"
                      />
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '40px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Program Type
                    </Grid>
                    <Grid item xs={6}>
                      Course Type
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '40px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Program Type Name
                    </Grid>
                    <Grid item xs={6}>
                      {data.programtypename}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '40px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Is Kriya Taught
                    </Grid>
                    <Grid item xs={6}>
                      {data.iskriyataught === 1 ? 'Yes' : 'No'}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '40px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Health Category
                    </Grid>
                    <Grid item xs={6}>
                      {data.healthcategory} - Type {healthCategoryMapping[data.healthcategory]}
                    </Grid>
                  </Grid>{' '}
                  {/* <Grid> */}
                  <Paper
                    container
                    sx={{
                      marginTop: '32px',
                      marginLeft: '48px',
                      marginRight: '48px',
                      marginBottom: '10px',
                      backgroundColor: '#E8E8E8',
                      // color: 'blac',
                      borderRadius: '8px',
                      padding: '10px',
                      width: '50vw',
                      paddingBottom: '32px'
                    }}>
                    <Grid container sx={{ marginTop: '0px', marginLeft: '2px' }} spacing={2}>
                      <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                        Program Type ID (Org 1)
                      </Grid>
                      <Grid item xs={3}>
                        {data.org1id}
                      </Grid>
                    </Grid>{' '}
                    <Grid container sx={{ marginTop: '0px', marginLeft: '2px' }} spacing={2}>
                      <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                        Program Type ID (Org 2)
                      </Grid>
                      <Grid item xs={2}>
                        {data.org2id}
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: '0px', marginLeft: '2px' }} spacing={2}>
                      <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                        Program Type ID (Org 3)
                      </Grid>
                      <Grid item xs={2}>
                        {data.org3id}
                      </Grid>
                    </Grid>
                  </Paper>
                  {/* <Paper sx={{ backgroundColor: 'grey', borderRadius: '8px' }}>

                    </Paper> */}
                  {/* </Grid> */}
                  <Grid container sx={{ marginTop: '0px', marginLeft: '40px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Notes
                    </Grid>
                    <Grid item xs={6}>
                      {data.notes}
                    </Grid>
                  </Grid>
                </Paper>
                <Button
                  sx={{ marginLeft: '48px', marginTop: '16px', marginBottom: '50px' }}
                  onClick={() => {
                    navigate(`/program-types/${programTypedId}/edit`);
                  }}
                  variant="contained">
                  Edit
                </Button>
                <Button
                  sx={{ marginLeft: '48px', marginTop: '16px', marginBottom: '50px' }}
                  onClick={() => {
                    navigate('/program-types/');
                  }}
                  variant="outlined"
                  startIcon={<SearchIcon />}>
                  Find Program Types
                </Button>
              </Paper>
            </form>
          </Box>
        )}
      </PageTemplate>
    </div>
  );
}

export default TeachersNewPage;

/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import Select from '@mui/material/Select';
import UiButton from '../../../UiCore/FormComponent/UiButton/UiButton';
import MenuItem from '@mui/material/MenuItem';
import { OutlinedInput } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const regions = [
  'All Regions',
  'Africa',
  'Brazil',
  'Central Asia',
  'Europe',
  'Far East',
  'Middle East',
  'North America',
  'Oceania',
  'Russia',
  'South America',
  'South Asia'
];

function Home(props) {
  const {
    selectedRegion,
    handleChangeSelect,
    selectedRows,
    quickFilterValue,
    setQuickFilterValue,
    setQuickSearch,
    searchInput
  } = props;
  const navigate = useNavigate();
  const delayedSearch = useCallback(
    debounce((q) => setQuickSearch(q), 600),
    []
  );

  return (
    <div>
      <div>
        <div className="home-icon">
          <div className="home-icon-left-container">
            <div>
              <h3>Home</h3>
            </div>
            <div className="slect-dropdown-container">
              <Select
                sx={{ height: 40 }}
                className="select-dropdown"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="region"
                value={selectedRegion}
                onChange={handleChangeSelect}
                defaultValue={10}>
                {regions.map((region, index) => (
                  <MenuItem value={index} key={index}>
                    {region}
                  </MenuItem>
                ))}
              </Select>

              <OutlinedInput
                className="quick-search"
                // id="outlined-basic"
                ref={searchInput}
                placeholder="Search By Country"
                variant="outlined"
                sx={{ marginLeft: '16px', width: '250px' }}
                onChange={(e) => {
                  setQuickFilterValue(e.target.value);
                  delayedSearch(e.target.value);
                }}
                value={quickFilterValue}
                startAdornment={<SearchIcon />}
              />
            </div>
          </div>
          <div className="home-export-add-container">
            <UiButton
              text="Export"
              onClick={() => {
                navigate('/home/export', {
                  state: {
                    selectedRegion: regions[selectedRegion],
                    selectedRows: selectedRows
                  }
                });
              }}></UiButton>
            <div>
              <UiButton onClick={() => navigate('/teachers/new')} text={'Add Teacher'}></UiButton>
            </div>
          </div>
        </div>
        <hr className="hr-line"></hr>
      </div>
    </div>
  );
}
export default Home;

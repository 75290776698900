/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState, useCallback } from 'react';
import UiButton from 'Components/UiCore/FormComponent/UiButton/UiButton';
import './styles.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as XLSX from 'xlsx/xlsx.mjs';
import CancelIcon from '@mui/icons-material/Cancel';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import debounce from 'lodash/debounce';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import {
  Chip,
  FormControl,
  Grid,
  Input,
  InputBase,
  InputLabel,
  OutlinedInput
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Data_table from './dataTable';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PageTemplate from 'Components/UiCore/pageTemplate/PageTemplate';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import cityData from './cityData.json';

function TeachersListing(props) {
  const [selectedRegion, setSelectedRegion] = React.useState(0);
  const [region_countryData, setRegion_countryData] = React.useState([]);
  const [openAdvModal, setOpenAdvModal] = React.useState(false);

  // modal input fields

  const [name, setName] = React.useState('');
  const [alias, setAlias] = React.useState('');
  const [emailID, setEmailId] = React.useState('');
  const [teacherCode, setTeacherCode] = React.useState('');
  const [selectedRowData, setSelectedRowData] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [hidefilterforquick, setHidefilterforquick] = React.useState(true);

  const [harmonyTeacherCode, setHarmonyTeacherCode] = React.useState('');

  const [countries, setCountries] = React.useState(['India', 'Brazil', 'USA', 'UK']);
  const [selectedCurrentCountry, setSelectedCurrentCountry] = React.useState([]);
  const [selectedTTPCountry, setSelectedTTPCountry] = React.useState([]);
  const [selectedTeachingCountry, setselectedTeachingCountry] = React.useState([]);
  const [courseTypes, setCourseTypes] = React.useState([]);
  const [quickSearchValue, setQuickSearchValue] = React.useState('');
  const [selectedCourseType, setSelectedCourseType] = React.useState([]);
  const [applyClearFilters, setApplyClearFilters] = React.useState(false);
  const [ttpLocationData, setTtpLocationData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [countryData, setcountryData] = React.useState([]);

  const [selectedCertificationType, setSelectedCertificationType] = React.useState({
    Certified: false,
    'Certified Online': false,
    'Co-teach': false
  });
  const [selectedTTPLocation, setSelectedTTPLocation] = React.useState({});
  const [teacherStatus, setTeacherStatus] = React.useState({
    Active: false,
    'Inactive - Deceased': false,
    'Inactive - Not with Org': false,
    'Inactive - Suspended': false,
    'Inactive - Return Kriya Tape and Manual': false,
    'Inactive - Not Teaching': false,
    'View Only': false
  });
  const [teacherType, setTeacherType] = React.useState({
    'Full Time': false,
    'Part Time': false
  });
  const [agreementSigned, setAgreementSigned] = React.useState({
    Yes: false,
    No: false
  });
  const [expiredDate, setExpiredDate] = React.useState('');
  const [signedOnDate, setSignedOnDate] = React.useState('');

  // adv search Filters

  const [searchFilter, setSearchFilters] = React.useState({});
  const [isFindTeachersClicked, setIsFindTeachersClicked] = React.useState(false);
  const getCourseNameById = (id)=>{
    const cname = courseTypes.find(
                                   (item) => item.id == id
                                  ).programtypename;
    return cname;
  }
  useEffect(() => {
    resetFiltersToDefault();
    setApplyClearFilters(false);
  }, [applyClearFilters]);

  useEffect(() => {
    // countries
    const token = sessionStorage.getItem('user');

    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/teachers/countries`, {
        headers: { authorization: `Bearer ${token}` }
      })
      .then((response) => {
        setCountries([
          { countryname: 'All Countries', regionname: 'All Regions' },
          ...response.data
        ]);
        setcountryData(response.data);
        setselectedTeachingCountry([]);
        setSelectedCurrentCountry([]);
        setSelectedTTPCountry([]);
      })
      .catch((error) => console.error(error));
    axios
      .get(
        `${process.env.REACT_APP_BASE_API_URL}/teachers/coursetypes`,

        { headers: { authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setCourseTypes([{ programtypename: 'All Courses' }, ...response.data]);
        setSelectedCourseType([]);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem('user');
    axios
      .get(
        `${process.env.REACT_APP_BASE_API_URL}/teachers/ttpcertificationtype`,

        { headers: { authorization: `Bearer ${token}` } }
      )
      .then((response) => {})
      .catch((error) => console.error(error));

    axios
      .get(
        `${process.env.REACT_APP_BASE_API_URL}/teachers/ttptype`,

        { headers: { authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => console.error(error));

    axios
      .get(
        `${process.env.REACT_APP_BASE_API_URL}/teachers/ttplocation`,

        { headers: { authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
        let loC = [{ programtypename: 'All Courses' }, ...response.data];
        setTtpLocationData(response.data);
        let obj = {};
        response.data.map((item) => {
          obj[item.lookupdesc] = false;
        });

        setSelectedTTPLocation(obj);

        // setCourseTypes(loC);
        // setSelectedCourseType('All Courses');
      })
      .catch((error) => {
        console.error(error);
        navigate('/');
      });
  }, []);

  const navigate = useNavigate();

  const resetFiltersToDefault = () => {
    setName('');
    setAlias('');
    setEmailId('');
    setTeacherCode('');
    setHarmonyTeacherCode('');
    setSelectedCurrentCountry([]);
    setSelectedTTPCountry([]);
    setselectedTeachingCountry([]);
    setSelectedCourseType([]);
    setSelectedCertificationType({
      Certified: false,
      'Certified Online': false,
      'Co-teach': false
    });

    let obj = {};
    ttpLocationData.map((item) => {
      obj[item.lookupdesc] = false;
    });

    setSelectedTTPLocation(obj);
    setTeacherStatus({
      Active: false,
      'Inactive - Deceased': false,
      'Inactive - Not with Org': false,
      'Inactive - Suspended': false,
      'Inactive - Return Kriya Tape and Manual': false,
      'Inactive - Not Teaching': false,

      'View Only': false
    });
    setTeacherType({
      'Full Time': false,
      'Part Time': false
    });
    setAgreementSigned({
      Yes: false,
      No: false
    });
    setExpiredDate('');
    setSignedOnDate('');

    console.log('im happening 989');

    setSearchFilters({
      name: '',
      alias: '',
      emailID: '',
      teacherCode: '',
      harmonyTeacherCode: '',
      currentCountry: [],
      ttpCountry: [],
      teachingCountry: [],
      courseTypes: [],
      certificationType: {
        Certified: false,
        'Certified Online': false,
        'Co-teach': false
      },
      teacherStatus: {
        Active: false,
        'Inactive - Deceased': false,
        'Inactive - Not with Org': false,
        'Inactive - Suspended': false,
        'Inactive - Return Kriya Tape and Manual': false,
        'Inactive - Not teaching': false,
        'View Only': false
      },
      teacherType: {
        'Full Time': false,
        'Part Time': false
      },
      agreementSigned: {
        Yes: false,
        No: false
      },
      agreementSignedOn: '',
      agreementExpiresOn: '',
      quickSearch: ''
    });
    setQuickSearchValue('');
  };

  const setFiltersFromLocalStorage = () => {
    let filtersObj = localStorage.getItem('teachersAdvSearch')
      ? JSON.parse(localStorage.getItem('teachersAdvSearch'))
      : {};

    console.log(filtersObj.name);

    if (localStorage.getItem('teachersAdvSearch')) {
      setName(filtersObj.name);
      setAlias(filtersObj.alias);
      setEmailId(filtersObj.emailID);
      setTeacherCode(filtersObj.teacherCode);
      setHarmonyTeacherCode(filtersObj.harmonyTeacherCode);
      setSelectedCurrentCountry(filtersObj.currentCountry);
      setSelectedTTPCountry(filtersObj.ttpCountry);
      setselectedTeachingCountry(filtersObj.teachingCountry);
      setSelectedCourseType(filtersObj.courseTypes);
      setSelectedCertificationType(filtersObj.certificationType);
      setSelectedTTPLocation(filtersObj.ttpLocation);

      setTeacherStatus(filtersObj.teacherStatus);
      setTeacherType(filtersObj.teacherType);
      setAgreementSigned(filtersObj.agreementSigned);
      setExpiredDate(filtersObj.agreementExpiresOn);
      setSignedOnDate(filtersObj.agreementSignedOn);

      setSearchFilters(filtersObj);
      setQuickSearchValue('');
    } else {

      setSearchFilters({
        name: filtersObj.name ? filtersObj.name : '',
        alias: filtersObj.alias ? filtersObj.alias : '',
        emailID: filtersObj.emailID ? filtersObj.emailID : '',
        teacherCode: filtersObj.teacherCode ? filtersObj.teacherCode : '',
        harmonyTeacherCode: filtersObj.harmonyTeacherCode ? filtersObj.harmonyTeacherCode : '',
        currentCountry: filtersObj.currentCountry ? filtersObj.currentCountry : '',
        ttpCountry: filtersObj.ttpCountry ? filtersObj.ttpCountry : '',
        teachingCountry: filtersObj.teachingCountry ? filtersObj.teachingCountry : '',
        courseTypes: filtersObj.courseTypes ? filtersObj.courseTypes : '',
        certificationType: filtersObj.certificationType ? filtersObj.certificationType : '',
        teacherStatus: filtersObj.teacherStatus ? filtersObj.teacherStatus : '',
        teacherType: filtersObj.teacherType ? filtersObj.teacherType : '',
        agreementSigned: filtersObj.agreementSigned ? filtersObj.agreementSigned : '',
        agreementSignedOn: filtersObj.agreementSignedOn ? filtersObj.agreementSignedOn : '',
        agreementExpiresOn: filtersObj.agreementExpiresOn ? filtersObj.agreementExpiresOn : '',
        quickSearch: ''
      });
    }
    setLoader(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setFiltersFromLocalStorage();
    }, 1000);
  }, []);

  const handleChangeSelect = (index) => {
    const token = sessionStorage.getItem('user');
    setSelectedRegion(regions[index]);
    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/home/countriesdata`, {
        params: { region: regions[index] },
        headers: { authorization: `Bearer ${token}` }
      })
      .then((response) => {
        //  updateSomewhere(res);
        setRegion_countryData(response.data);
        // mainTabledata=response.data;
      })
      .catch((error) => console.error(error));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleExpiryDateChange = (event) => {
    setExpiredDate(event.target.value);
  };

  const handleSignedOnDateChange = (event) => {
    setSignedOnDate(event.target.value);
  };

  const setAdvFilters = () => {
    localStorage.setItem(
      'teachersAdvSearch',
      JSON.stringify({
        name: name,
        alias: alias,
        emailID: emailID,
        teacherCode: teacherCode,
        harmonyTeacherCode: harmonyTeacherCode,
        currentCountry: selectedCurrentCountry,
        ttpCountry: selectedTTPCountry,
        teachingCountry: selectedTeachingCountry,
        courseTypes: selectedCourseType,
        certificationType: selectedCertificationType,
        ttpLocation: selectedTTPLocation,
        teacherStatus: teacherStatus,
        teacherType: teacherType,
        agreementSigned: agreementSigned,
        agreementSignedOn: signedOnDate,
        agreementExpiresOn: expiredDate,
        quickSearch: quickSearchValue
      })
    );

    setSearchFilters({
      name: name,
      alias: alias,
      emailID: emailID,
      teacherCode: teacherCode,
      harmonyTeacherCode: harmonyTeacherCode,
      currentCountry: selectedCurrentCountry,
      ttpCountry: selectedTTPCountry,
      ttpLocation: selectedTTPLocation,
      teachingCountry: selectedTeachingCountry,
      courseTypes: selectedCourseType,
      certificationType: selectedCertificationType,
      teacherStatus: teacherStatus,
      teacherType: teacherType,
      agreementSigned: agreementSigned,
      agreementSignedOn: signedOnDate,
      agreementExpiresOn: expiredDate,
      quickSearch: quickSearchValue
    });
  };

  const setQuickSearch = (value) => {
    setSearchFilters({
      name: name,
      alias: alias,
      emailID: emailID,
      teacherCode: teacherCode,
      harmonyTeacherCode: harmonyTeacherCode,
      currentCountry: selectedCurrentCountry,
      ttpCountry: selectedTTPCountry,
      teachingCountry: selectedTeachingCountry,
      courseTypes: selectedCourseType,
      certificationType: selectedCertificationType,
      ttpLocation: selectedTTPLocation,
      teacherStatus: teacherStatus,
      teacherType: teacherType,
      agreementSigned: agreementSigned,
      agreementSignedOn: signedOnDate,
      agreementExpiresOn: expiredDate,
      quickSearch: value
    });
  };

  const delayedSearch = useCallback(
    debounce((q) => setQuickSearch(q), 600),
    []
  );
  const getCountryName = (id)=>{
    if(id && id != "" && countryData.length >0 ){
      const countryname = countryData.find(
                                (item) => item.countryid === id
                              ).countryname;
      return countryname;
    }
  }

  const areFiltersApplied = () => {
    const filters = searchFilter;

    let areFiltersApplied = false;
    console.log(filters, filters.toString().length, 'debug filter');
    console.log(areFiltersApplied, 'debug filter 1');
    if (filters.agreementSigned) {
      if (filters.agreementSigned.Yes !== false || filters.agreementSigned.No !== false) {
        areFiltersApplied = true;
      }
      console.log(areFiltersApplied, 'debug filter 2');

      if (filters.agreementSignedOn !== '') {
        areFiltersApplied = true;
      }

      if (filters.alias !== '') {
        areFiltersApplied = true;
      }

      if (
        filters.certificationType.Certified !== false ||
        filters.certificationType[`Certified Online`] !== false ||
        filters.certificationType[`Co-teach`] !== false
      ) {
        areFiltersApplied = true;
      }

      if (filters.courseTypes.length !== 0) {
        areFiltersApplied = true;
      }
      console.log(areFiltersApplied, 'debug filter 2');

      if (filters.currentCountry.length !== 0) {
        areFiltersApplied = true;
      }

      if (filters.emailID !== '') {
        areFiltersApplied = true;
      }
      if (filters.harmonyTeacherCode !== '') {
        areFiltersApplied = true;
      }
      console.log(areFiltersApplied, 'debug filter 3');

      if (filters.name !== '') {
        areFiltersApplied = true;
      }
      if (filters.quickSearch !== '') {
        areFiltersApplied = true;
      }
      if (filters.teacherCode !== '') {
        areFiltersApplied = true;
      }
      console.log(
        areFiltersApplied,
        'debug filter 6',
        filters.teacherStatus.Active,
        filters.teacherStatus[`Inactive - Deceased`],
        filters.teacherStatus[`Inactive - Not Teaching`],
        filters.teacherStatus[`Inactive - Not with Org`],
        filters.teacherStatus[`Inactive - Return Kriya Tape and Manual`],
        filters.teacherStatus[`Suspended`],
        filters.teacherStatus[`View Only`]
      );

      if (
        filters.teacherStatus.Active !== false ||
        filters.teacherStatus[`Inactive - Deceased`] !== false ||
        // filters.teacherStatus[`Inactive - Not Teaching`] !== false ||
        filters.teacherStatus[`Inactive - Not with Org`] !== false ||
        filters.teacherStatus[`Inactive - Return Kriya Tape and Manual`] !== false ||
        // filters.teacherStatus[`Suspend   ed`] !== false ||
        filters.teacherStatus[`View Only`] !== false
      ) {
        areFiltersApplied = true;
      }
      console.log(areFiltersApplied, 'debug filter 4');

      if (
        filters.teacherType[`Full Time`] !== false ||
        filters.teacherType[`Part Time`] !== false
      ) {
        areFiltersApplied = true;
      }
      if (filters.teachingCountry.length !== 0) {
        areFiltersApplied = true;
      }
      if (filters.ttpCountry.length !== 0) {
        areFiltersApplied = true;
      }
      if (filters.ttpLocation) {
        if (
          filters.ttpLocation[`Art of Living Center Bad Antogast, Germany`] !== false ||
          filters.ttpLocation[`Art of Living International Center, Bangalore`] !== false ||
          filters.ttpLocation[`Art of Living Retreat Center, Boone`] !== false
        ) {
          areFiltersApplied = true;
        }
      }
      console.log(areFiltersApplied, 'debug filter 5');
    }

    return areFiltersApplied;
  };




  return (
    <PageTemplate
      tabBar
      navbar
      applyClearFilters={() => {
        setApplyClearFilters(true);
        resetFiltersToDefault();
        localStorage.setItem('teachersAdvSearch', '');
      }}
      isFindTeachersClicked={isFindTeachersClicked}
      setIsFindTeachersClicked={setIsFindTeachersClicked}>
      <div>
        <div className="home-inner-container1">
          <div className="home-inner-container2">
            <div>
              <div className="home-icon">
                <div className="home-icon-left-container-teachers">
                  <h3>Find Teachers</h3>
                  <OutlinedInput
                    className="quick-search"
                    id="outlined-basic"
                    placeholder="Search By Name / Code"
                    variant="outlined"
                    sx={{ marginLeft: '16px', width: '250px' }}
                    onChange={(e) => {
                      setQuickSearchValue(e.target.value);
                      delayedSearch(e.target.value);
                      setHidefilterforquick(false);
                    }}
                    value={quickSearchValue}
                    startAdornment={<SearchIcon />}
                  />
                </div>
                <div className="home-export-add-container">
                  <p
                    onClick={() => {
                      setOpenAdvModal(true);
                    }}
                    className="adv-search-btn">
                    Advanced Search
                  </p>

                  {areFiltersApplied() && hidefilterforquick && (
                    <UiButton
                      onClick={() => {
                        resetFiltersToDefault();
                        localStorage.setItem('teachersAdvSearch', '');
                      }}
                      text={'Clear Filters'}></UiButton>
                  )}

                  <UiButton
                    text="Export"
                    onClick={() => {
                      navigate('/teachers/export', {
                        state: {
                          selectedTeacherRows: selectedRows
                        }
                      });
                    }}></UiButton>
                  <UiButton
                    onClick={() => navigate('/teachers/new')}
                    text={'Add Teacher'}></UiButton>
                </div>
                {/* <div>

                </div> */}
              </div>
            </div>
            <hr className="hr-line"></hr>
            {console.log(selectedRows, 'selected id s debug')}
            <Data_table
              onSelectionModelChange={(selectedIds, teachersData) => {
                const selectedRowData = [];
                selectedIds.forEach((id) => {
                  selectedRowData.push(teachersData.find((item) => id === item.basicinfo.id));
                });
                setSelectedRows(selectedIds);
                setSelectedRowData(selectedRowData.filter((item) => item !== undefined));
              }}
              selectionModel={selectedRows}
              filters={searchFilter}
              data={region_countryData}
              height={window.innerHeight - 240}
              hideFooter={false}
              isFindTeachersClicked={isFindTeachersClicked}
              setIsFindTeachersClicked={setIsFindTeachersClicked}
              setAdvFilters={setAdvFilters}
              loader={loader}
              getCountryNameById ={countryData}
            />
            <div></div>
          </div>
        </div>
      </div>
      ;{/* advanced search dialog */}
      <Dialog fullWidth={true} maxWidth={'lg'} open={openAdvModal}>
        <DialogTitle
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '5px',
            marginLeft: '40px',
            marginRight: '40px'
          }}>
          <p>Advanced Search</p>
          <CloseIcon
            onClick={() => setOpenAdvModal(false)}
            style={{ marginLeft: 'auto', cursor: 'pointer' }}></CloseIcon>
        </DialogTitle>
        <div className="model-item-seperator"></div>
        <DialogContent style={{ margin: '40px', marginTop: '10px', marginBottom: '10px' }}>
          <div className="adv-form-items-cont">
            <div className="adv-form-input-cont">
              <p className="adv-form-label">Name</p>
              <TextField
                onChange={(event) => {
                  setName(event.target.value);
                }}
                value={name}
                className="adv-form-input"
                label="Name"
                variant="outlined"
              />
            </div>
            <div className="adv-form-input-cont">
              <p className="adv-form-label">Alias Name</p>
              <TextField
                onChange={(event) => {
                  setAlias(event.target.value);
                }}
                className="adv-form-input"
                label="Alias Name"
                variant="outlined"
                value={alias}
              />
            </div>
            <div className="adv-form-input-cont">
              <p className="adv-form-label">Email ID</p>
              <TextField
                onChange={(event) => {
                  setEmailId(event.target.value);
                }}
                className="adv-form-input"
                label="Email ID"
                variant="outlined"
                value={emailID}
              />
            </div>
            <div className="adv-form-input-cont">
              <p className="adv-form-label">Teacher Code</p>
              <TextField
                onChange={(event) => {
                  setTeacherCode(event.target.value);
                }}
                className="adv-form-input"
                label="Teacher Code"
                variant="outlined"
                value={teacherCode}
              />
            </div>
            <div className="adv-form-input-cont">
              <p className="adv-form-label">Harmony Teacher Code</p>
              <TextField
                onChange={(event) => {
                  setHarmonyTeacherCode(event.target.value);
                }}
                className="adv-form-input"
                label="Harmony Teacher Code"
                variant="outlined"
                value={harmonyTeacherCode}
              />
            </div>
            <div className="adv-form-input-cont">
              <p className="adv-form-label">Current Country</p>
              <FormControl className="adv-form-input" fullWidth>
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                <Select
                  multiple
                  // inputProps={{ 'aria-label': 'Without label' }}
                  placeholder="Select"
                  sx={{ width: '100%' }}
                  label="Select"
                  MenuProps={{ sx: { height: '500px' } }}
                  onChange={(event, e2) => {
                    if (e2.props.value != 'All Countries') {
                      setSelectedCurrentCountry(
                        event.target.value.filter((item) => item != 'All Countries')
                      );
                    } else {
                      setSelectedCurrentCountry(['All Countries']);
                    }
                  }}
                  renderValue={(selected) => (
                    <div
                    // className={classes.chips}
                    >
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={getCountryName(value)}
                          clickable
                          deleteIcon={
                            <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
                          }
                          // className={classes.chip}
                          onDelete={(e, v1) => {
                            const index = selected.findIndex((item) => item === value);
                            selected.splice(index, 1);
                            setSelectedCurrentCountry([...selected]);
                          }}
                        />
                      ))}
                    </div>
                  )}
                  value={selectedCurrentCountry}
                  className="adv-form-input">
                  {countryData.map((item) => {
                    return (
                      <MenuItem key={item.countryid} value={item.countryid}>
                        {item.countryname}
                      </MenuItem>
                    );
                  })}
                  {/* {countries.map((option) => (
                    <MenuItem key={option.countryname} value={option.countryname}>
                      {option.countryname}
                    </MenuItem>
                  ))} */}
                </Select>
              </FormControl>
            </div>
            <div className="adv-form-input-cont">
              <p className="adv-form-label">TTP Application Country</p>
              <FormControl className="adv-form-input" fullWidth>
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                <Select
                  multiple
                  MenuProps={{ sx: { height: '500px' } }}
                  onChange={(event, e2) => {
                    if (e2.props.value != 'All Countries') {
                      setSelectedTTPCountry(
                        event.target.value.filter((item) => item != 'All Countries')
                      );
                    } else {
                      setSelectedTTPCountry(['All Countries']);
                    }
                  }}
                  value={selectedTTPCountry}
                  sx={{ width: '100%' }}
                  label="Select"
                  className="adv-form-input"
                  renderValue={(selected) => (
                    <div
                    // className={classes.chips}
                    >
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={getCountryName(value)}
                          clickable
                          deleteIcon={
                            <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
                          }
                          // className={classes.chip}
                          onDelete={(e, v1) => {
                            const index = selected.findIndex((item) => item === value);
                            selected.splice(index, 1);
                            setSelectedTTPCountry([...selected]);
                          }}
                        />
                      ))}
                    </div>
                  )}>
                  {countryData.map((item) => {
                    return (
                      <MenuItem key={item.countryid} value={item.countryid}>
                        {item.countryname}
                      </MenuItem>
                    );
                  })}
                  {/* {countries.map((option) => (
                    <MenuItem key={option.countryname} value={option.countryname}>
                      {option.countryname}
                    </MenuItem>
                  ))} */}
                </Select>
              </FormControl>
            </div>
            <div className="adv-form-input-cont">
              <p className="adv-form-label">Teaching Country</p>
              <FormControl className="adv-form-input" fullWidth>
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                <Select
                  multiple
                  MenuProps={{ sx: { height: '500px' } }}
                  sx={{ width: '100%' }}
                  label="Select"
                  onChange={(event, e2) => {
                    if (e2.props.value !== 'All Countries') {
                      setselectedTeachingCountry(
                        event.target.value.filter((item) => item !== 'All Countries')
                      );
                    } else {
                      setselectedTeachingCountry(['All Countries']);
                    }
                  }}
                  value={selectedTeachingCountry}
                  className="adv-form-input"
                  renderValue={(selected) => (
                    <div
                    // className={classes.chips}
                    >
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={getCountryName(value)}
                          clickable
                          deleteIcon={
                            <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
                          }
                          // className={classes.chip}
                          onDelete={(e, v1) => {
                            const index = selected.findIndex((item) => item === value);
                            selected.splice(index, 1);
                            setselectedTeachingCountry([...selected]);
                          }}
                        />
                      ))}
                    </div>
                  )}>
                  {countryData.map((item) => {
                    return (
                      <MenuItem key={item.countryid} value={item.countryid}>
                        {item.countryname}
                      </MenuItem>
                    );
                  })}
                  {/* {countries.map((option) => (
                    <MenuItem key={option.countryname} value={option.countryname}>
                      {option.countryname}
                    </MenuItem>
                  ))} */}
                </Select>
              </FormControl>
            </div>
            <div className="adv-form-input-cont">
              <p className="adv-form-label">Course Types</p>
              <FormControl className="adv-form-input" fullWidth>
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                <Select
                  multiple
                  sx={{ width: '100%' }}
                  label="Select"
                  placeholder="Select"
                  onChange={(event, e2) => {
                    if (e2.props.value !== 'All Courses') {
                      setSelectedCourseType(
                        event.target.value.filter((item) => item !== 'All Courses')
                      );
                    } else {
                      setSelectedCourseType(['All Courses']);
                    }
                  }}
                  value={selectedCourseType}
                  MenuProps={{ sx: { height: '500px' } }}
                  className="adv-form-input"
                  renderValue={(selected) => (
                    <div
                    // clas sName={classes.chips}
                    >
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          clickable
                          deleteIcon={
                            <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
                          }
                          // className={classes.chip}
                          onDelete={(e, v1) => {
                            const index = selected.findIndex((item) => item === value);
                            selected.splice(index, 1);
                            setSelectedCourseType([...selected]);
                          }}
                        />
                      ))}
                    </div>
                  )}>
                  {console.log(courseTypes, 'course type debug')}
                  {courseTypes.map((option) => (
                    <MenuItem key={option.programtypename} value={option.programtypename}>
                      {option.programtypename}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="adv-form-input-cont">
              <p className="adv-form-label">Certification Type</p>
              <div style={{ display: 'flex', flexDirection: 'row', flex: 5 }}>
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={selectedCertificationType['Certified']}
                        onChange={(event) => {
                          let dupCertifiedCoursesObj = selectedCertificationType;
                          dupCertifiedCoursesObj['Certified'] = event.target.checked;
                          setSelectedCertificationType(dupCertifiedCoursesObj);
                        }}
                      />
                    }
                    label="Certified"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={selectedCertificationType['Certified Online']}
                        onChange={(event) => {
                          let dupCertifiedCoursesObj = selectedCertificationType;
                          dupCertifiedCoursesObj['Certified Online'] = event.target.checked;
                          setSelectedCertificationType(dupCertifiedCoursesObj);
                        }}
                      />
                    }
                    label="Certified Online"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={selectedCertificationType['Co-teach']}
                        onChange={(event) => {
                          let dupCertifiedCoursesObj = selectedCertificationType;
                          dupCertifiedCoursesObj['Co-teach'] = event.target.checked;
                          setSelectedCertificationType(dupCertifiedCoursesObj);
                        }}
                      />
                    }
                    label="Co-teach"
                  />
                </FormGroup>
              </div>
            </div>
            <div className="adv-form-input-cont">
              <p className="adv-form-label">TTP Location</p>
              <div style={{ display: 'flex', flexDirection: 'row', flex: 5 }}>
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  {ttpLocationData.map((item) => {
                    return (
                      <FormControlLabel
                        key={item.lookupdesc}
                        control={
                          <Checkbox
                            defaultChecked={selectedTTPLocation[item.lookupdesc]}
                            onChange={(event) => {
                              let dupCertifiedCoursesObj = selectedTTPLocation;
                              dupCertifiedCoursesObj[item.lookupdesc] = event.target.checked;
                              setSelectedTTPLocation(dupCertifiedCoursesObj);
                            }}
                          />
                        }
                        label={item.lookupdesc}
                      />
                    );
                  })}
                </FormGroup>
              </div>
            </div>
            <div className="adv-form-input-cont">
              <p className="adv-form-label" style={{ marginBottom: 'auto' }}>
                Teacher Status
              </p>
              <div style={{ display: 'flex', flexDirection: 'row', flex: 5 }}>
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={teacherStatus['Active']}
                        onChange={(event) => {
                          let dupTeacherStatusObj = teacherStatus;
                          dupTeacherStatusObj['Active'] = event.target.checked;
                          setTeacherStatus(dupTeacherStatusObj);
                        }}
                      />
                    }
                    label="Active"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={teacherStatus['Inactive - Deceased']}
                        onChange={(event) => {
                          let dupTeacherStatusObj = teacherStatus;
                          dupTeacherStatusObj['Inactive - Deceased'] = event.target.checked;
                          setTeacherStatus(dupTeacherStatusObj);
                        }}
                      />
                    }
                    label="Inactive - Deceased"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={teacherStatus['Inactive - Not with Organization']}
                        onChange={(event) => {
                          let dupTeacherStatusObj = teacherStatus;
                          dupTeacherStatusObj['Inactive - Not with Organization'] =
                            event.target.checked;
                          setTeacherStatus(dupTeacherStatusObj);
                        }}
                      />
                    }
                    label="Inactive - Not with Organization"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={teacherStatus['Inactive - Suspended']}
                        onChange={(event) => {
                          let dupTeacherStatusObj = teacherStatus;
                          dupTeacherStatusObj['Inactive - Suspended'] = event.target.checked;
                          setTeacherStatus(dupTeacherStatusObj);
                        }}
                      />
                    }
                    label="Inactive - Suspended"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={teacherStatus['Inactive - Return Kriya Tape and Manual']}
                        onChange={(event) => {
                          let dupTeacherStatusObj = teacherStatus;
                          dupTeacherStatusObj['Inactive - Return Kriya Tape and Manual'] =
                            event.target.checked;
                          setTeacherStatus(dupTeacherStatusObj);
                        }}
                      />
                    }
                    label="Inactive - Return Kriya Tape and Manual"
                  />

                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={teacherStatus['Inactive - Not Teaching']}
                        onChange={(event) => {
                          let dupTeacherStatusObj = teacherStatus;
                          dupTeacherStatusObj['Inactive - Not Teaching'] = event.target.checked;
                          setTeacherStatus(dupTeacherStatusObj);
                        }}
                      />
                    }
                    label="Inactive - Not Teaching"
                  /> */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={teacherStatus['View Only']}
                        onChange={(event) => {
                          let dupTeacherStatusObj = teacherStatus;
                          dupTeacherStatusObj['View Only'] = event.target.checked;
                          setTeacherStatus(dupTeacherStatusObj);
                        }}
                      />
                    }
                    label="View Only"
                  />
                </FormGroup>
              </div>
            </div>
            <div className="adv-form-input-cont">
              <p className="adv-form-label">Teacher Type</p>
              <div style={{ display: 'flex', flexDirection: 'row', flex: 5 }}>
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={teacherType['Full Time']}
                        onChange={(event) => {
                          let dupTeacherType = teacherType;
                          dupTeacherType['Full Time'] = event.target.checked;
                          setTeacherType(dupTeacherType);
                        }}
                      />
                    }
                    label="Full Time"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={teacherType['Part Time']}
                        onChange={(event) => {
                          let dupTeacherType = teacherType;
                          dupTeacherType['Part Time'] = event.target.checked;
                          setTeacherType(dupTeacherType);
                        }}
                      />
                    }
                    label="Part Time"
                  />
                </FormGroup>
              </div>
            </div>
            <div className="adv-form-input-cont">
              <p className="adv-form-label">Teacher agreement signed</p>
              <div style={{ display: 'flex', flexDirection: 'row', flex: 5 }}>
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={agreementSigned['Yes']}
                        onChange={(event) => {
                          let dupAgreementSigned = agreementSigned;
                          dupAgreementSigned['Yes'] = event.target.checked;
                          setAgreementSigned(dupAgreementSigned);
                        }}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={agreementSigned['No']}
                        onChange={(event) => {
                          let dupAgreementSigned = agreementSigned;
                          dupAgreementSigned['No'] = event.target.checked;
                          setAgreementSigned(dupAgreementSigned);
                        }}
                      />
                    }
                    label="No"
                  />
                </FormGroup>
              </div>
            </div>
            <div className="adv-form-input-cont">
              <p className="adv-form-label">Teacher agreement Signed on or before</p>
              <input
                onChange={(event) => {
                  handleSignedOnDateChange(event);
                }}
                className="adv-form-input"
                style={{ height: '55px', paddingLeft: '20px', borderRadius: '5px' }}
                required="true"
                type="date"
                value={signedOnDate}
                id="agreementSignedOn"
                name="dob"
              />
            </div>
            <div className="adv-form-input-cont">
              <p className="adv-form-label">Teacher agreement expiry on or before</p>
              <input
                className="adv-form-input"
                onChange={(event) => {
                  handleExpiryDateChange(event);
                }}
                style={{ height: '55px', paddingLeft: '20px', borderRadius: '5px' }}
                required="true"
                value={expiredDate}
                type="date"
                id="agreementExpiredOn"
                name="dob"
              />
            </div>
          </div>
        </DialogContent>
        <div className="model-item-seperator"></div>
        <DialogActions style={{ margin: '20px' }}>
          <UiButton
            onClick={() => {
              setAdvFilters();
              setOpenAdvModal(false);
            }}
            style={{ marginLeft: 'auto' }}
            text={'Search'}></UiButton>
          <p
            onClick={() => setOpenAdvModal(false)}
            style={{ marginRight: 'auto', marginLeft: '20px', cursor: 'pointer' }}>
            Cancel
          </p>
        </DialogActions>
      </Dialog>
    </PageTemplate>
  );
}
export default TeachersListing;

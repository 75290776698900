/* eslint-disable react/prop-types */
import { TextField } from '@mui/material';
import { OutlinedTextFieldProps } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import FormHelperText from '@mui/material/FormHelperText';
import { useController } from 'react-hook-form';

const InputFormFeild = (props) => {
  const {
    field,
    fieldState: { error }
  } = useController({ name: props.name, defaultValue: '' });
  return (
    <div>
      {/* {console.log('feild value', field.value)} */}
      <TextField
        {...props}
        {...field}
        className={props.className}
        helperText={error?.message || ''}
        error={!!error?.message}
      />
    </div>
  );
};

export default InputFormFeild;
InputFormFeild.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string
};

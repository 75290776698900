/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import UiButton from 'Components/UiCore/FormComponent/UiButton/UiButton';
import './styles.css';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { FormControl, IconButton, InputBase, InputLabel, Menu } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Chip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AllReg from 'Components/Templates/Home/All_register/Alreg';
import Dialog from '@mui/material/Dialog';
import FilterListIcon from '@mui/icons-material/FilterList';
// import SortIcon from '@mui/icons-material/Sort';
import SortIcon from '../../../sort.png';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import CloseIcon from '@mui/icons-material/Close';

import Button from '@mui/material/Button';
// import Data_table from './dataTable';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import PageTemplate from 'Components/UiCore/pageTemplate/PageTemplate';
import {
  DataGrid,
  ColDef,
  ValueGetterParams,
  GridColumnHeaderTitle,
  GridColumnHeaderSortIcon
} from '@mui/x-data-grid';
import json2csv from 'json2csv';
import { CSVLink } from 'react-csv';
import { padding } from '@mui/system';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
// console.log('token', token);

const healthCategoryMap = {
  'SK Taught': 'Type 1',
  'SK Practised': 'Type 2',
  'Sahaj / Meditation courses': 'Type 3',
  'Yoga courses': 'Type 4',
  'Other courses': 'Type 5',
  'Youth courses': 'Type 6',
  'Online Youth courses': 'Type 7',
  'Online Yoga courses': 'Type 8'
};

function TeachersListing(props) {
  const [newValue, setValue] = React.useState(0);
  const [selectedRegion, setSelectedRegion] = React.useState(0);
  const [region_countryData, setRegion_countryData] = React.useState([]);
  const [programTypesData, setProgramTypesData] = React.useState([]);
  const [pageLoader, setPageLoader] = useState(true);
  const [openAdvModal, setOpenAdvModal] = React.useState(false);
  const [searchFilter, setSearchFilters] = React.useState({});
  const [isFindPTClicked, setIsFindPTClicked] = React.useState(false);
  const [applyClearFilters, setApplyClearFilters] = React.useState(false);
  const [loader, setLoader] = React.useState(true);
  const [apiLoader, setApiLoader] = React.useState(true);


  const [selectedProgramTypeName, setSelectedProgramTypeName] = React.useState([

  ]);
  const [selectedHealthCategory, setSelectedHealthCategory] = React.useState([

  ]);
  const [selectedOrganization, setSelectedOrganization] = React.useState([]);
  const [selectedRowData, setSelectedRowData] = React.useState([]);

  const [programtypeNames, setProgramTypeNames] = React.useState({
    'All Program Type Names': 'All Program Type Names'
  });
  const [organizations, setOrganizations] = React.useState({
    'All Organizations': 'All Organizations'
  });
  const [healthCategories, setHealthCategories] = React.useState({
    'All Health Categories': 'All Types',
    'SK Taught': 'Type 1',
    'SK Practised': 'Type 2',
    'Sahaj / Meditation courses': 'Type 3',
    'Yoga courses': 'Type 4',
    'Other courses': 'Type 5',
    'Youth courses': 'Type 6',
    'Online Youth courses': 'Type 7',
    'Online Yoga courses': 'Type 8'
  });

  const [filteredRows, setFilteredRows] = React.useState([]);
  const [rows, setRows] = React.useState([]);

  const [listOrder, setListOrder] = React.useState('ASC');

  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleSortHover(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  useEffect(() => {
  //  resetFilters();
    setApplyClearFilters(false);
    console.log('setFilterItems', 'resetFilters', '2')

  }, [applyClearFilters]);

  useEffect(() => {
    console.log('im here', 'major debug', isFindPTClicked)
  if(isFindPTClicked)
  {
    resetFilters();
    console.log('setFilterItems', 'resetFilters', '1')


    console.log('im innside here', 'major debug', isFindPTClicked)

   const token = sessionStorage.getItem('user');
   setApiLoader(true);

    let mounted = true;
    axios
      .get(
        `${process.env.REACT_APP_BASE_API_URL}/pgm/findprograms`,
        { headers: { authorization: `Bearer ${token}` } }
        // , { headers: { authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        let sampleProgramTypesObj = {
          'All Program Type Names': 'All Program Type Names'
        };
        let sampleOrganizations = {
          'All Organizations': 'All Organizations'
        };
        response.data.forEach((el) => {
          sampleProgramTypesObj[el.programtypename] = el.programtypename;
          el.organization
            ? el.organization.split(',').forEach((singleOrg) => {
                sampleOrganizations[singleOrg] = singleOrg;
              })
            : null;
        });
        setProgramTypeNames(sampleProgramTypesObj);
        setOrganizations(sampleOrganizations);
        let sortedProgramTypesData = response.data.sort((a, b) => {
          const nameA = a.programtypename.toUpperCase(); // ignore upper and lowercase
          const nameB = b.programtypename.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        setProgramTypesData(sortedProgramTypesData);
        setApiLoader(false);

        const rows_data = sortedProgramTypesData.map((item) => ({
          name: item.programtypename,
          healthCategory: item.healthcategory,
          organization: item.organization,
          id: item.id,
          healthCategoryType: healthCategoryMap[item.healthcategory]
        }));
        setRows(rows_data);
        // setFilteredRows(rows_data);

        setPageLoader(false);

        console.log('I am here', 'test')
        // setApplyClearFilters(true);

      });


    return () => (mounted = false)}
    setIsFindPTClicked(false)

    setPageLoader(false);
  }, [isFindPTClicked]);

  useEffect(() => {
    const token = sessionStorage.getItem('user');

    let mounted = true;
    setApiLoader(true)
    axios
      .get(
        `${process.env.REACT_APP_BASE_API_URL}/pgm/findprograms`,
        { headers: { authorization: `Bearer ${token}` } }
        // , { headers: { authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        let sampleProgramTypesObj = {
          'All Program Type Names': 'All Program Type Names'
        };
        let sampleOrganizations = {
          'All Organizations': 'All Organizations'
        };
        response.data.forEach((el) => {
          sampleProgramTypesObj[el.programtypename] = el.programtypename;
          el.organization
            ? el.organization.split(',').forEach((singleOrg) => {
                sampleOrganizations[singleOrg] = singleOrg;
              })
            : null;
        });
        setProgramTypeNames(sampleProgramTypesObj);
        setOrganizations(sampleOrganizations);
        let sortedProgramTypesData = response.data.sort((a, b) => {
          const nameA = a.programtypename.toUpperCase(); // ignore upper and lowercase
          const nameB = b.programtypename.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        setProgramTypesData(sortedProgramTypesData);
        setApiLoader(false)

        const rows_data = sortedProgramTypesData.map((item) => ({
          name: item.programtypename,
          healthCategory: item.healthcategory,
          organization: item.organization,
          id: item.id,
          healthCategoryType: healthCategoryMap[item.healthcategory]
        }));
        setRows(rows_data);
        setFilteredRows(rows_data);
        console.log(rows_data, '4', 'setFilterItems' )


        // setPageLoader(false);
      });

    return () => (mounted = false);
  }, []);

  const rows_data = programTypesData.map((item) => ({
    name: item.programtypename,
    healthCategory: item.healthcategory,
    organization: item.organization,
    id: item.id,
    healthCategoryType: healthCategoryMap[item.healthcategory]
  }));

  const navigate = useNavigate();
  const columns = [
    {
      field: 'name',
      headerName: 'Program Type Name',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => {
        return (
          <span style={{ fontSize: '14px' }}>
            Name{' '}
            <IconButton>
              {' '}
              <UnfoldMoreIcon onMouseOver={handleSortHover} />{' '}
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{ marginTop: '8px' }}
                MenuListProps={{
                  onMouseLeave: handleClose
                }}>
                <MenuItem
                  sx={
                    listOrder === 'ASC'
                      ? {
                          '&:hover': {
                            backgroundColor: '#1976D2'
                          },
                          '&': {
                            backgroundColor: '#1976D2'
                          }
                        }
                      : {
                          '&:hover': {
                            backgroundColor: '#DAD9D9'
                          }
                        }
                  }
                  onClick={() => {
                    let sortedProgramTypesData = programTypesData.sort((a, b) => {
                      const nameA = a.programtypename.toUpperCase(); // ignore upper and lowercase
                      const nameB = b.programtypename.toUpperCase(); // ignore upper and lowercase
                      if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }

                      // names must be equal
                      return 0;
                    });
                    const rows_data = sortedProgramTypesData.map((item) => ({
                      name: item.programtypename,
                      healthCategory: item.healthcategory,
                      organization: item.organization,
                      id: item.id,
                      healthCategoryType: healthCategoryMap[item.healthcategory]
                    }));
                    setFilteredRows(rows_data);
                    setListOrder('ASC');
                  }}>
                  A <ArrowForwardIcon sx={{ transform: 'scale(0.8,0.8)' }} /> Z
                </MenuItem>
                <MenuItem
                  sx={
                    listOrder === 'DESC'
                      ? {
                          '&:hover': {
                            backgroundColor: '#1976D2'
                          },
                          '&': {
                            backgroundColor: '#1976D2'
                          }
                        }
                      : {
                          '&:hover': {
                            backgroundColor: '#DAD9D9'
                          }
                        }
                  }
                  onClick={() => {
                    let sortedProgramTypesData = programTypesData.sort((a, b) => {
                      const nameA = a.programtypename.toUpperCase(); // ignore upper and lowercase
                      const nameB = b.programtypename.toUpperCase(); // ignore upper and lowercase
                      if (nameA > nameB) {
                        return -1;
                      }
                      if (nameA < nameB) {
                        return 1;
                      }

                      // names must be equal
                      return 0;
                    });
                    const rows_data = sortedProgramTypesData.map((item) => ({
                      name: item.programtypename,
                      healthCategory: item.healthcategory,
                      organization: item.organization,
                      id: item.id,
                      healthCategoryType: healthCategoryMap[item.healthcategory]
                    }));
                    setFilteredRows(rows_data);
                    setListOrder('DESC');
                  }}>
                  {' '}
                  Z <ArrowForwardIcon sx={{ transform: 'scale(0.8,0.8)' }} /> A
                </MenuItem>
              </Menu>
            </IconButton>
          </span>
        );
      }
    },
    {
      field: 'organization',
      headerName: 'Organization',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'healthCategory',
      headerName: 'Health Category',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'healthCategoryType',
      headerName: 'Health Category Type',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      disableColumnMenu: true
    },

    {
      // field: 'Actions',
      field: 'Actions',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (rowData) => (
        <strong>
          {/* {console.log(rowData)} */}
          <Button
            variant="text"
            onClick={() => {
              navigate(`/program-types/${rowData.id}/view`);
            }}>
            View
          </Button>
          <Button
            variant="text"
            onClick={() => {
              navigate(`/program-types/${rowData.id}/edit`);
            }}>
            Edit
          </Button>
        </strong>
      )
    }
  ];

  useEffect(() => {
    console.log('apply filetrs');
  }, [searchFilter]);

  useEffect(() => {
    if(programTypesData.length >0){
    setLoader(true)
    }else{
    setLoader(false)

    }
    setTimeout(() => {
      setFiltersFromLocalStorage()
    }, 1000);
  }, [programTypesData])

  const setFiltersFromLocalStorage = () => {

   if(programTypesData.length > 0){ let filtersObj = localStorage.getItem('programsAdvSearch') ? JSON.parse(localStorage.getItem('programsAdvSearch')) : {}
    console.log(localStorage, programTypesData,'setFilterItems' , 'setFiltersFromLocalStorage', 99 )

    setSearchFilters(filtersObj);
    filtersObj.selectedProgramTypeName ? setSelectedProgramTypeName(filtersObj.selectedProgramTypeName) : null
    filtersObj.selectedHealthCategory ? setSelectedHealthCategory(filtersObj.selectedHealthCategory) : null
    filtersObj.selectedOrganization ? setSelectedOrganization(filtersObj.selectedOrganization) : null


    let filteredItems = [];

    const rows_data = programTypesData.map((item) => ({
      name: item.programtypename,
      healthCategory: item.healthcategory,
      organization: item.organization,
      id: item.id,
      healthCategoryType: healthCategoryMap[item.healthcategory]
    }));

    rows_data.forEach((el) => {
      let status = true;
      filtersObj?.selectedProgramTypeName?.length
        ? filtersObj.selectedProgramTypeName.includes('All Program Type Names')
          ? null
          : filtersObj.selectedProgramTypeName.includes(el.name)
          ? null
          : (status = false)
        : null;
        filtersObj?.selectedHealthCategory?.length
        ? filtersObj.selectedHealthCategory.includes('All Health Categories')
          ? null
          : filtersObj.selectedHealthCategory.includes(el.healthCategory)
          ? null
          : (status = false)
        : null;
      if (filtersObj?.selectedOrganization?.length) {
        if (!filtersObj.selectedOrganization.includes('All Organizations')) {
          let found = filtersObj.selectedOrganization.some((r) => el.organization.includes(r));
          if (!found) {
            status = false;
          }
        }
      }

      if (status) {
        filteredItems.push(el);
      }
    });

    setLoader(false)
    setFilteredRows(filteredItems);
    console.log(filteredItems, '1', 'setFilterItems' )
  }}

  const setAdvFilters = () => {

    localStorage.setItem('programsAdvSearch', JSON.stringify({
      selectedProgramTypeName,
      selectedOrganization,
      selectedHealthCategory
    }))

    setSearchFilters({
      selectedProgramTypeName,
      selectedOrganization,
      selectedHealthCategory
    });

    console.log(selectedProgramTypeName, selectedOrganization, selectedHealthCategory, ' .   ===> .  ');

    let filteredItems = [];
    rows.forEach((el) => {
      let status = true;
      selectedProgramTypeName.length
        ? selectedProgramTypeName.includes('All Program Type Names')
          ? null
          : selectedProgramTypeName.includes(el.name)
          ? null
          : (status = false)
        : null;
      selectedHealthCategory.length
        ? selectedHealthCategory.includes('All Health Categories')
          ? null
          : selectedHealthCategory.includes(el.healthCategory)
          ? null
          : (status = false)
        : null;
      if (selectedOrganization.length) {
        if (!selectedOrganization.includes('All Organizations')) {
          let found = selectedOrganization.some((r) => el.organization.includes(r));
          if (!found) {
            status = false;
          }
        }
      }

      if (status) {
        filteredItems.push(el);
      }
    });

    setFilteredRows(filteredItems);
    console.log(filteredItems, '2' ,'setFilterItems')

  };

  const resetFilters = () => {
    console.log('test 238')
    setSelectedProgramTypeName([]);
    setSelectedHealthCategory([]);
    setSelectedOrganization([]);
    setAdvFilters();
    localStorage.setItem('programsAdvSearch', '')
    setFilteredRows(rows);
    console.log(rows, '1','setFilterItems' )

  };

  return (
    <PageTemplate tabBar navbar
        //  applyClearFilters={() => setApplyClearFilters(true)}
    setIsFindPTClicked={setIsFindPTClicked}>

      <div>
        <div className="home-inner-container1">
          <div className="home-inner-container2">
            <div>
              <div style={{ flexWrap: 'nowrap' }} className="home-icon">
                <div style={{ width: '20%' }} className="home-icon-left-container">
                  <div>
                    <h3>Program Types</h3>
                  </div>
                </div>
                <div style={{ width: '80%' }} className="home-export-add-container">
                  <p
                    onClick={() => setOpenAdvModal(true)}
                    style={{ marginLeft: 'auto' }}
                    className="adv-search-btn">
                    Advanced Search
                  </p>
                  {/* <p onClick={() => resetFilters()} className="adv-search-btn">
                    Clear Filters
                  </p> */}
                  <p>
                  { localStorage.getItem('programsAdvSearch') &&   <UiButton onClick={() => resetFilters()} text={'Clear Filters'}></UiButton>}
                  </p>
                  {/* <CSVLink
                    data={selectedRowData}
                    // asyncOnClick={true}
                    // onClick={this.getUsers}
                    // eslint-disable-next-line prettier/prettier
                  >
                    <UiButton text="Export" onClick={() => {}}></UiButton>
                  </CSVLink> */}

                  <div>
                    <UiButton
                      onClick={() => navigate('/program-types/new')}
                      text={'Add Program Type'}></UiButton>
                  </div>
                </div>
              </div>
            </div>
            <hr className="hr-line"></hr>
            {/* <Data_table data={region_countryData} height={400} /> */}
            <div
              style={{
                // height: "400px",
                height: 800,
                width: '100%'
                // overflowY: "scroll",
              }}>
                {console.log(pageLoader, loader, filteredRows, 'loader debug')}
              <DataGrid
                rows={filteredRows}
                columns={columns}
                localeText={{
                  noRowsLabel: 'No records found'
                }}
                loading={pageLoader || loader || apiLoader}
                // pageSize={5}
                // rowsPerPageOptions={[10]}
                // checkboxSelection
                onSelectionModelChange={(newSelectionModel, e) => {
                  const selectedRowData = [];
                  newSelectionModel.forEach((item) => {
                    selectedRowData.push(programTypesData[item]);
                  });
                  setSelectedRowData(selectedRowData.filter((item) => item !== undefined));
                }}
                sx={{
                  '.MuiDataGrid-columnSeparator': {
                    display: 'none'
                  },
                  '&.MuiDataGrid-root': {
                    border: 'none'
                  },
                  '.MuiDataGrid-columnHeader': {
                    fontSize: 16,
                    fontWeight: 'bold'
                  },
                  '.MuiDataGrid-sortIcon': {
                    opacity: 'inherit !important',
                    color: 'black'
                  },
                  '& .MuiDataGrid-iconButtonContainer': {
                    visibility: 'visible !important'
                  },
                  '& .css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 700,
                    fontSize: '14px'
                  },
                  '& .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root': {
                    color: 'black'
                  },
                  '& .MuiDataGrid-iconButtonContainer': {
                    display: 'none'
                  },
                  '& .MuiDataGrid-iconButtonContainer1': {
                    paddingLeft: '5px',
                    color: 'black'
                  }
                }}
                hideFooter="true"
                // onSelectionModelChange={(newSelection) => {
                //   console.log(newSelection, 'hsdmhdkjwa');
                //   setselection(newSelection);
                // }}
              />
            </div>
          </div>
        </div>
      </div>

      <Dialog fullWidth={true} maxWidth={'lg'} open={openAdvModal}>
        <DialogTitle
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '5px',
            marginLeft: '40px',
            marginRight: '40px'
          }}>
          <p>Advanced Search</p>
          <CloseIcon
            onClick={() => setOpenAdvModal(false)}
            style={{ marginLeft: 'auto', cursor: 'pointer' }}></CloseIcon>
        </DialogTitle>
        <div className="model-item-seperator"></div>
        <DialogContent style={{ margin: '40px', marginTop: '10px', marginBottom: '0px' }}>
          <div className="adv-form-input-cont">
            <p className="adv-form-label">Program Type Name</p>
            <FormControl className="adv-form-input" fullWidth>
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
            <Select
              multiple
              MenuProps={{ sx: { height: '500px' } }}
              sx={{ width: '100%' }}
              label="Select"
              onChange={(event, e2) => {
                if (e2.props.value != 'All Program Type Names') {
                  setSelectedProgramTypeName(
                    event.target.value.filter((item) => item != 'All Program Type Names')
                  );
                } else {
                  setSelectedProgramTypeName(['All Program Type Names']);
                }
              }}
              value={selectedProgramTypeName}
              className="adv-form-input"
              renderValue={(selected) => (
                <div>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      clickable
                      deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                      // className={classes.chip}
                      onDelete={(e, v1) => {
                        const index = selected.findIndex((item) => item === value);
                        selected.splice(index, 1);
                        setSelectedProgramTypeName([...selected]);
                      }}
                    />
                  ))}
                </div>
              )}>
              {Object.keys(programtypeNames).map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            </FormControl>
          </div>
          <div className="adv-form-input-cont">
            <p className="adv-form-label">Organization</p>
            <FormControl className="adv-form-input" fullWidth>
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
            <Select
              multiple
              MenuProps={{ sx: { height: '500px' } }}
              sx={{ width: '100%' }}
              label="Select"
              onChange={(event, e2) => {
                if (e2.props.value != 'All Organizations') {
                  setSelectedOrganization(
                    event.target.value.filter((item) => item != 'All Organizations')
                  );
                } else {
                  setSelectedOrganization(['All Organizations']);
                }
              }}
              value={selectedOrganization}
              className="adv-form-input"
              renderValue={(selected) => (
                <div>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      clickable
                      deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                      // className={classes.chip}
                      onDelete={(e, v1) => {
                        const index = selected.findIndex((item) => item === value);
                        selected.splice(index, 1);
                        setSelectedOrganization([...selected]);
                      }}
                    />
                  ))}
                </div>
              )}>
              {/* {Object.keys(organizations).map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))} */}
              <MenuItem key={'Org 1'} value={'Org 1'}>
                {'Org 1'}
              </MenuItem>
              <MenuItem key={'Org 2'} value={'Org 2'}>
                {'Org 2'}
              </MenuItem>
              <MenuItem key={'Org 3'} value={'Org 3'}>
                {'Org 3'}
              </MenuItem>
            </Select>
            </FormControl>
          </div>

          <div className="adv-form-input-cont">
            <p className="adv-form-label">Health Category</p>
            <FormControl className="adv-form-input" fullWidth>
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
            <Select
              multiple
              MenuProps={{ sx: { height: '500px' } }}
              sx={{ width: '100%' }}
              label="Select"
              onChange={(event, e2) => {
                if (e2.props.value != 'All Health Categories') {
                  setSelectedHealthCategory(
                    event.target.value.filter((item) => item != 'All Health Categories')
                  );
                } else {
                  setSelectedHealthCategory(['All Health Categories']);
                }
              }}
              value={selectedHealthCategory}
              className="adv-form-input"
              renderValue={(selected) => (
                <div>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      clickable
                      deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                      // className={classes.chip}
                      onDelete={(e, v1) => {
                        const index = selected.findIndex((item) => item === value);
                        selected.splice(index, 1);
                        setSelectedHealthCategory([...selected]);
                      }}
                    />
                  ))}
                </div>
              )}>
              {Object.keys(healthCategories).map((option) => (
                <MenuItem key={option} value={option}>
                  {option !== 'All Health Categories' ? `${option} - ${healthCategoryMap[option]}` : option }

                </MenuItem>
              ))}
            </Select>
            </FormControl>
          </div>
        </DialogContent>
        <div className="model-item-seperator"></div>
        <DialogActions style={{ margin: '20px' }}>
          <UiButton
            onClick={() => {
              setAdvFilters();
              setOpenAdvModal(false);
            }}
            style={{ marginLeft: 'auto' }}
            text={'Search'}></UiButton>
          <p
            onClick={() => setOpenAdvModal(false)}
            style={{ marginRight: 'auto', marginLeft: '20px', cursor: 'pointer' }}>
            Cancel
          </p>
        </DialogActions>
      </Dialog>
    </PageTemplate>
  );
}
export default TeachersListing;

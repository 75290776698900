/* eslint-disable react/jsx-key */
import React from 'react';
import './Cards.css';

class Cards extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      Region: [
        {
          title: 'Europe',
          count: 1001
        },
        {
          title: 'Europe',
          count: 1001
        },
        {
          title: 'Europe',
          count: 1001
        },
        {
          title: 'Europe',
          count: 1001
        },
        {
          title: 'Europe',
          count: 1001
        },
        {
          title: 'Europe',
          count: 1001
        },
        {
          title: 'Europe',
          count: 1001
        },
        {
          title: 'Europe',
          count: 1001
        }
      ],
      Summary: [
        {
          title: 'Europe',
          count: 1001
        },
        {
          title: 'Europe',
          count: 1001
        }
      ],
      SummarybyStatus: [
        {
          title: 'Europe',
          count: 1001
        },
        {
          title: 'Europe',
          count: 1001
        },
        {
          title: 'Europe',
          count: 1001
        }
      ]
    };
  }
  render(props) {
    // console.log("props in card", this.props);
    return (
      <div className="cards-outer-contanier">
        <div className="cards-inner-container1">
          <div className="cards-inner-container2">
            <div className="Cards-Container">
              {/* 3 cards in a row */}
              <div className="card-1">
                <div className="card-2-inner-container">
                  <h5 className="cards-heading">Teachers Count By Region</h5>
                  <div className="bb"></div>
                  <div className="card-scroll">
                    {/* {console.log(props, Object.keys(this.props.regionCountMap))} */}
                    {this.props &&
                      this.props.regionCountMap &&
                      Object.keys(this.props.regionCountMap)
                        .sort((key1, key2) => {
                          const nameA = key1.toUpperCase(); // ignore upper and lowercase
                          const nameB = key2.toUpperCase(); // ignore upper and lowercase

                          if (key1 === 'All Regions Count') {
                            return 0;
                          }
                          if (key2 === 'All Regions Count') {
                            return 0;
                          }
                          if (nameA < nameB) {
                            return -1;
                          }
                          if (nameA > nameB) {
                            return 1;
                          }

                          // names must be equal
                          return 0;
                        })
                        .map((l, i) => {
                          return (
                            <div>
                              <div className="card-content">
                                <div className="cards-title">{l}</div>
                                <div className="cards-count">{this.props.regionCountMap[l]}</div>
                              </div>
                              <div className="cards-border"></div>
                            </div>
                          );
                        })}
                  </div>
                </div>
              </div>
              <div className="card-2">
                <div className="card-2-inner-container">
                  <h5 className="cards-heading">Teachers Summary</h5>
                  {/* <div className='cards-border'></div> */}
                  <div className="bb"></div>

                  {/* {this.props.data.Summary.map((l, i) => {
                    return ( */}

                  <>
                    <div>
                      <div className="card-content">
                        <div className="cards-title">Full Time</div>
                        <div className="cards-count">
                          {this.props.data.Summary.find((i) => +i.teachertype === 1) &&
                          this.props.data.Summary.find((i) => +i.teachertype === 1).count
                            ? this.props.data.Summary.find((i) => +i.teachertype === 1).count
                            : 0}
                        </div>
                      </div>
                      <div className="cards-border"></div>
                    </div>
                    <div>
                      <div className="card-content">
                        <div className="cards-title">Part Time</div>
                        <div className="cards-count">
                          {this.props.data.Summary.find((i) => +i.teachertype === 0) &&
                          this.props.data.Summary.find((i) => +i.teachertype === 0).count
                            ? this.props.data.Summary.find((i) => +i.teachertype === 0).count
                            : 0}
                        </div>
                      </div>
                      <div className="cards-border"></div>
                    </div>
                  </>

                  {/* );
                    })} */}
                </div>
              </div>

              <div className="card-3">
                <div className="card-2-inner-container">
                  <h5 className="cards-heading">Teachers Summary by Status</h5>
                  {/* <div className='cards-border'>dfgd</div> */}
                  <div className="bb"></div>

                  <>
                    <div>
                      <div className="card-content">
                        <div className="cards-title">Active</div>
                        <div className="cards-count">
                          {this.props.data.teacher_summary_status.find(
                            (i) => i.teacheractivitystatus === 'Active'
                          ) &&
                          this.props.data.teacher_summary_status.find(
                            (i) => i.teacheractivitystatus === 'Active'
                          ).count
                            ? this.props.data.teacher_summary_status.find(
                                (i) => i.teacheractivitystatus === 'Active'
                              ).count
                            : 0}
                        </div>
                      </div>
                      <div className="cards-border"></div>
                    </div>
                    <div>
                      <div className="card-content">
                        <div className="cards-title">Inactive</div>
                        <div className="cards-count">
                          {' '}
                          {this.props.data.teacher_summary_status.find(
                            (i) => i.teacheractivitystatus === 'Inactive'
                          ) &&
                          this.props.data.teacher_summary_status.find(
                            (i) => i.teacheractivitystatus === 'Inactive'
                          ).count
                            ? this.props.data.teacher_summary_status.find(
                                (i) => i.teacheractivitystatus === 'Inactive'
                              ).count
                            : 0}
                        </div>
                      </div>
                      <div className="cards-border"></div>
                    </div>
                    <div>
                      <div className="card-content">
                        <div className="cards-title">View Only</div>
                        <div className="cards-count">
                          {' '}
                          {this.props.data.teacher_summary_status.find(
                            (i) => i.teacheractivitystatus === 'View only'
                          ) &&
                          this.props.data.teacher_summary_status.find(
                            (i) => i.teacheractivitystatus === 'View only'
                          ).count
                            ? this.props.data.teacher_summary_status.find(
                                (i) => i.teacheractivitystatus === 'View only'
                              ).count
                            : 0}
                        </div>
                      </div>
                      <div className="cards-border"></div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Cards;

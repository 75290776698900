import React, { useState } from 'react';
import styles from '../Global.module.css';
import AOLlogo from '../AOLlogo.png';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { InputAdornment, IconButton } from '@mui/material';
import Form from '../../../UiCore/FormComponent/FormFeild/FormFeild';
import InputFormFeild from '../../../UiCore/FormComponent/InputFormFeild/InputFormFeild2';
import UiButton from '../../../UiCore/FormComponent/UiButton/UiButton';
import * as axios from 'axios';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import { useForm } from 'react-hook-form';

var msg, link_url;
const regex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]).{8,}$/;

// const newPasswordSchema = yup.object().shape({
// oldpassword: yup.string().required('Old Password is required'),
// newpassword: yup
//   .string()
//   .required('New Password is required')
//   .matches(
//     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
//     'Password must be at least 8 characters in length containing One Uppercase Letter, One Lowercase Letter, Special Character and One Number'
//   ),
// confirmpassword: yup
//   .string()
//   .oneOf([yup.ref('newpassword'), null], 'Password and Confirm password must be same')
//   .required('Confirm Password is required')
// .when(['newpassword'], (newpassword, schema) => {
//   console.log(schema);
//   return schema.oneOf([newpassword], 'Password and Confirm password must be same');
// })
// .when(['newpassword', 'confirmpassword'], {
//   is: (newpassword, confirmpassword) => newpassword !== confirmpassword,
//   then: yup.string().required('Passwords must match')
// })
// .test('passwords-match', 'Passwords must match', function (value) {
//   console.log(value, yup.ref('newpassword'), 'major debug' , this.parent);
//   return this.parent.newpassword === value;
// })
// });

export default function NewPassword() {
  const [showOldPassword, setShowOldPassword] = useState(true);
  const [showPassword, setShowPassword] = useState(true);
  const [oldPassword, setOldPassword] = useState('');

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [oldPwdInputed, setOldPwdInputed] = useState(false);
  const [newPwdInputed, setNewPwdInputed] = useState(false);
  const [confirmPwdInputed, setConfirmPwdInputed] = useState(false);

  //   const onSubmit = (data) => {
  //     console.log("data is", data);
  //   };
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let onSubmit = async (data) => {
    const token = await sessionStorage.getItem('user');
    let error = false;
    if (oldPassword === '') {
      error = true;
    }
    if (!newPassword.match(regex)) {
      error = true;
    }
    if (newPassword !== confirmPassword) {
      error = true;
    }
    if (!error) {
      await axios
        .put(
          `${process.env.REACT_APP_BASE_API_URL}/home/changepassword`,
          {
            params: { oldpassword: oldPassword, newpassword: newPassword }
          },
          { headers: { authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          if (response.data.message === 'Password Updated Successfully') {
            msg = 'Changes have been saved successfully';
            link_url = '/home';
            setOpen(true);
          } else {
            // setOpen(true)
            msg = 'You have entered a wrong old password';
            link_url = '/changepassword';
            setOpen(true);
          }
        });
    }
  };

  const { watch } = useForm({ mode: 'onChange' });
  // old password validation
  let oldPasswordErrorMessage = null;

  if (oldPassword === '' && oldPwdInputed) {
    oldPasswordErrorMessage = 'Old Password is required';
  }

  // new passWord validation
  let newPasswordErrorMessage = null;

  if (!newPassword.match(regex) && newPwdInputed) {
    console.log('im here');
    newPasswordErrorMessage =
      'Password must be at least 8 characters in length containing One Uppercase Letter, One Lowercase Letter, Special Character and One Number';
  }
  //confirm password validation
  let confirmPasswordErrorMessage = null;
  if (newPasswordErrorMessage !== '' && confirmPwdInputed) {
    if (newPassword !== confirmPassword) {
      confirmPasswordErrorMessage = 'Password and Confirm password must be same';
    }
  }
  console.log(newPasswordErrorMessage, newPassword);
  return (
    <div className={styles.outerContainer}>
      <div className={styles.container}>
        <img src={AOLlogo} className={styles.img} />
        <div className={styles.heading}>New Password</div>
        {/* {console.log(watch('newpassword'), 'debug pass', newPasswordSchema)} */}
        <Form
          onSubmit={onSubmit}
          //  schema={newPasswordSchema}
        >
          <label className={styles.label}>Enter Old Password</label>
          <InputFormFeild
            // label="Enter new password"
            placeholder="Enter old password"
            name="oldpassword"
            type={showOldPassword ? 'password' : 'text'}
            className={styles.input}
            variant={'outlined'}
            value={oldPassword}
            onChange={(e) => {
              if (e.target.value !== '') {
                setOldPwdInputed(true);
              }
              setOldPassword(e.target.value);
            }}
            errorMessage={oldPasswordErrorMessage}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowOldPassword(!showOldPassword)}>
                    {showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <label className={styles.label}>Enter New Password</label>
          <InputFormFeild
            // label="Enter new password"
            placeholder="Enter new password"
            name="newpassword"
            type={showPassword ? 'password' : 'text'}
            className={styles.input}
            variant={'outlined'}
            value={newPassword}
            onChange={(e) => {
              if (e.target.value !== '') {
                setNewPwdInputed(true);
              }
              setNewPassword(e.target.value);
            }}
            errorMessage={newPasswordErrorMessage}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <label className={styles.label}>Confirm Password</label>
          <InputFormFeild
            // label="Confirm Password"
            placeholder="Confirm Password"
            name="confirmpassword"
            type={showConfirmPassword ? 'password' : 'text'}
            className={styles.input}
            variant={'outlined'}
            value={confirmPassword}
            errorMessage={confirmPasswordErrorMessage}
            onChange={(e) => {
              if (e.target.value !== '') {
                setConfirmPwdInputed(true);
              }
              setConfirmPassword(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <br />

          {/* <UiButton
            text="Save"
            type="submit"
          ></UiButton> */}
          <Button
            type="submit"
            variant="contained"
            style={{
              marginTop: '30px',
              marginBottom: '10px',
              height: '50px',
              fontWeight: '700',
              textTransform: 'uppercase'
            }}
            className={styles.button}
            onClick={() => {
              setOldPwdInputed(true);

              setNewPwdInputed(true);
              setConfirmPwdInputed(true);
            }}>
            Save
          </Button>
          <Link to="/home">
            <div style={{ textDecoration: 'none' }} className={styles.link}>
              Cancel
            </div>
          </Link>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            maxWidth={'xs'}
            fullWidth={true}
            aria-describedby="alert-dialog-description">
            <DialogContent
            //  style={{ margin: '25px', marginBottom: '0px' }}
             >
              <DialogContentText style={{ color: 'black' }}>
                <p dangerouslySetInnerHTML={{ __html: msg }}></p>
              </DialogContentText>
            </DialogContent>
            <DialogActions 
            // style={{ margin: '25px', marginTop: '0px' }}
            >
              <Link to={link_url} style={{ textDecoration: 'none' }}>
                <Button variant="contained" onClick={handleClose} autoFocus>
                  Ok
                </Button>
              </Link>
            </DialogActions>
          </Dialog>
        </Form>
      </div>
    </div>
  );
}

/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/react-in-jsx-scope */
import PageTemplate from 'Components/UiCore/pageTemplate/PageTemplate';
import React, { useRef, useState, useEffect } from 'react';
import UiButton from 'Components/UiCore/FormComponent/UiButton/UiButton';
import './styles.css';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Button, { ButtonProps } from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import { Chip, Grid, InputBase } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import * as XLSX from 'xlsx/xlsx.mjs';
import * as axios from 'axios';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Navbar from 'Components/UiCore/Navbar/Navbar.js';
// import UserData from "../../Data_table/data.json";
import Data_table from './dataTable.js';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';

function format(inputDate) {
  let date, month, year;

  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;
  year = inputDate.getFullYear();

  date = date.toString().padStart(2, '0');

  month = month.toString().padStart(2, '0');

  return `${date}-${month}-${year}`;
}

const ExportPage = () => {
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const [regionData, setRegionData] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [teachersData, setTeachersData] = React.useState([]);

  const [isParentSelectedRows, setIsParentSelectedRows] = useState(true);
  // useEffect(() => {

  // }, []);

  console.log(location, 'location value');

  let navigate = useNavigate();
  const handleChangeSelect = (event) => {
    setSelectedRegion(event.target.value);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getSelectedRows = (totalData, selectedId) => {
    var d = totalData.filter((data) => selectedId.includes(data.Country));
    setSelectedRows(d);
  };

  useEffect(() => {
    setSelectedRows([...location.state.selectedTeacherRows]);
    setIsParentSelectedRows(false);
    const selectedRowData = [];
    location.state.selectedTeacherRows.forEach((id) => {
      selectedRowData.push(teachersData.find((item) => id === item.basicinfo.id));
    });
    // !isParentSelectedRows &&
    console.log(
      selectedRowData,
      location.state.selectedTeacherRows,
      teachersData,
      'major debug export selected rows'
    );
    setSelectedRowData(selectedRowData.filter((item) => item !== undefined));
    console.log('i happen', location, location.state.selectedTeacherRows);
  }, [location.state, teachersData]);

  useEffect(() => {
    const token = sessionStorage.getItem('user');
    let mounted = true;
    axios
      .get(
        `${process.env.REACT_APP_BASE_API_URL}/teachers/findall`,

        { headers: { authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setTeachersData(
          response.data.sort((a, b) => {
            const nameA = a.basicinfo.firstname.toUpperCase(); // ignore upper and lowercase
            const nameB = b.basicinfo.firstname.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          })
        );
        // setTeachersData(response.data);
        // setUnfilteredTeachertsData(response.data);
        // setPageLoader(false);
      });
    return () => (mounted = false);
  }, []);
  //   const tableRef = useRef(null);
  //  const { onDownload } = useDownloadExcel({
  //     currentTableRef: tableRef.current,
  //     filename: "AOL Teachers Information",
  //     sheet: "Teachers Data",
  //   });

  const handleExportAllRows = () => {
    const finalArray = [];
    finalArray.push({});
    var wb = XLSX.utils.book_new();

    var ws = XLSX.utils.json_to_sheet(
      teachersData.map((row) => {
        let item = row.basicinfo;
        let languages = item.languages.split(',').map((lang, i) => {
          console.log(item.languages.split(','), 'i break too');
          if (i !== item.languages.split(',').length - 1) return `${lang}, `;
          return lang;
        });
        languages = languages.join('');
        console.log(item, 'i break here', typeof item.languages, item.languages.split(','));
        return {
          'First Name': item.firstname,
          'Last Name': item.lastname,
          'Alias Name 1': item.aliasname1,
          'Alias Name 2': item.aliasname2,
          Gender: item.gender,
          DOB: item.dob && format(new Date(item.dob.substring(0, 10))),
          Email: item.email,
          Mobile: `+${item.mobile1}`,
          'Alternate Phone': item.mobile2 ? `+${item.mobile2}` : '',
          'Address 1': item.address1,
          'Address 2': item.address2,
          'Country (Country of residence)': getCountryName(item.country),
          State: getStateNameById(item.state),
          City: item.city,
          'PIN / ZIP Code': item.pincode,
          'Teacher Code': item.teachercode,
          'Harmony Code': item.harmonycode,
          'Teacher Activity Status': item.teacheractivitystatus,
          'Teacher Activity Sub Status':
            item.teacheractivitystatus === 'Inactive' ? item.teacheractivitysubstatus : 'N/A',

          'Sadhvi Ji / Swami Ji?': +item.isswamiorsadhvi === 1 ? 'Yes' : 'No',
          'Full Time Office Bearer?': +item.fulltimeofficebearer === 1 ? 'Yes' : 'No',
          Languages: languages,
          'Agreement Signed?': +item.isteacheragreementsigned === 1 ? 'Yes' : 'No',
          'Teacher Agreement Signed On':
            item.teacheragreementsignedon &&
            format(new Date(item.teacheragreementsignedon.substring(0, 10))),
          'Teacher Agreement Expires On':
            item.teacheragreementexpiredon &&
            format(new Date(item.teacheragreementexpiredon.substring(0, 10)))
          // 'Teaching Countries' : row.Countries.map( country => {

          // }),
        };
      })
    );

    const countriesArray = [];

    teachersData.map((item) => {
      const email = item.basicinfo.email;
      const countries = item.Countries;
      const firstname = item.basicinfo.firstname;
      const lastname = item.basicinfo.lastname;
      countries.map((country) => {
        let ctypes = country.coursetypes.split(',').map((item ,i) => {
          return getCourseNameById(parseInt(item));
        }).join(',');
        console.log('ctypes');
        console.log(ctypes);
        countriesArray.push({
          'First Name': firstname,
          'Last Name': lastname,
          Email: email,
          'Country Name': getCountryName(country.countryname),
          'Teaching Start Date':
            country.teachingstartdate &&
            format(new Date(country.teachingstartdate.substring(0, 10))),
          'Teaching Expiry Date':
            country.teachingexpirydate &&
            format(new Date(country.teachingexpirydate.substring(0, 10))),
          'Country Level Status': country.countrylevelstatus,
          'Teacher Type': +country.teachertype === 1 ? 'Full Time' : 'Part Time',
          'Course Types': ctypes,
          'Primary Country': +country.primarycountry === 1 ? 'Yes' : 'No'
        });
      });
    });

    const courseTypeArray = [];

    teachersData.map((item) => {
      const email = item.basicinfo.email;
      const firstname = item.basicinfo.firstname;
      const lastname = item.basicinfo.lastname;

      const courses = item.Courseinfo;
      courses.map((course) => {
        courseTypeArray.push({
          'First Name': firstname,
          'Last Name': lastname,
          Email: email,
          'Course Type': course.coursetype,
          'TTP Type': course.ttptype,
          'TTP Certification Type': course.ttpcertificationtype,
          'TTP Application Country': getCountryName(course.ttpapplicationcountry),
          'TTP Location': course.ttplocation,
          'TTP Graduation Date':
            course.ttpgraduationdate && format(new Date(course.ttpgraduationdate.substring(0, 10))),
          Notes: course.notes
        });
      });
    });

    const ws2 = XLSX.utils.json_to_sheet(countriesArray);
    const ws3 = XLSX.utils.json_to_sheet(courseTypeArray);

    XLSX.utils.book_append_sheet(wb, ws, 'Personal Data');
    XLSX.utils.book_append_sheet(wb, ws2, 'Teacher Countries Data');
    XLSX.utils.book_append_sheet(wb, ws3, 'Course Type Data');

    XLSX.writeFile(wb, 'AOL Teachers List.xlsx');
  };
  const handleExportSelectedRows = () => {
    const finalArray = [];
    finalArray.push({});
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(
      selectedRowData.map((row) => {
        let item = row.basicinfo;
        let languages = item.languages.split(',').map((lang, i) => {
          console.log(item.languages.split(','), 'i break too');
          if (i !== item.languages.split(',').length - 1) return `${lang}, `;
          return lang;
        });
        languages = languages.join('');
        return {
          'First Name': item.firstname,
          'Last Name': item.lastname,
          'Alias Name 1': item.aliasname1,
          'Alias Name 2': item.aliasname2,
          Gender: item.gender,
          DOB: item.dob && format(new Date(item.dob.substring(0, 10))),
          Email: item.email,
          Mobile: `+${item.mobile1}`,
          'Alternate Phone': item.mobile2 ? `+${item.mobile2}` : '',
          'Address 1': item.address1,
          'Address 2': item.address2,
          'Country (Country of residence)': getCountryName(item.country),
          State: getStateNameById(item.state),
          City: item.city,
          'PIN / ZIP Code': item.pincode,
          'Teacher Code': item.teachercode,
          'Harmony Code': item.harmonycode,
          'Teacher Activity Status': item.teacheractivitystatus,
          'Teacher Activity Sub Status':
            item.teacheractivitystatus === 'Inactive' ? item.teacheractivitysubstatus : 'N/A',

          'Sadhvi Ji / Swami Ji?': +item.isswamiorsadhvi === 1 ? 'Yes' : 'No',
          'Full Time Office Bearer?': +item.fulltimeofficebearer === 1 ? 'Yes' : 'No',
          Languages: languages,
          'Agreement Signed?': +item.isteacheragreementsigned === 1 ? 'Yes' : 'No',
          'Teacher Agreement Signed On':
            item.teacheragreementsignedon &&
            format(new Date(item.teacheragreementsignedon.substring(0, 10))),
          'Teacher Agreement Expires On':
            item.teacheragreementexpiredon &&
            format(new Date(item.teacheragreementexpiredon.substring(0, 10)))
          // 'Teaching Countries' : row.Countries.map( country => {

          // }),
        };
      })
    );

    const countriesArray = [];

    selectedRowData.map((item) => {
      const email = item.basicinfo.email;
      const countries = item.Countries;
      const firstname = item.basicinfo.firstname;
      const lastname = item.basicinfo.lastname;
      countries.map((country) => {
        countriesArray.push({
          'First Name': firstname,
          'Last Name': lastname,
          Email: email,
          'Country Name': getCountryName(country.countryname),
          'Teaching Start Date':
            country.teachingstartdate &&
            format(new Date(country.teachingstartdate.substring(0, 10))),
          'Teaching Expiry Date':
            country.teachingexpirydate &&
            format(new Date(country.teachingexpirydate.substring(0, 10))),
          'Country Level Status': country.countrylevelstatus,
          'Teacher Type': +country.teachertype === 1 ? 'Full Time' : 'Part Time',
          'Course Types': country.coursetypes.split(',').map((item,i) => {
            if (i !==  country.coursetypes.split(',').length - 1) return `${getCourseNameById(item)}, `;
            return getCourseNameById(item);
          }),

          'Primary Country': +country.primarycountry === 1 ? 'Yes' : 'No'
        });
      });
    });

    const courseTypeArray = [];
    console.log(selectedRowData)
    selectedRowData.map((item) => {
      const email = item.basicinfo.email;
      const courses = item.Courseinfo;
      const firstname = item.basicinfo.firstname;
      const lastname = item.basicinfo.lastname;
      console.log(courses);
      courses.map((course) => {
        courseTypeArray.push({
          'First Name': firstname,
          'Last Name': lastname,
          Email: email,
          'Course Type': course.coursetype,
          'TTP Type': course.ttptype,ttpTypeData ,
          'TTP Certification Type': course.ttpcertificationtype,

          'TTP Application Country': getCountryName(course.ttpapplicationcountry),
          'TTP Location': course.ttplocation,
          'TTP Graduation Date':
            course.ttpgraduationdate && format(new Date(course.ttpgraduationdate.substring(0, 10))),
          Notes: course.notes
        });
      });
    });

    const ws2 = XLSX.utils.json_to_sheet(countriesArray);
    const ws3 = XLSX.utils.json_to_sheet(courseTypeArray);

    XLSX.utils.book_append_sheet(wb, ws, 'Personal Data');
    XLSX.utils.book_append_sheet(wb, ws2, 'Teacher Countries Data');
    XLSX.utils.book_append_sheet(wb, ws3, 'Course Type Data');

    XLSX.writeFile(wb, 'AOL Teachers List.xlsx');
  };

  function handleClose() {
    navigate(-1);
  }
  const handleSearch = (event) => {
    var value = event.target.value;
    var temp = regionData.filter((data) =>
      data.Country.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(temp);
  };

  const [openAdvModal, setOpenAdvModal] = React.useState(false);
  console.log(location, 'location state debug');

  const [name, setName] = React.useState('');
  const [alias, setAlias] = React.useState('');
  const [emailID, setEmailId] = React.useState('');
  const [teacherCode, setTeacherCode] = React.useState('');

  const [harmonyTeacherCode, setHarmonyTeacherCode] = React.useState('');

  const [countries, setCountries] = React.useState(['India', 'Brazil', 'USA', 'UK']);
  const [selectedCurrentCountry, setSelectedCurrentCountry] = React.useState([]);
  const [selectedTTPCountry, setSelectedTTPCountry] = React.useState([]);
  const [selectedTeachingCountry, setselectedTeachingCountry] = React.useState([]);
  const [courseTypes, setCourseTypes] = React.useState([]);
  const [selectedCourseType, setSelectedCourseType] = React.useState([]);

  const [selectedCertificationType, setSelectedCertificationType] = React.useState({
    Certified: false,
    'Certified Online': false,
    'Co-teach': false,
    'Moved out of country': false
  });
  const [teacherStatus, setTeacherStatus] = React.useState({
    Active: false,
    'Inactive - Deceased': false,
    'Inactive - Not with Org': false,
    'Inactive - Suspended': false,
    'Inactive - Returned Kriya Tape and Manual': false,
    'View Only': false
  });
  const [teacherType, setTeacherType] = React.useState({
    'Full Time': false,
    'Part Time': false
  });
  const [agreementSigned, setAgreementSigned] = React.useState({
    Yes: false,
    No: false
  });
  const [expiredDate, setExpiredDate] = React.useState('');
  const [signedOnDate, setSignedOnDate] = React.useState('');
  const [searchFilter, setSearchFilters] = React.useState({});

  const setAdvFilters = () => {
    setSearchFilters({
      name: name,
      alias: alias,
      emailID: emailID,
      teacherCode: teacherCode,
      harmonyTeacherCode: harmonyTeacherCode,
      currentCountry: selectedCurrentCountry,
      ttpCountry: selectedTTPCountry,
      teachingCountry: selectedTeachingCountry,
      courseTypes: selectedCourseType,
      certificationType: selectedCertificationType,
      teacherStatus: teacherStatus,
      teacherType: teacherType,
      agreementSigned: agreementSigned,
      agreementSignedOn: signedOnDate,
      agreementExpiresOn: expiredDate
    });
  };
  const [countryData, setcountryData] = React.useState([]);
  const [courseTypeData, setCourseTypeData] = React.useState([]);
  const [ttpCertificationTypeData, setTtpCertiicationTypeData] = React.useState([]);
  const [ttpTypeData, setTtpTypeData] = React.useState([]);
  const [ttpLocationData, setTtpLocationData] = React.useState([]);
  const [stateData, setStateData] = React.useState([]);
  const getCountryName = (id)=>{
    console.log(id,'country_name');
    if(countryData.length >0 && id !="" && id != null ){
      const countryname = countryData.find(
                                (item) => item.countryid == id
                              ).countryname;
      return countryname;
    }
  }
  const getCourseNameById = (id)=>{
   console.log(id,'course_type');
    if(courseTypeData.length > 0 && id != "" && id != null && !Number.isNaN(id)){
    const cname = courseTypeData.find(
                                   (item) => item.id == id
                                  ).programtypename;
    return cname;
    }
    return "";
  }
  const getTppNameById = (id,lookupdata)=>{
    const ttp = lookupdata.find(
                              (item) => item.id === id
                            ).lookupdesc;
    return ttp;
  }
  const getStateNameById = (id)=>{
    if(id == '13040' || id == "None"){
      return "None";
    }
    const s_name = stateData.find(
                              (item) => item.id === id
                            ).name;
    return s_name;
  }
  useEffect(() => {
    // countries
    const token = sessionStorage.getItem('user');

    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/teachers/countries`, {
        headers: { authorization: `Bearer ${token}` }
      })
      .then((response) => {
        setCountries([
          { countryname: 'All Countries', regionname: 'All Regions' },
          ...response.data
        ]);
        setcountryData(response.data);
        setselectedTeachingCountry(['All Countries']);
        setSelectedCurrentCountry(['All Countries']);
        setSelectedTTPCountry(['All Countries']);
      })
      .catch((error) => console.error(error));

    axios
      .get(
        `${process.env.REACT_APP_BASE_API_URL}/teachers/coursetypes`,

        { headers: { authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setCourseTypes([{ programtypename: 'All Courses' }, ...response.data]);
        setSelectedCourseType(['All Courses']);
        setCourseTypeData(response.data);
      })
      .catch((error) => console.error(error));
      axios
        .get(`${process.env.REACT_APP_BASE_API_URL}/teachers/getstatebycountryid`, {
          headers: { authorization: `Bearer ${token}` }
        })
        .then((response) => {
          setStateData(response.data);
        });

  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem('user');
    axios
      .get(
        `${process.env.REACT_APP_BASE_API_URL}/teachers/ttpcertificationtype`,

        { headers: { authorization: `Bearer ${token}` } }
      )
      .then((response) => { setTtpCertiicationTypeData(response.data) })
      .catch((error) => console.error(error));

    axios
      .get(
        `${process.env.REACT_APP_BASE_API_URL}/teachers/ttptype`,

        { headers: { authorization: `Bearer ${token}` } }
      )
      .then((response) => {setTtpTypeData(response.data)})
      .catch((error) => console.error(error));

    axios
      .get(
        `${process.env.REACT_APP_BASE_API_URL}/teachers/ttplocation`,

        { headers: { authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        let loC = [{ programtypename: 'All Courses' }, ...response.data];
        setCourseTypes(loC);
        setTtpLocationData(response.data)
        // setSelectedCourseType('All Courses');
      })
      .catch((error) => {
        console.error(error);
        navigate('/');
      });
  }, []);

  return (
    <PageTemplate navbar tabBar>
      <div style={{ marginTop: '40px' }} className="export-outer-container">
        {/* <Navbar></Navbar> */}
        <div className="export-inner-container1">
          {/* <p>{advFilters}</p> */}
          <div className="export-inner-container2">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                padding: '30px 10px 30px 10px'
              }}>
              <p style={{ marginRight: '10px' }}></p>
              <p
                // onClick={() => {
                //   setOpenAdvModal(true);
                // }}
                className="adv-search-btn"></p>
              {console.log(selectedRowData, 'major debug')}

              <UiButton
                text="Export Selected Rows"
                style={{ marginLeft: 'auto', marginRight: '20px' }}
                disabled={selectedRows.length !== 0 ? false : true}
                onClick={handleExportSelectedRows}></UiButton>
              <UiButton text="Export All Rows" onClick={handleExportAllRows}></UiButton>
              {selectedRows.length !== 0 && (
                <p style={{ fontSize: '14px', marginLeft: '30px', marginTop: '5px' }}>
                  {selectedRows.length} records selected
                </p>
              )}
              <IconButton style={{ background: '#eee', marginLeft: '10px', marginTop: '5px' }}>
                <CloseIcon fontSize="small" onClick={handleClose} />
              </IconButton>

              <hr className="hr-line"></hr>
              {/* advanced search dialog */}

              <Dialog fullWidth={true} maxWidth={'lg'} open={openAdvModal}>
                <DialogTitle
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '5px',
                    marginLeft: '40px',
                    marginRight: '40px'
                  }}>
                  <p>Advanced Search</p>
                  <CloseIcon
                    onClick={() => setOpenAdvModal(false)}
                    style={{ marginLeft: 'auto', cursor: 'pointer' }}></CloseIcon>
                </DialogTitle>
                <div className="model-item-seperator"></div>
                <DialogContent style={{ margin: '40px', marginTop: '10px', marginBottom: '10px' }}>
                  <div className="adv-form-items-cont">
                    <div className="adv-form-input-cont">
                      <p className="adv-form-label">Name</p>
                      <TextField
                        onChange={(event) => {
                          setName(event.target.value);
                        }}
                        value={name}
                        className="adv-form-input"
                        label="Name"
                        variant="outlined"
                      />
                    </div>
                    <div className="adv-form-input-cont">
                      <p className="adv-form-label">Alias Name</p>
                      <TextField
                        onChange={(event) => {
                          setAlias(event.target.value);
                        }}
                        className="adv-form-input"
                        label="Alias Name"
                        variant="outlined"
                        value={alias}
                      />
                    </div>
                    <div className="adv-form-input-cont">
                      <p className="adv-form-label">Email ID</p>
                      <TextField
                        onChange={(event) => {
                          setEmailId(event.target.value);
                        }}
                        className="adv-form-input"
                        label="Email ID"
                        variant="outlined"
                        value={emailID}
                      />
                    </div>
                    <div className="adv-form-input-cont">
                      <p className="adv-form-label">Teacher Code</p>
                      <TextField
                        onChange={(event) => {
                          setTeacherCode(event.target.value);
                        }}
                        className="adv-form-input"
                        label="Teacher Code"
                        variant="outlined"
                        value={teacherCode}
                      />
                    </div>
                    <div className="adv-form-input-cont">
                      <p className="adv-form-label">Harmony Teacher Code</p>
                      <TextField
                        onChange={(event) => {
                          setHarmonyTeacherCode(event.target.value);
                        }}
                        className="adv-form-input"
                        label="Harmony Teacher Code"
                        variant="outlined"
                        value={harmonyTeacherCode}
                      />
                    </div>
                    <div className="adv-form-input-cont">
                      <p className="adv-form-label">Current Country</p>
                      <Select
                        multiple
                        MenuProps={{ sx: { height: '500px' } }}
                        label="Current Country"
                        onChange={(event) => {
                          setSelectedCurrentCountry(event.target.value);
                        }}
                        renderValue={(selected) => (
                          <div
                          // className={classes.chips}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                clickable
                                deleteIcon={
                                  <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
                                }
                                // className={classes.chip}
                                onDelete={(e, v1) => {
                                  const index = selected.findIndex((item) => item === value);
                                  selected.splice(index, 1);
                                  setSelectedCurrentCountry([...selected]);
                                }}
                              />
                            ))}
                          </div>
                        )}
                        value={selectedCurrentCountry}
                        className="adv-form-input">
                        {countries.map((option) => (
                          <MenuItem key={option.countryname} value={option.countryname}>
                            {option.countryname}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="adv-form-input-cont">
                      <p className="adv-form-label">TTP Country</p>
                      <Select
                        multiple
                        label="TTP Country"
                        MenuProps={{ sx: { height: '500px' } }}
                        onChange={(event) => {
                          setSelectedTTPCountry(event.target.value);
                        }}
                        value={selectedTTPCountry}
                        className="adv-form-input"
                        renderValue={(selected) => (
                          <div
                          // className={classes.chips}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                clickable
                                deleteIcon={
                                  <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
                                }
                                // className={classes.chip}
                                onDelete={(e, v1) => {
                                  const index = selected.findIndex((item) => item === value);
                                  selected.splice(index, 1);
                                  setSelectedTTPCountry([...selected]);
                                }}
                              />
                            ))}
                          </div>
                        )}>
                        {countries.map((option) => (
                          <MenuItem key={option.countryname} value={option.countryname}>
                            {option.countryname}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="adv-form-input-cont">
                      <p className="adv-form-label">Teaching Country</p>
                      <Select
                        multiple
                        label="Teaching Country"
                        MenuProps={{ sx: { height: '500px' } }}
                        onChange={(event) => {
                          setselectedTeachingCountry(event.target.value);
                        }}
                        value={selectedTeachingCountry}
                        className="adv-form-input"
                        renderValue={(selected) => (
                          <div
                          // className={classes.chips}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                clickable
                                deleteIcon={
                                  <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
                                }
                                // className={classes.chip}
                                onDelete={(e, v1) => {
                                  const index = selected.findIndex((item) => item === value);
                                  selected.splice(index, 1);
                                  setselectedTeachingCountry([...selected]);
                                }}
                              />
                            ))}
                          </div>
                        )}>
                        {countries.map((option) => (
                          <MenuItem key={option.countryname} value={option.countryname}>
                            {option.countryname}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="adv-form-input-cont">
                      <p className="adv-form-label">Course Types</p>
                      <Select
                        multiple
                        label="Course Types"
                        onChange={(event) => {
                          setSelectedCourseType(event.target.value);
                        }}
                        value={selectedCourseType}
                        className="adv-form-input"
                        renderValue={(selected) => (
                          <div
                          // className={classes.chips}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                clickable
                                deleteIcon={
                                  <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
                                }
                                // className={classes.chip}
                                onDelete={(e, v1) => {
                                  const index = selected.findIndex((item) => item === value);
                                  selected.splice(index, 1);
                                  setSelectedCourseType([...selected]);
                                }}
                              />
                            ))}
                          </div>
                        )}>
                        {courseTypes.map((option) => (
                          <MenuItem key={option.programtypename} value={option.programtypename}>
                            {option.programtypename}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="adv-form-input-cont">
                      <p className="adv-form-label">Certification Type</p>
                      <div style={{ display: 'flex', flexDirection: 'row', flex: 5 }}>
                        <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={selectedCertificationType['Certified']}
                                onChange={(event) => {
                                  let dupCertifiedCoursesObj = selectedCertificationType;
                                  dupCertifiedCoursesObj['Certified'] = event.target.checked;
                                  setSelectedCertificationType(dupCertifiedCoursesObj);
                                }}
                              />
                            }
                            label="Certified"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={selectedCertificationType['Certified Online']}
                                onChange={(event) => {
                                  let dupCertifiedCoursesObj = selectedCertificationType;
                                  dupCertifiedCoursesObj['Certified Online'] = event.target.checked;
                                  setSelectedCertificationType(dupCertifiedCoursesObj);
                                }}
                              />
                            }
                            label="Certified Online"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={selectedCertificationType['Co-teach']}
                                onChange={(event) => {
                                  let dupCertifiedCoursesObj = selectedCertificationType;
                                  dupCertifiedCoursesObj['Co-teach'] = event.target.checked;
                                  setSelectedCertificationType(dupCertifiedCoursesObj);
                                }}
                              />
                            }
                            label="Co-teach"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={selectedCertificationType['Moved out of country']}
                                onChange={(event) => {
                                  let dupCertifiedCoursesObj = selectedCertificationType;
                                  dupCertifiedCoursesObj['Moved out of country'] =
                                    event.target.checked;
                                  setSelectedCertificationType(dupCertifiedCoursesObj);
                                }}
                              />
                            }
                            label="Moved out of country"
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="adv-form-input-cont">
                      <p className="adv-form-label" style={{ marginBottom: 'auto' }}>
                        Teacher Status
                      </p>
                      <div style={{ display: 'flex', flexDirection: 'row', flex: 5 }}>
                        <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={teacherStatus['Active']}
                                onChange={(event) => {
                                  let dupTeacherStatusObj = teacherStatus;
                                  dupTeacherStatusObj['Active'] = event.target.checked;
                                  setTeacherStatus(dupTeacherStatusObj);
                                }}
                              />
                            }
                            label="Active"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={teacherStatus['Inactive - Deceased']}
                                onChange={(event) => {
                                  let dupTeacherStatusObj = teacherStatus;
                                  dupTeacherStatusObj['Inactive - Deceased'] = event.target.checked;
                                  setTeacherStatus(dupTeacherStatusObj);
                                }}
                              />
                            }
                            label="Inactive - Deceased"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={teacherStatus['Inactive - Not with Org']}
                                onChange={(event) => {
                                  let dupTeacherStatusObj = teacherStatus;
                                  dupTeacherStatusObj['Inactive - Not with Org'] =
                                    event.target.checked;
                                  setTeacherStatus(dupTeacherStatusObj);
                                }}
                              />
                            }
                            label="Inactive - Not with Org"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={teacherStatus['Inactive - Suspended']}
                                onChange={(event) => {
                                  let dupTeacherStatusObj = teacherStatus;
                                  dupTeacherStatusObj['Inactive - Suspended'] =
                                    event.target.checked;
                                  setTeacherStatus(dupTeacherStatusObj);
                                }}
                              />
                            }
                            label="Inactive - Suspended"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={
                                  teacherStatus['Inactive - Returned Kriya Tape and Manual']
                                }
                                onChange={(event) => {
                                  let dupTeacherStatusObj = teacherStatus;
                                  dupTeacherStatusObj['Inactive - Returned Kriya Tape and Manual'] =
                                    event.target.checked;
                                  setTeacherStatus(dupTeacherStatusObj);
                                }}
                              />
                            }
                            label="Inactive - Returned Kriya Tape and Manual"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={teacherStatus['View Only']}
                                onChange={(event) => {
                                  let dupTeacherStatusObj = teacherStatus;
                                  dupTeacherStatusObj['View Only'] = event.target.checked;
                                  setTeacherStatus(dupTeacherStatusObj);
                                }}
                              />
                            }
                            label="View Only"
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="adv-form-input-cont">
                      <p className="adv-form-label">Teacher Type</p>
                      <div style={{ display: 'flex', flexDirection: 'row', flex: 5 }}>
                        <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={teacherType['Full Time']}
                                onChange={(event) => {
                                  let dupTeacherType = teacherType;
                                  dupTeacherType['Full Time'] = event.target.checked;
                                  setTeacherType(dupTeacherType);
                                }}
                              />
                            }
                            label="Full Time"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={teacherType['Part Time']}
                                onChange={(event) => {
                                  let dupTeacherType = teacherType;
                                  dupTeacherType['Part Time'] = event.target.checked;
                                  setTeacherType(dupTeacherType);
                                }}
                              />
                            }
                            label="Part Time"
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="adv-form-input-cont">
                      <p className="adv-form-label">Teacher agreement signed</p>
                      <div style={{ display: 'flex', flexDirection: 'row', flex: 5 }}>
                        <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={agreementSigned['Yes']}
                                onChange={(event) => {
                                  let dupAgreementSigned = agreementSigned;
                                  dupAgreementSigned['Yes'] = event.target.checked;
                                  setAgreementSigned(dupAgreementSigned);
                                }}
                              />
                            }
                            label="Yes"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={agreementSigned['No']}
                                onChange={(event) => {
                                  let dupAgreementSigned = agreementSigned;
                                  dupAgreementSigned['No'] = event.target.checked;
                                  setAgreementSigned(dupAgreementSigned);
                                }}
                              />
                            }
                            label="No"
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="adv-form-input-cont">
                      <p className="adv-form-label">Teacher agreement Signed on or before</p>
                      <input
                        onChange={(event) => {
                          handleSignedOnDateChange(event);
                        }}
                        className="adv-form-input"
                        style={{ height: '55px', paddingLeft: '20px', borderRadius: '5px' }}
                        required="true"
                        type="date"
                        value={signedOnDate}
                        id="agreementSignedOn"
                        name="dob"
                      />
                    </div>
                    <div className="adv-form-input-cont">
                      <p className="adv-form-label">Teacher agreement expiry on or before</p>
                      <input
                        className="adv-form-input"
                        onChange={(event) => {
                          handleExpiryDateChange(event);
                        }}
                        style={{ height: '55px', paddingLeft: '20px', borderRadius: '5px' }}
                        required="true"
                        value={expiredDate}
                        type="date"
                        id="agreementExpiredOn"
                        name="dob"
                      />
                    </div>
                  </div>
                </DialogContent>
                <div className="model-item-seperator"></div>
                <DialogActions style={{ margin: '20px' }}>
                  <UiButton
                    onClick={() => {
                      setAdvFilters();
                      setOpenAdvModal(false);
                    }}
                    style={{ marginLeft: 'auto' }}
                    text={'Search'}></UiButton>
                  <p
                    onClick={() => setOpenAdvModal(false)}
                    style={{ marginRight: 'auto', marginLeft: '20px', cursor: 'pointer' }}>
                    Cancel
                  </p>
                </DialogActions>
              </Dialog>
            </div>
            {console.log(selectedRows, 'selected rows')}

            <Data_table
              onSelectionModelChange={(selectedIds, teachersData) => {
                const selectedRowData = [];
                selectedIds.forEach((id) => {
                  selectedRowData.push(teachersData.find((item) => id === item.basicinfo.id));
                });
                !isParentSelectedRows && setSelectedRows(selectedIds);
                !isParentSelectedRows &&
                  setSelectedRowData(selectedRowData.filter((item) => item !== undefined));
              }}
              filters={searchFilter}
              data={[]}
              height={window.innerHeight - 260}
              selectionModel={selectedRows}
              teachersData={location.state.teachersData}
              // toolBar={ExportPageToolBar}
            />

            <div></div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default ExportPage;

import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './TabBar.css';
// import Home from '../../../pages/Home.js';
import { Link, useNavigate } from 'react-router-dom';

import HomePage from '../../Templates/Home/HomePage/HomePage.js';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function TabBar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const location = window.location.href;
  const currentTab = location.split('/').at(3);
  let currentTabValue = 0;
  const isNewPage = location.split('/').at(4) === 'new' || location.split('/').at(4) === 'new#';
  if (currentTab === 'teachers') {
    currentTabValue = 1;
  }
  if (currentTab == 'program-types') {
    currentTabValue = 2;
  }
  const value = currentTabValue;
  // const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    // setValue(newValue);
  };
  const navigate = useNavigate();

  function handleTeacherHover(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }
  function handlePTHover(event) {
    if (anchorEl2 !== event.currentTarget) {
      setAnchorEl2(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }
  function handleClose2() {
    setAnchorEl2(null);
  }

  return (
    <div className="Tabbar_mainbox">
      <Box sx={{ marginTop: '0px' }} className="Tabbar_boxContainer">
        <Box className="Tabbar_BoxSection">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              '& .MuiTabs-indicator': {
                display: 'none'
              }
            }}
            className="TabBar_Tabssection">
            <Tab
              label={<span style={{ padding: '2px' }}> Home</span>}
              onClick={() => {
                navigate('/home');
                props.applyClearFilters();
              }}
              {...a11yProps(0)}
              style={{
                backgroundColor: value == 0 ? '#1976D2' : 'white',
                color: value == 0 ? 'white' : 'black',
                padding: 0,
                height: '28px',
                display: 'flex',
                alignItems: 'center'
              }}
              className="TabBar_Tab"></Tab>
            <Tab
              label="Teachers"
              onClick={() => navigate('/teachers')}
              onMouseOver={handleTeacherHover}
              {...a11yProps(1)}
              style={{
                backgroundColor: value == 1 ? '#1976D2' : 'white',
                color: value == 1 ? 'white' : 'black',
                height: '28px',
                display: 'flex',
                alignItems: 'center'
              }}
              className="TabBar_Tab"></Tab>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{ marginTop: '8px' }}
              {...a11yProps(2)}
              onMouseLeave={handleClose}
              MenuListProps={{ onMouseLeave: handleClose }}>
              <MenuItem
                style={
                  !isNewPage && currentTab === 'teachers'
                    ? {
                        margin: '4px',
                        borderRadius: '2px',
                        backgroundColor: '#1976D2',
                        height: '28px',
                        color: 'white'
                      }
                    : {}
                }
                onClick={() => {
                  navigate('/teachers');
                  props.applyClearFilters();
                  props.setIsFindTeachersClicked && props.setIsFindTeachersClicked(true);
                }}>
                {' '}
                Find Teachers
              </MenuItem>
              <MenuItem
                style={
                  isNewPage && ['teachers', 'teachers#'].includes(currentTab)
                    ? {
                        margin: '4px',
                        borderRadius: '2px',
                        backgroundColor: '#1976D2',
                        height: '28px',
                        color: 'white'
                      }
                    : {}
                }
                onClick={() => navigate('/teachers/new')}>
                {' '}
                Add Teacher
              </MenuItem>
            </Menu>
            <Tab
              label="Program Types"
              onClick={() => navigate('/program-types')}
              {...a11yProps(2)}
              onMouseOver={handlePTHover}
              style={{
                backgroundColor: value == 2 ? '#1976D2' : 'white',
                color: value == 2 ? 'white' : 'black',
                height: '28px',
                display: 'flex',
                alignItems: 'center'
              }}
              className="TabBar_Tab"></Tab>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl2}
              open={Boolean(anchorEl2)}
              onClose={handleClose2}
              onMouseLeave={handleClose2}
              sx={{ marginTop: '8px' }}
              MenuListProps={{ onMouseLeave: handleClose2 }}>
              <MenuItem
                style={
                  !isNewPage && ['program-types', 'program-types#'].includes(currentTab)
                    ? {
                        margin: '4px',
                        borderRadius: '2px',
                        backgroundColor: '#1976D2',
                        height: '28px',
                        color: 'white'
                      }
                    : {}
                }
                onClick={() => 
                
                {
                  props.setIsFindPTClicked && props.setIsFindPTClicked(true);
                  // props.applyClearFilters();
                  navigate('/program-types')}}>
                {' '}
                Find Program Types
              </MenuItem>
              <MenuItem
                style={
                  isNewPage && currentTab === 'program-types'
                    ? {
                        margin: '4px',
                        borderRadius: '2px',
                        backgroundColor: '#1976D2',
                        height: '28px',
                        color: 'white'
                      }
                    : {}
                }
                onClick={() => navigate('/program-types/new')}>
                {' '}
                Add Program Type
              </MenuItem>
            </Menu>
          </Tabs>
        </Box>
        {/* <div className="TabBar_TabPanelSection">
          <TabPanel value={value} index={0}>
            <HomePage />
          </TabPanel>
          <TabPanel value={value} index={1}>
            Item Two
          </TabPanel>
          <TabPanel value={value} index={2}>
            Item Three
          </TabPanel>
        </div> */}
      </Box>
    </div>
  );
}

import React, { useState } from 'react';
import UiButton from '../../../UiCore/FormComponent/UiButton/UiButton';
import './Export.css';
import * as XLSX from 'xlsx/xlsx.mjs';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDataByRegion } from '../../../../assets/apiCalls/ApiHomePage';
import { useQuery } from '@tanstack/react-query';
import Data_table from '../../Data_table/data_table';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
const addTotalTeachersFeild = (data) => {
  var temp = data.map((d) => {
    return {
      ...d,
      'Total Teachers':
        parseInt(d.Active || 0) + parseInt(d.Inactive || 0) + parseInt(d.ViewOnly || 0)
    };
  });
  return temp;
};
const getSelectedRowsData = (regionData, selectedRows) => {
  return regionData.filter((data) => selectedRows.includes(data.Country));
};

function insertKey(key, value, obj, pos) {
  return Object.keys(obj).reduce((ac, a, i) => {
    if (i === pos) ac[key] = value;
    ac[a] = obj[a];
    return ac;
  }, {});
}

function Export() {
  const location = useLocation();
  const [selectedRows, setSelectedRows] = useState(location.state.selectedRows || []);
  const [selectedRegion, setSelectedRegion] = useState(location.state.selectedRegion);
  const { data: regionData } = useQuery(['region-data', selectedRegion], getDataByRegion, {
    select: (data) => {
      return addTotalTeachersFeild(data.data);
    }
  });
  const handleExportAllRows = () => {
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(
      regionData.map((item) => {
        let temp = item;
        temp.Active = +item.Active;
        temp.Inactive = +item.Inactive;
        temp = insertKey('View Only', +item.ViewOnly, temp, 3);

        delete temp.ViewOnly;
        return temp;
      })
    );
    XLSX.utils.book_append_sheet(wb, ws, 'Teachers Data');
    XLSX.writeFile(wb, 'AOL Teachers Information.xlsx');
  };
  const handleExportSelectedRows = () => {
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(
      getSelectedRowsData(
        regionData.map((item) => {
          let temp = item;
          temp.Active = +item.Active;
          temp.Inactive = +item.Inactive;
          temp = insertKey('View Only', +item.ViewOnly, temp, 3);
          delete temp.ViewOnly;
          return temp;
        }),
        selectedRows
      )
    );

    XLSX.utils.book_append_sheet(wb, ws, 'Teachers Data');
    XLSX.writeFile(wb, 'AOL Teachers Information.xlsx');
  };

  const ExportPageToolBar = () => {
    return (
      <ExportPageToolBarComponent
        selectedRows={selectedRows}
        selectedRegion={selectedRegion}
        handleExportSelectedRows={handleExportSelectedRows}
        handleExportAllRows={handleExportAllRows}
      />
    );
  };

  return (
    <div className="export-outer-container">
      <div className="export-inner-container1">
        <div className="export-inner-container2">
          {console.log(regionData, 'major debug')}
          {regionData ? (
            <Data_table
            renderToolbarMaterial
              data={regionData}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              height={regionData.length > 100 ? 5500 : regionData.length * 52 + 300}
              toolBar={ExportPageToolBar}
            />
          ) : (
            'Loading...'
          )}
        </div>
      </div>
    </div>
  );
}

export default Export;

const ExportPageToolBarComponent = ({
  selectedRegion,
  handleExportSelectedRows,
  handleExportAllRows,
  selectedRows
}) => {
  const navigate = useNavigate();
  function handleClose() {
    navigate('/home', {
      state: {
        selectedRegion: selectedRegion,
        selectedRows: selectedRows
      }
    });
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '40px 10px 40px 10px'
      }}>
      <p style={{ marginRight: '20px' }}>Region : {selectedRegion}</p>
      <GridToolbarQuickFilter
        placeholder="Search by Country"
        className="export-page-search"
        variant="outlined"
        sx={{ maxWidth: '220px' }}
      />
      <UiButton
        text="Export Selected Rows"
        style={{ marginLeft: 'auto', marginRight: '20px' }}
        disabled={selectedRows.length != 0 ? false : true}
        onClick={handleExportSelectedRows}></UiButton>
      <UiButton text="Export All Rows" onClick={handleExportAllRows}></UiButton>
      {selectedRows.length !== 0 && (
        <p style={{ fontSize: '14px', marginLeft: '30px', marginTop: '5px' }}>
          {selectedRows.length} records selected
        </p>
      )}
      <IconButton style={{ background: '#eee', marginLeft: '10px', marginTop: '5px' }}>
        <CloseIcon fontSize="small" onClick={handleClose} />
      </IconButton>

      <hr className="hr-line"></hr>
    </div>
  );
};

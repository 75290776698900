/* eslint-disable react/prop-types */
import { TextField } from '@mui/material';
import { OutlinedTextFieldProps } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import FormHelperText from '@mui/material/FormHelperText';
import { useController } from 'react-hook-form';

const InputFormFeild2 = (props) => {
  const {
    field,
    fieldState: { error }
  } = useController({ name: props.name, defaultValue: '' });
  return (
    <div>
      {/* {console.log('feild value', field.value)} */}
      <TextField
        {...props}
        {...field}
        className={props.className}
        onChange={props.onChange ? props.onChange : undefined}
        value={props.value ? props.value : undefined}
        helperText={props.errorMessage ? props.errorMessage : undefined}
        error={props.errorMessage ? props.errorMessage : undefined}
      />
    </div>
  );
};

export default InputFormFeild2;
InputFormFeild2.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string
};

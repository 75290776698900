import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router';
import UiButton from 'Components/UiCore/FormComponent/UiButton/UiButton';
import './styles.css';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import {
  Autocomplete,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  InputBase,
  Tooltip
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import debounce from 'lodash/debounce';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Navbar from 'Components/UiCore/Navbar/Navbar';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Link from '@mui/material/Link';
import InfoIcon from '@mui/icons-material/Info';
import Modal from '@mui/material/Modal';
import FilterListIcon from '@mui/icons-material/FilterList';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CloseIcon from '@mui/icons-material/Close';
import { promisify } from 'util';
import MuiPhoneNumber from 'material-ui-phone-number';
import ReactLoading from 'react-loading';
import PhoneInput from 'react-phone-input-2';
import Alert from '@mui/material/Alert';
import CancelIcon from '@mui/icons-material/Cancel';
import cityData from '../cityData.json';
import languagesData from '../languagesData.json';

// import { DatePicker } from '@material-ui/pickers'
import { Country, State, City } from 'country-state-city';
import aws from 'aws-sdk';

import { uploadFile } from 'react-s3';
import {
  DataGrid,
  ColDef,
  ValueGetterParams,
  GridColumnHeaderTitle,
  GridColumnHeaderSortIcon
} from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import { useData } from './DataContext';
import { useForm, Controller } from 'react-hook-form';
import RenderCourseInformation from './renderCourseInformation';
import PageTemplate from 'Components/UiCore/pageTemplate/PageTemplate';
import Snackbar from '@mui/material/Snackbar';
import S3FileUpload from 'react-s3/lib/ReactS3';
import sampleImage from '../View/sampleimage.jpeg';
import countryTelData from 'country-telephone-data';

import * as axios from 'axios';
// import config from '../../../.s3Config.json'
import { upload } from '@testing-library/user-event/dist/upload';
import Multiselect from 'multiselect-react-dropdown';
import moment from 'moment';
import TeacherModal from './teacherModal';
import { Language } from '@mui/icons-material';

// var countryTelData = require('country-telephone-data')

function format(inputDate) {
  let date, month, year;

  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;
  year = inputDate.getFullYear();

  date = date.toString().padStart(2, '0');

  month = month.toString().padStart(2, '0');

  return `${date}/${month}/${year}`;
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const config = {
  bucketName: 'tptm-aold',
  dirName: 'user-images',
  region: 'ap-south-1',
  accessKeyId: 'AKIAZ5Y75QNNSZIU627W',
  secretAccessKey: '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'
};

const s3 = new aws.S3({
  region: 'ap-south-1',
  accessKeyId: 'AKIAZ5Y75QNNSZIU627W',
  secretAccessKey: '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',
  signatureVersion: 'v4'
});

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  // maxHeight: '80vh',
  transform: 'translate(-50%, -50%)',
  width: '95vw',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowX: 'hidden'
};

const steps = ['Personal Info', 'Contact Info', 'Teaching Info', 'Course Info'];
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

window.Buffer = window.Buffer || require('buffer').Buffer;

const regions = ['All Regions', 'Latam', 'Europe', 'Oceania', 'Far East'];

function TeachersNewPage(props) {
  const [openModal, setOpenModal] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openEndDateSnack, setOpenEndDateSnack] = React.useState(false);

  const [openPrimaryCountrySnack, setOpenPrimaryCountrySnack] = React.useState(false);
  const [alternateNumber, setAlternateNumber] = React.useState(false);
  const [countryAlreadySelected, setCountryAlreadySelected] = React.useState(false);
  const [stateList, setStateList] = React.useState([]);
  const [cityList, setCityList] = React.useState([]);

  const [courseInfoData, setCourseInfoData] = React.useState([{ key: 0, isFormSubmitted: false }]);
  const [teachingCountryData, setTeachingCountryData] = React.useState([]);
  const [tempTeachingCountryData, setTempTeachingCountryData] = React.useState([]);

  const [countryData, setCountryData] = React.useState([]);
  const [stateData, setStateData] = React.useState([]);
  const [courseTypeData, setCourseTypeData] = React.useState([]);
  const [ttpCertificationTypeData, setTtpCertiicationTypeData] = React.useState([]);
  const [ttpTypeData, setTtpTypeData] = React.useState([]);
  const [ttpLocationData, setTtpLocationData] = React.useState([]);
  const [language, setLanguage] = React.useState([]);
  const [rowKey, setRowKey] = React.useState(0);
  const [courseInfoRowKey, setCourseInfoRowKey] = React.useState(0);
  const [showError, setShowError] = React.useState(false);
  const [isSelectPrimaryCountryDisabled, setIsSelectPrimaryCountryDisabled] = React.useState(false);
  const [isSaveAndNew, setIsSaveAndNew] = React.useState(false);

  const [tcd, setTcd] = React.useState([]);
  const [statusLoader, setStatusLoader] = React.useState(false);

  const [teacherStatus, setTeacherStatus] = React.useState(null);
  const [generatedCode, setGeneratedCode] = React.useState(null);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = React.useState(false);
  const [stateFormData, setStateFormData] = React.useState({});
  const [imageKey, setImageKey] = React.useState('');
  const [pageLoader, setPageLoader] = useState(false);
  const [unDisabledRow, setUnDisabledRow] = useState(null);
  const [savedUnDisabledRow, setSavedUndisabledRow] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [deleteRowKey, setDeleteRowKey] = useState(null);
  const [submitError, setSubmitError] = useState(false);
  const [emailValidationError, setEmailValidationError] = useState(false);
  const [fieldValidationErrors, setFieldValidationErrors] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [imagePath, setImagePath] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [fileName, setFileName] = useState('');
  const [imageUploadFailed, setImageUploadFailed] = useState(false);
  const [imageTooLarge, setImageTooLarge] = useState('');
  const [emailDebounceValue, setEmailDebounceValue] = React.useState('');
  const [showFieldValidationErrorOnSubmit, setShowFieldValidationErrorOnSubmit] =
    React.useState(false);
  const [openViewOnlyStatusPopup, setOpenViewOnlyStatusPopup] = React.useState(false);
  const [openInactiveStatusPopUp, setOpenInactiveStatusPopUp] = React.useState(false);
  const imageUploadRef = React.createRef();

  const handleFormDataChange = (value, key) => {
    let currentStateFormData = stateFormData;

    currentStateFormData[key] = value;

    setStateFormData({ ...currentStateFormData });
  };
  const getCountryName = (id)=>{
      const countryname = countryData.find(
        (item) => item.countryid === id
      ).countryname;
      return countryname;
  }
  const getCourseNameById = (id,lookupdata)=>{
    const cname = lookupdata.find(
                              (item) => item.id === id
                            ).programtypename;
    return cname;
  }
  const getTppNameById = (id,lookupdata)=>{
    const ttp = lookupdata.find(
                              (item) => item.id === id
                            ).lookupdesc;
    return ttp;
  }
  function handleTeacherHover(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  // t(() => {
  //   const fileInput = document.querySelector('input[type="file"]');

  //   // Create a new File object
  //   const myFile = new File(['Hello World!'], 'myFile.txt', {
  //     type: 'text/plain',
  //     lastModified: new Date()
  //   });useEffec

  //   // Now let's create a DataTransfer to get a FileList
  //   const dataTransfer = new DataTransfer();
  //   dataTransfer.items.add(myFile);
  //   fileInput.files = dataTransfer.files;
  // }, []);
  // useEffect(() => {
  //   // countries
  //   const token = sessionStorage.getItem('user');

  //   axios
  //     .get(`${process.env.REACT_APP_BASE_API_URL}/teachers/countries`, {
  //       headers: { authorization: `Bearer ${token}` }
  //     })
  //     .then((response) => {
  //       setCountries([
  //         { countryname: 'All Countries', regionname: 'All Regions' },
  //         ...response.data
  //       ]);
  //       setselectedTeachingCountry([]);
  //       setSelectedCurrentCountry([]);
  //       setSelectedTTPCountry([]);
  //     })
  //     .catch((error) => console.error(error));
  // }, []);

  useEffect(() => {
    console.log('asdasdasdasdasd');
    setTcd(teachingCountryData);
    console.log(tcd, 'asdasdasd -- tcd');
  }, [teachingCountryData]);


  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setError,
    clearErrors,
    watch,
    trigger,
    setValue,
    resetField,
    formState: { errors }
  } = useForm({ mode: 'onChange' });

  if (
    countryData.length === 0 &&
    courseTypeData.length === 0 &&
    ttpCertificationTypeData.length === 0 &&
    ttpTypeData.length === 0 &&
    ttpLocationData.length === 0
  ) {
    const token = sessionStorage.getItem('user');
    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/teachers/countries`, {
        headers: { authorization: `Bearer ${token}` }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      })
      .then((response) => {
        setCountryData(response.data);
      });
      // axios
      // .get(`${process.env.REACT_APP_BASE_API_URL}/teachers/getstates`, {
      //   headers: { authorization: `Bearer ${token}` }
      // })
      // .catch((error) => {
      //   if (error.response && error.response.status === 401) {
      //     navigate('/login');
      //   }
      // })
      // .then((response) => {
      //   console.log(response.data)
      //   // setCountryData(response.data);
      // });
    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/teachers/courseTypes`, {
        headers: { authorization: `Bearer ${token}` }
      })
      .then((response) => {
        setCourseTypeData(response.data);
      });
    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/teachers/ttpcertificationtype`, {
        headers: { authorization: `Bearer ${token}` }
      })
      .then((response) => {
        setTtpCertiicationTypeData(response.data);
      });
    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/teachers/ttptype`, {
        headers: { authorization: `Bearer ${token}` }
      })
      .then((response) => {
        setTtpTypeData(response.data);
      });
    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/teachers/ttplocation`, {
        headers: { authorization: `Bearer ${token}` }
      })
      .then((response) => {
        setTtpLocationData(response.data);
      });
  }

  const navigate = useNavigate();

  // const handleUpload = (e) => {
  //   S3FileUpload.uploadFile(e.target.files[0], config)
  //     .then((data) => {
  //     })
  //     .catch((err) => {
  //       alert(err);
  //     });
  // };
  const getEmergencyFoundImg = (urlImg) => {
    var img = new Image();
    img.src = urlImg;
    img.crossOrigin = 'Anonymous';

    var canvas = document.createElement('canvas'),
      ctx = canvas.getContext('2d');

    canvas.height = img.naturalHeight;
    canvas.width = img.naturalWidth;
    ctx.drawImage(img, 0, 0);

    var b64 = canvas.toDataURL('image/png').replace(/^data:image.+;base64,/, '');
    return b64;
  };
  function makeid() {
    var text = '';
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (var i = 0; i < 50; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    var reader = new FileReader();
    console.log(event, 'image debug');
    setImageLoaded(false);
    //Read the contents of Image File.
    // reader.readAsDataURL(fileUpload.files[0]);
    if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
      alert('Please select only supported image formats');
      handleFormDataChange('', 'filePath');
    } else if (file.size > 2097152) {
      // alert('File is too big! Max Size 300 Kb');
      setImageTooLarge('File is too big! Max Size 2 Mb');
      // setError('imageUpload', {
      //   type: 'custom',
      //   message: 'File is too big! Max Size 300 Kb'
      // });

      handleFormDataChange('', 'filePath');
      const fileInput = document.querySelector('input[type="file"]');
      const dataTransfer = new DataTransfer();
      fileInput.files = dataTransfer.files;
    } else {
      setImageTooLarge('');
      // clearErrors('imageUpload');
      setImagePath(event.target.value);
      setFileName(event.target.files[0].name);

      handleFormDataChange(event.target.value, 'filePath');

      const convertedFile = await convertToBase64(file);
      // const rawBytes = await randomBytes(16);
      const imageName = makeid();
      const params = {
        Bucket: 'tptm-aold',
        Key: imageName,
        Expires: 60
      };

      const uploadURL = await s3.getSignedUrlPromise('putObject', params);

      console.log(uploadURL, 'major debug');

      if (!uploadURL.ok) {
        console.log('im happening');
        setImageUploadFailed(true);
        // handleFormDataChange('', 'filePath');
        // const fileInput = document.querySelector('input[type="file"]');
        // const dataTransfer = new DataTransfer();
        // fileInput.files = dataTransfer.files;
      }
      // Request will be sent from here in the future
      axios
        .put(uploadURL, file, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          console.log(response, 'im here ffsnfadfad');
          setImageLoaded(true);
          setImageKey(imageName);
          setImageUploadFailed(false);
        });

      // fetch(uploadURL, {
      //   method: 'PUT',
      //   headers: {
      //     'Content-Type': 'multipart/form-data'
      //   },
      //   body: file
      // });
    }
  };
  const convertToBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const onSubmit = async (data) => {
    setImageTooLarge('');
    const entries = Object.entries(data).filter((entry) => entry[0] !== 'files');

    let error = false;
    let expiredOnError = false;
    console.log(data, 'submit data', errors);
    setIsFormSubmitted(true);

    if (data.expiredOn) {
      if (
        moment(data.signedOn && data.signedOn.substring(0, 10)).isAfter(
          data.expiredOn && data.expiredOn.substring(0, 10)
        )
      ) {
        error = true;
        expiredOnError = true;
        if (expiredOnError) {
          setError('expiredOn', {
            type: 'custom',
            message: 'Teaching Expiry Date cannot be less than Teaching Start Date'
          });
        } else {
          clearErrors('expiredOn');
        }
      }
    }
    if (teachingCountryData.length === 0) {
      error = true;
      setError('teachingCountryTable', {
        type: 'custom',
        message: 'Please Fill Teaching Country Table Data'
      });
    } else {
      clearErrors('teachingCountryTable');
      // window.scrollTo(0, 0);
    }
    console.log(error, 'majoor debug 1');

    if (courseInfoData.length !== 0) {
      // error = true;
      let courseInfoError = false;
      let tempCourseInfoData = courseInfoData;
      tempCourseInfoData.map((item) => {
        const rowKey = item.key;
        const rowIndex = courseInfoData.findIndex((i) => i.key === rowKey);
        const rowData = courseInfoData[rowIndex];
        rowData.isFormSubmitted = true;
        if (
          item.courseType === null ||
          item.certificationType === null ||
          !item.courseType ||
          !item.certificationType
        ) {
          courseInfoError = true;
        }
        return rowData;
      });
      setCourseInfoData([...tempCourseInfoData]);

      if (courseInfoError) {
        error = true;
        setError('courseInformation', {
          type: 'custom',
          message: 'Please Fill Required Course Information Fields'
        });
      } else {
        clearErrors('courseInformation');
      }
    }

    console.log(error, 'majoor debug 2');

    let fieldValidationError = false;

    let fieldValidationErrors = {};

    if (data.firstName === '') {
      fieldValidationError = true;
      fieldValidationErrors.firstName = true;
    }

    if (data.lastName === '') {
      fieldValidationError = true;
      fieldValidationErrors.lastName = true;
    }

    if (data.gender === null) {
      fieldValidationError = true;
      fieldValidationErrors.gender = true;
    }

    if (data.dob === '') {
      fieldValidationError = true;
      fieldValidationErrors.dob = true;
    }

    if (!fieldValidationErrors.dob) {
      if (moment().diff(moment(data.dob), 'years') < 18) {
        fieldValidationError = true;
        fieldValidationErrors.dobTooYoung = true;
      }
    }

    if (data.emailId === '') {
      fieldValidationError = true;
      fieldValidationErrors.emailId = true;
    }
    console.log(
      moment().diff(moment(data.dob), 'years'),
      fieldValidationErrors,
      ' major debug dob code'
    );

    if (!stateFormData.mobile1Code || data.mobile1 === '') {
      fieldValidationError = true;
      fieldValidationErrors.mobile1 = true;
    }
    if (data.mobile1.toString().length > 13) {
      fieldValidationError = true;
      fieldValidationErrors.mobile1 = true;
    }

    if (data.mobile2 && data.mobile2.toString().length > 13) {
      fieldValidationError = true;
      fieldValidationErrors.mobile2 = true;
    }

    if (alternateNumber && (!stateFormData.mobile2Code || data.mobile2 === '')) {
      fieldValidationError = true;
      fieldValidationErrors.mobile2 = true;
    }

    if (data.addLine1 === '') {
      fieldValidationError = true;
      fieldValidationErrors.addLine1 = true;
    }

    if (!stateFormData.country) {
      fieldValidationError = true;
      fieldValidationErrors.country = true;
    }

    if (!stateFormData.state) {
      fieldValidationError = true;
      fieldValidationErrors.state = true;
    }

    if (!stateFormData.city) {
      fieldValidationError = true;
      fieldValidationErrors.city = true;
    }

    if (!stateFormData.teacherStatus) {
      fieldValidationError = true;
      fieldValidationErrors.teacherStatus = true;
    }

    if (teacherStatus === 'Inactive' && !stateFormData.teacherStatus2) {
      fieldValidationError = true;
      fieldValidationErrors.teacherStatus2 = true;
    }

    if (!stateFormData.swami) {
      fieldValidationError = true;
      fieldValidationErrors.swami = true;
    }

    if (!stateFormData.officeBearer) {
      fieldValidationError = true;
      fieldValidationErrors.officeBearer = true;
    }

    if (!stateFormData.teacherAgreementSigned) {
      fieldValidationError = true;
      fieldValidationErrors.teacherAgreementSigned = true;
    }

    if (data.languages === '' || data.languages === []) {
      fieldValidationError = true;
      fieldValidationErrors.languages = true;
    }

    console.log(error, fieldValidationError, 'majoor debug 3');

    setFieldValidationErrors(fieldValidationErrors);

    if (error || fieldValidationError) {
      setShowFieldValidationErrorOnSubmit('true');
    }

    if (!error && !fieldValidationError) {
      const payload = {};
      const basicInfo = {
        firstname: data.firstName,
        lastname: data.lastName,
        aliasname1: data.aliasName1,
        aliasname2: data.aliasName2,
        gender: stateFormData.gender,
        dob: data.dob,
        uploadphoto: imageKey,
        email: data.emailId,
        mobile1: stateFormData.mobile1Code + '-' + data.mobile1.toString(),
        mobile2: stateFormData.mobile2Code
          ? stateFormData.mobile2Code + '-' + data.mobile2.toString()
          : null,
        address1: data.addLine1,
        address2: data.addLine2,
        country: stateFormData.country,
        state: stateFormData.state,
        city: stateFormData.city,
        countryCode: stateFormData.countryCode,

        stateCode: stateFormData.stateCode,

        pincode: data.pincode,
        // teachercode: data.teacherCode,

        teachercode: generatedCode,
        harmonycode: data.harmonyCode,

        teacheractivitystatus: stateFormData.teacherStatus,
        teacheractivitysubstatus: data.teacherStatus2,
        isswamiorsadhvi: stateFormData.swami === 'yes' ? 1 : 0,
        fulltimeofficebearer: stateFormData.officeBearer === 'yes' ? 1 : 0,
        istravellingteacher: data.travellingTeacher === 'yes' ? 1 : 0,
        languages: data.languages.toString(),
        isteacheragreementsigned: stateFormData.teacherAgreementSigned === 'yes' ? 1 : 0,
        teacheragreementsignedon: data.signedOn === '' ? undefined : data.signedOn,
        teacheragreementexpiredon: data.expiredOn === '' ? undefined : data.expiredOn,
        filename: fileName
      };
      if (stateFormData.mobile2Code) {
        basicInfo.mobile2 = stateFormData.mobile2Code + '-' + data.mobile2.toString();
      }
      const teachingcourse = [];
      courseInfoData.map((item) => {
        teachingcourse.push({
          coursetype: item.courseType,
          ttptype: item.ttpType,
          ttpcertificationtype: item.certificationType,
          ttpapplicationcountry: item.applicationCountry,
          ttplocation: item.location,
          ttpgraduationdate: item.graduationDate === '' ? undefined : item.graduationDate,
          notes: item.notes
        });
      });

      const teachingcountry = [];
      teachingCountryData.map((item) => {
        teachingcountry.push({
          countryname: item.country,
          teachingstartdate: item.startDate,
          teachingexpirydate: item.endDate === '' ? undefined : item.endDate,
          countrylevelstatus: item.countryStatus,
          teachertype: item.teacherType === 'Full Time' ? 1 : 0,
          primarycountry: item.primaryCountry,
          coursetypes: item.courseTypes.toString()
        });
      });
      const primaryCountryName = teachingcountry.find(
        (country) => country.primarycountry
      ).countryname;
      basicInfo.primarycountryname = primaryCountryName;
      payload.basicinfo = basicInfo;
      payload.teachingcourse = teachingcourse;
      payload.teachingcountry = teachingcountry;

      Object.keys(payload).forEach((key) => {
        if (payload[key] === null) {
          delete payload[key];
        }
      });
      // setIsSubmitSuccessful(true);
      // resolveAfter2Seconds();
      const token = sessionStorage.getItem('user');
      setEmailValidationError(false);
      axios
        .post(`${process.env.REACT_APP_BASE_API_URL}/teachers/createteacher`, payload, {
          headers: { authorization: `Bearer ${token}` }
        })
        .then((response) => {
          console.log(response);
          if (response.data.name !== 'error') {
            if (!isSaveAndNew) {
              navigate(`/teachers/${response.data.id}/view`);
            } else {
              setIsSubmitSuccessful(true);
              resolveAfter2Seconds();
            }
          } else {
            setSubmitError(true);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setEmailValidationError(true);
          }
        });
    }
  };

  function resolveAfter2Seconds() {
    return new Promise((resolve) => {
      setTimeout(() => {
        setIsSubmitSuccessful(false);
      }, 4000);
    });
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      setCourseInfoData([...[{ key: 0 }]]);
      setTeachingCountryData([]);
      setLanguage([]);
      // setValue('gender', undefined, { shouldDirty: truef });
      // resetField("country")
      setStateFormData({});
      reset({
        firstName: '',
        lastName: '',
        aliasName1: '',
        aliasName2: '',
        gender: null,
        dob: '',
        // uploadphoto: data.file,
        emailId: '',
        mobile1: '',
        mobile2: '',
        addLine1: '',
        addLine2: '',
        country: '',
        state: '',
        city: '',
        pincode: '',
        teacherCode: '',
        teacherStatus: '',
        teacherStatus2: '',
        swami: '',
        officeBearer: '',
        travellingTeacher: '',
        teacherAgreementSigned: '',
        languages: '',
        signedOn: '',
        expiredOn: '',
        harmonyCode: ''
      });
    }
    // setPageLoader(true);
    // resolveAfter2Seconds(6000); //7 seconds in milliseconds
  }, [isSubmitSuccessful]);

  const myRef = useRef(null);
  const personalInfoRef = useRef(null);
  const contactRef = useRef(null);
  const teachingRef = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseDeleteRow = () => {
    setOpen(false);
  };

  const isEndDateBeforeStartDate = (startDate, endDate) => {
    let endDateError = false;
    if (endDate) {
      if (
        moment(startDate && startDate.substring(0, 10)).isAfter(endDate && endDate.substring(0, 10))
      ) {
        endDateError = true;
      }
    }
    return endDateError;
  };

  const expiredOnError = () => {
    let expiredOnError = false;
    if (
      moment(watch('signedOn') && watch('signedOn').substring(0, 10)).isAfter(
        watch('expiredOn') && watch('expiredOn').substring(0, 10)
      )
    ) {
      expiredOnError = true;
    }
    return expiredOnError;
  };

  const delayedEmailValue = useCallback(
    debounce((q) => setEmailDebounceValue(q), 600),
    []
  );

  const delayedEmailTrigger = useCallback(
    debounce(() => trigger('emailId'), 1),
    []
  );

  const handleMakeCountryStatusInactive = () => {
    handleFormDataChange('Inactive', 'teacherStatus');
    let finalArray = teachingCountryData;

    finalArray = finalArray.map((item) => {
      let finalItem = item;
      finalItem.countryStatus = 'Inactive';
      finalItem.endDate = moment().format('YYYY-MM-DD');
      return finalItem;
    });
    setTeachingCountryData(finalArray);
    setTeacherStatus('Inactive');
    setOpenInactiveStatusPopUp(false);
  };

  const hanldeMakeCountryStatusOutOfCountry = () => {
    handleFormDataChange('View only', 'teacherStatus');
    let finalArray = teachingCountryData;

    finalArray = finalArray.map((item) => {
      let finalItem = item;
      finalItem.countryStatus = 'Moved out of country';
      finalItem.endDate = moment().format('YYYY-MM-DD');
      return finalItem;
    });
    setTeacherStatus('View only');

    setTeachingCountryData(finalArray);
    setOpenViewOnlyStatusPopup(false);
  };
  const modalRows = tempTeachingCountryData.map((item, i) => {
    return {
      country: item.country,
      startDate: item.startDate,
      endDate: item.endDate,
      countryStatus: item.countryStatus,
      teacherType: item.teacherType,
      primaryCountry: item.primaryCountry,
      id: i,
      key: item.key,
      courseTypes: item.courseTypes || []
    };
  });

  console.log(tempTeachingCountryData, '9678');
  const modalColumns = [
    {
      field: 'country',
      headerName: 'Country',
      // flex: 1.5,
      minWidth: 200,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      disableColumnMenu: true,

      renderCell: (rowContent) => {
        const row = rowContent.row;

        return (
          <Select
            id="country"
            error={openSnack && !row.country ? 'true' : false}
            value={row.country ? getCountryName(row.country):row.country}
            // {...register('country')}
            inputProps={{ 'aria-label': 'Without label' }}
            // className="modalRowElement"
            sx={{
              width: 150,
              left: 0
            }}
            size="small"
            renderValue={() => row.country ? getCountryName(row.country):row.country}
            onChange={(e) => {
              const isAlreadySelected = tempTeachingCountryData.find(
                (i) => i.country === e.target.value
              );

              if (!isAlreadySelected) {
                const rowKey = row.key;
                const rowIndex = tempTeachingCountryData.findIndex((i) => i.key === rowKey);
                const rowData = tempTeachingCountryData[rowIndex];
                console.log(rowKey, rowIndex, rowData, row, 'country selection fail');
                rowData.country =  e.target.value;
                const finalRowData = tempTeachingCountryData;
                finalRowData[rowIndex] = rowData;
                setTempTeachingCountryData([...finalRowData]);
                setCountryAlreadySelected(false);
              } else {
                const rowKey = row.key;
                const rowIndex = tempTeachingCountryData.findIndex((i) => i.key === rowKey);
                const rowData = tempTeachingCountryData[rowIndex];
                rowData.country = null;
                const finalRowData = tempTeachingCountryData;
                finalRowData[rowIndex] = rowData;
                setTempTeachingCountryData([...finalRowData]);
                setCountryAlreadySelected(true);
              }

              if (openSnack) {
                let error = false;
                tempTeachingCountryData.map((item) => {
                  if (!item.teacherType) {
                    error = true;
                  }
                  if (!item.country) {
                    error = true;
                  }
                  if (!item.startDate) {
                    error = true;
                  }
                  if (!item.countryStatus) {
                    error = true;
                  }
                });
                if (!error) {
                  setOpenSnack(false);
                }
              }

              if (openSnack) {
                let error = false;
                tempTeachingCountryData.map((item) => {
                  if (!item.teacherType) {
                    error = true;
                  }
                  if (!item.country) {
                    error = true;
                  }
                  if (!item.startDate) {
                    error = true;
                  }
                  if (!item.countryStatus) {
                    error = true;
                  }
                });
                if (!error) {
                  setOpenSnack(false);
                }
              }
            }}>
            <MenuItem key={'ss'} value={null}></MenuItem>
            {
              countryData.map( item =>
                {
                  return (
                    <MenuItem key={item.isocode} name={item.isocode} value={item.countryid}>
                      {item.countryname}
                    </MenuItem>
                  );
                }
              )
            }
          </Select>
        );
      }
    },
    {
      field: 'startDate',
      headerName: 'Teaching Start Date',
      // flex: 1.5,
      minWidth: 200,

      headerAlign: 'center',
      align: 'center',
      key: 'startDate',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (rowContent) => {
        const row = rowContent.row;
        return (
          <Input
            {...register(`startDate${row.key}`)}
            // required="true"
            type="date"
            key={`startDate${row.key}`}
            id={`startDate${row.key}`}
            error={openSnack && !row.startDate ? 'true' : false}
            // className="modalRowElement"
            value={row.startDate} // id="startDate"
            name="startDate"
            sx={{
              width: 150,
              left: 0
            }}
            inputProps={{ value: row.startDate }}
            defaultValue={row.startDate}
            onChange={(e) => {
              const rowKey = row.key;
              const rowIndex = tempTeachingCountryData.findIndex((i) => i.key === rowKey);
              const rowData = tempTeachingCountryData[rowIndex];
              rowData.startDate = e.target.value;
              const finalRowData = tempTeachingCountryData;
              finalRowData[rowIndex] = rowData;
              // tempTeachingCountryData = [...finalRowData]
              setTempTeachingCountryData([...finalRowData]);
              if (openSnack) {
                let error = false;
                tempTeachingCountryData.map((item) => {
                  if (!item.teacherType) {
                    error = true;
                  }
                  if (!item.country) {
                    error = true;
                  }
                  if (!item.startDate) {
                    error = true;
                  }
                  if (!item.countryStatus) {
                    error = true;
                  }
                });
                if (!error) {
                  setOpenSnack(false);
                }
              }
            }}
          />
        );
      }
    },
    {
      field: 'endDate',
      headerName: 'Teaching End Date',
      key: 'endDate',
      // flex: 1.5,
      minWidth: 200,

      headerAlign: 'center',
      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (rowContent) => {
        const row = rowContent.row;

        return (
          <Input
            // {...register('dsdaob')}
            {...register(`endDate${row.key}`)}
            // required="true"
            type="date"
            key={`endDate${row.key}`}
            id={`endDate${row.key}`}
            // className="modalRowElement"
            sx={{
              width: 180,
              left: 0
            }}
            disablePast="true"
            value={rowContent.row.endDate}
            error={openEndDateSnack && isEndDateBeforeStartDate(row.startDate, row.endDate)}
            name="endDate"
            onChange={(e) => {
              const rowKey = row.key;
              const rowIndex = tempTeachingCountryData.findIndex((i) => i.key === rowKey);
              const rowData = tempTeachingCountryData[rowIndex];
              rowData.endDate = e.target.value;
              const finalRowData = tempTeachingCountryData;
              finalRowData[rowIndex] = rowData;
              setTempTeachingCountryData([...finalRowData]);
              let endDateError = false;
              tempTeachingCountryData.map((item) => {
                if (item.endDate) {
                  if (
                    moment(item.startDate && item.startDate.substring(0, 10)).isAfter(
                      item.endDate && item.endDate.substring(0, 10)
                    )
                  ) {
                    endDateError = true;
                  }
                }
              });
              if (!endDateError) {
                setOpenEndDateSnack(false);
              }
            }}
          />
        );
      }
    },
    {
      field: 'status',
      headerName: 'Teacher Status',
      // flex: 1.5,
      minWidth: 150,

      headerAlign: 'center',
      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (rowContent) => {
        const row = rowContent.row;

        return (
          <Select
            id="status"
            // value={age}
            // {...register('status')}
            // required="true"
            // className="modalRowElement"
            key={row.key}
            sx={{
              width: 150,
              left: 0
            }}
            size="small"
            inputProps={{ 'aria-label': 'Without label' }}
            value={row.countryStatus}
            error={openSnack && !row.countryStatus ? 'true' : false}
            disabled={row.countryStatus === 'Inactive'}
            // onChange={handleChange}

            onChange={(e) => {
              const rowKey = row.key;
              const rowIndex = tempTeachingCountryData.findIndex((i) => i.key === rowKey);
              const rowData = tempTeachingCountryData[rowIndex];
              rowData.countryStatus = e.target.value;
              const finalRowData = tempTeachingCountryData;
              finalRowData[rowIndex] = rowData;
              // tempTeachingCountryData = [...finalRowData];
              setTempTeachingCountryData([...finalRowData]);
              if (openSnack) {
                let error = false;
                tempTeachingCountryData.map((item) => {
                  if (!item.teacherType) {
                    error = true;
                  }
                  if (!item.country) {
                    error = true;
                  }
                  if (!item.startDate) {
                    error = true;
                  }
                  if (!item.countryStatus) {
                    error = true;
                  }
                });
                if (!error) {
                  setOpenSnack(false);
                }
              }
            }}>
            {/* {ttpCertificationTypeData.map((item) => {
            return (
              <MenuItem key={item.lookupdesc} value={item.lookupdesc}>
                {item.lookupdesc}
              </MenuItem>
            );
          })} */}
            <MenuItem value={'Active'}>Active</MenuItem>
            <MenuItem value={'Assist'}>Assist</MenuItem>
            <MenuItem value={'Moved out of country'}>Moved out of country</MenuItem>
            <MenuItem value={'Not Teaching'}>Not Teaching</MenuItem>
            <MenuItem  value={'Inactive'}>
              Inactive
            </MenuItem>
            {/* disabled={getValues('teacherStatus') === 'Inactive'} */}
          </Select>
        );
      }
    },
    {
      field: 'type',
      headerName: 'Teacher Type',
      // flex: 1.5,
      minWidth: 150,

      headerAlign: 'center',
      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (rowContent) => {
        const row = rowContent.row;

        return (
          <Select
            id="status"
            // value={age}
            // {...register('teacherType')}
            // required="true"

            inputProps={{ 'aria-label': 'Without label' }}
            // className="modalRowElement"
            sx={{
              width: 150,
              left: 0
            }}
            size="small"
            key={row.key}
            error={openSnack && !row.teacherType ? 'true' : false}
            value={row.teacherType}
            onChange={(e) => {
              const rowKey = row.key;
              const rowIndex = tempTeachingCountryData.findIndex((i) => i.key === rowKey);
              const rowData = tempTeachingCountryData[rowIndex];
              rowData.teacherType = e.target.value;
              const finalRowData = tempTeachingCountryData;
              finalRowData[rowIndex] = rowData;
              // tempTeachingCountryData = [...finalRowData]
              setTempTeachingCountryData([...finalRowData]);
              if (openSnack) {
                let error = false;
                tempTeachingCountryData.map((item) => {
                  if (!item.teacherType) {
                    error = true;
                  }
                  if (!item.country) {
                    error = true;
                  }
                  if (!item.startDate) {
                    error = true;
                  }
                  if (!item.countryStatus) {
                    error = true;
                  }
                });
                if (!error) {
                  setOpenSnack(false);
                }
              }
            }}
            // onChange={handleChange}
          >
            <MenuItem value={'Full Time'}>Full Time</MenuItem>
            <MenuItem value={'Part Time'}>Part Time</MenuItem>
          </Select>
        );
      }
    },
    {
      field: 'courseType',
      headerName: 'Course Type',
      // flex: 1.5,
      headerAlign: 'center',
      minWidth: 150,

      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (rowContent) => {
        const row = rowContent.row;
        console.log(row, 'testing 123');
        return (
          <Select
            id="status"
            // value={age}
            // {...register('teacherType')}
            // required="true"
            inputProps={{ 'aria-label': 'Without label' }}
            // className="modalRowElement"
            multiple
            sx={{
              width: 150,
              left: 0
            }}
            size="small"
            key={row.key}
            error={openSnack && !row.courseTypes ? 'true' : false}
            renderValue={(selected) => (
              <div
              // className={classes.chips}
              >
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={getCourseNameById(value,courseTypeData)}
                    clickable
                    deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                    // className={classes.chip}
                    // onDelete={(e, v1) => {
                    //   const index = selected.findIndex((item) => item === value);
                    //   selected.splice(index, 1);
                    //   setLanguage([...selected]);
                    // }}
                    onClick={() => console.log('clicked chip')}
                  />
                ))}
              </div>
            )}
            value={row.courseTypes || []}
            onChange={(e) => {
              console.log(e);
              const rowKey = row.key;
              const rowIndex = tempTeachingCountryData.findIndex((i) => i.key === rowKey);
              const rowData = tempTeachingCountryData[rowIndex];
              rowData.courseTypes = e.target.value;
              const finalRowData = tempTeachingCountryData;
              finalRowData[rowIndex] = rowData;
              // tempTeachingCountryData = [...finalRowData]
              console.log(finalRowData, 'final row data');
              setTempTeachingCountryData([...finalRowData]);
              if (openSnack) {
                let error = false;
                tempTeachingCountryData.map((item) => {
                  if (!item.teacherType) {
                    error = true;
                  }
                  if (!item.country) {
                    error = true;
                  }
                  if (!item.startDate) {
                    error = true;
                  }
                  if (!item.countryStatus) {
                    error = true;
                  }
                });
                if (!error) {
                  setOpenSnack(false);
                }
              }
            }}
            // onChange={handleChange}
          >
            {courseInfoData
              .filter((item) => item.courseType && item.courseType && item.courseType !== '')
              .map((item) => {
                return (
                  <MenuItem key={item.courseType} value={item.courseType}>
                    {getCourseNameById(item.courseType,courseTypeData)}
                  </MenuItem>
                );
              })}
          </Select>
        );
      }
    },
    {
      field: 'primary',
      headerName: 'Primary Country',
      // flex: 2,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (rowContent) => {
        const row = rowContent.row;

        return (
          <RadioGroup
            sx={{
              width: 150,
              left: 0
            }}
            // className="modalRowElement"
            row
            // {...register('swami')}
            key={`primaryCountry${row.key}`}
            // required="true"
            value={row.primaryCountry}
            disabled={unDisabledRow !== null && +unDisabledRow !== +row.key}
            onChange={(e) => {
              const rowKey = row.key;
              const rowIndex = tempTeachingCountryData.findIndex((i) => i.key === rowKey);
              const rowData = tempTeachingCountryData[rowIndex];
              rowData.primaryCountry = e.target.value;
              const finalRowData = tempTeachingCountryData;
              finalRowData[rowIndex] = rowData;
              // tempTeachingCountryData = [...finalRowData]
              setTempTeachingCountryData(finalRowData);
              if (+e.target.value === 1) {
                setUnDisabledRow(rowKey);
                setOpenPrimaryCountrySnack(false);
              }
              if (+e.target.value === 0) {
                setUnDisabledRow(null);
              }
            }}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group">
            <FormControlLabel
              value={1}
              control={<Radio required="true" />}
              label="Yes"
              disabled={unDisabledRow !== null && +unDisabledRow !== +row.key}
            />
            <FormControlLabel
              value={0}
              control={<Radio required="true" />}
              label="No"
              disabled={unDisabledRow !== null && +unDisabledRow !== +row.key}
            />
          </RadioGroup>
        );
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      // flex: 1,
      minWidth: 150,

      headerAlign: 'center',
      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (rowContent) => {
        const row = rowContent.row;

        return (
          <>
            <span
              onClick={(event) => {
                setDeleteRowKey(row.key);
                handleClickOpen();
              }}
              className="deleteBtn">
              Delete
            </span>
            <Dialog
              open={open}
              onClose={handleCloseDeleteRow}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              {/* <DialogTitle id="alert-dialog-title">
       {"Use Google's location service?"}
     </DialogTitle> */}
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDeleteRow}>No</Button>
                <Button
                  onClick={() => {
                    let data = tempTeachingCountryData;
                    const rowKey = deleteRowKey;
                    const rowData = tempTeachingCountryData.find((item) => +item.key === +rowKey);

                    data = data.filter((item) => +item.key !== +rowKey);
                    // setRowKey(rowKey + 1);
                    if (+rowData.primaryCountry == 1) {
                      setUnDisabledRow(null);
                    }

                    setTempTeachingCountryData([...data]);
                    setOpen(false);
                  }}
                  autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </>
        );
      }
    }
  ];

  return (
    <div>
      <PageTemplate tabBar navbar>
        <Box sx={{ width: '100%', marginTop: '40px' }}>
          {isSubmitSuccessful ? (
            <ReactLoading type="bars" color="#fff" />
          ) : (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (teachingCountryData.length > 0) {
                  clearErrors('teachingCountryTable');
                }

                if (courseInfoData.length !== 0) {
                  // error = true;
                  let courseInfoError = false;
                  courseInfoData.map((item) => {
                    if (
                      item.courseType === null ||
                      item.certificationType === null ||
                      !item.courseType ||
                      !item.certificationType
                    ) {
                      courseInfoError = true;
                    }
                  });

                  if (!courseInfoError) {
                    clearErrors('courseInformation');
                  }
                }

                handleSubmit(onSubmit)(e);
              }}>
              <Paper
                sx={{ paddingLeft: '16px' }}
                className="navbar-full-container-2"
                variant="outlined"
                elevation={9}>
                <Grid container spacing={0} xs={8} sx={{ marginLeft: '16px' }}>
                  <Grid xs={4} item sx={{ marginTop: '16px' }}>
                    <h2>New Teacher </h2>
                  </Grid>
                  {console.log(getValues('teacherStatus'), '7654890')}

                  <Grid
                    xs={8}
                    item
                    sx={{ marginTop: '16px', paddingBottom: '12px', width: '400' }}
                    elevation={3}>
                    <Stepper alternativeLabel>
                      {/* {steps.map((label) => ( */}
                      <Step
                        active="true"
                        className="step"
                        onClick={() => {
                          // personalInfoRef.current.scrollIntoView()
                          personalInfoRef.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'end',
                            inline: 'nearest'
                          });
                        }}>
                        <StepLabel>Personal Info</StepLabel>
                      </Step>
                      <Step
                        active="true"
                        className="step"
                        onClick={() =>
                          contactRef.current.scrollIntoView({
                            behavior: 'smooth'
                          })
                        }>
                        <StepLabel>Contact Info</StepLabel>
                      </Step>
                      <Step
                        active="true"
                        className="step"
                        onClick={() =>
                          teachingRef.current.scrollIntoView({
                            behavior: 'smooth'
                          })
                        }>
                        <StepLabel>Teaching Info</StepLabel>
                      </Step>
                      <Step
                        active="true"
                        className="step"
                        onClick={() =>
                          myRef.current.scrollIntoView({
                            behavior: 'smooth'
                            // block: 'end',
                            // inline: 'nearest'
                          })
                        }>
                        <StepLabel>Course Info</StepLabel>
                      </Step>
                      {/* ))} */}
                    </Stepper>
                  </Grid>
                </Grid>
              </Paper>
              <Paper
                className="form-container"
                sx={{ top: '250px', paddingTop: '32px', overflow: 'hidden' }}>
                <Paper
                  container
                  sx={{
                    marginTop: '32px',
                    marginLeft: '48px',
                    marginRight: '48px',
                    marginBottom: '10px',
                    backgroundColor: '#4286d4',
                    color: 'white',
                    borderRadius: '8px',
                    padding: '10px'
                  }}>
                  <h3>Personal Information</h3>
                </Paper>
                <Paper
                  sx={{ paddingBottom: '20px', marginLeft: '48px', marginRight: '48px' }}
                  ref={personalInfoRef}>
                  {' '}
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      First Name <span className="required">*</span>
                    </Grid>
                    <Grid item xs={8}>
                      <span>
                        {' '}
                        <OutlinedInput
                          {...register('firstName')}
                          id="firstName"
                          error={!!fieldValidationErrors.firstName && watch('firstName') === ''}
                          type="text"
                          name="firstName"
                          placeholder="Enter first name"
                          sx={{ width: 450, marginLeft: 10 }}
                          size="small"
                          // required="true"
                        />
                      </span>

                      <span style={{ color: 'red', marginLeft: '16px' }}>
                        {fieldValidationErrors.firstName && watch('firstName') === '' && 'Required'}
                      </span>
                    </Grid>
                    {imageLoaded && imageKey && (
                      <img
                        width="200"
                        style={{ position: 'absolute', right: '100px' }}
                        height="200"
                        src={`https://tptm-aold.s3.ap-south-1.amazonaws.com/${imageKey}`}
                      />
                    )}
                  </Grid>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Last Name <span className="required">*</span>
                    </Grid>
                    <Grid item xs={8}>
                      <span>
                        <OutlinedInput
                          {...register('lastName')}
                          id="lastName"
                          type="text"
                          name="lastName"
                          placeholder="Enter last name"
                          error={!!fieldValidationErrors.lastName && watch('lastName') === ''}
                          helperText={errors?.firstName?.message}
                          sx={{ width: 450, marginLeft: 10 }}
                          size="small"
                          // required="true"
                        />
                      </span>

                      <span style={{ color: 'red', marginLeft: '16px' }}>
                        {fieldValidationErrors.lastName && watch('lastName') === '' && 'Required'}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Alias Name 1
                    </Grid>
                    <Grid item xs={5}>
                      <OutlinedInput
                        {...register('aliasName1')}
                        id="aliasName1"
                        type="text"
                        name="aliasName1"
                        placeholder="Enter alias name 1"
                        // error={!!errors.firstName}
                        // helperText={errors?.firstName?.message}
                        sx={{ width: 450, marginLeft: 10 }}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Alias Name 2
                    </Grid>
                    <Grid item xs={5}>
                      <OutlinedInput
                        {...register('aliasName2')}
                        id="aliasName2"
                        type="text"
                        name="aliasName2"
                        placeholder="Enter alias name 2"
                        // error={!!errors.firstName}
                        // helperText={errors?.firstName?.message}
                        sx={{ width: 450, marginLeft: 10 }}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Gender <span className="required">*</span>
                    </Grid>
                    <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
                      <span>
                        <RadioGroup
                          sx={{ width: 450, marginLeft: 10 }}
                          row
                          // {...register('gender')}
                          value={stateFormData.gender}
                          // value="male"
                          onChange={(e) => handleFormDataChange(e.target.value, 'gender')}
                          // required="true"
                          // defaultValue={teachersData.basicinfo[0].gender}

                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group">
                          <FormControlLabel
                            value="Female"
                            {...register('gender')}
                            control={
                              <Radio
                              //  required="true"
                              />
                            }
                            label="Female"
                          />
                          <FormControlLabel
                            value="Male"
                            {...register('gender')}
                            control={
                              <Radio
                              //  required="true"
                              />
                            }
                            label="Male"
                          />
                          <FormControlLabel
                            value="Other"
                            {...register('gender')}
                            control={
                              <Radio
                              // required="true"
                              />
                            }
                            label="Other"
                          />
                        </RadioGroup>
                      </span>
                      <span style={{ color: 'red', marginLeft: '16px' }}>
                        {fieldValidationErrors.gender && watch('gender') === null && 'Required'}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid
                    ref={contactRef}
                    container
                    sx={{ marginTop: '0px', marginLeft: '20px' }}
                    spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Date Of Birth <span className="required">*</span>
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      sx={{
                        width: 450,
                        marginLeft: 10,
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                      {/* <input
                    {...register('expiredOn')}
                    required="true"
                    type="date"
                    id="expiredOn"
                    name="expiredOn"
                  /> */}

                      <span>
                        <TextField
                          {...register('dob')}
                          // required="true"
                          type="date"
                          error={!!fieldValidationErrors.dob && watch('dob') === ''}
                          id="dob"
                          name="dob"
                        />
                      </span>
                      <span style={{ color: 'red', marginLeft: '16px' }}>
                        {fieldValidationErrors.dob && watch('dob') === '' && 'Required'}
                        {fieldValidationErrors.dobTooYoung &&
                          moment().diff(moment(watch('dob')), 'years') < 18 &&
                          'DOB should be atleast 18 years'}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Upload Photo
                    </Grid>
                    <Grid item xs={10}>
                      <span style={{ display: 'flex', flexDirection: 'row' }}>
                        <span>
                          <TextField
                            sx={{ width: 450, marginLeft: 10 }}
                            size="small"
                            type="file"
                            accept="image/*"
                            inputProps={{
                              accept: 'image/*',
                              value: stateFormData.filePath || undefined,
                              ref: imageUploadRef
                            }}
                            onChange={(e) => {
                              // handleFormDataChange(e.target.value, 'filePath');
                              handleUpload(e);
                            }}
                            // ref="upload"
                            id="componentfgdg-outslined"
                            helperText="File format must be .jpg, .jpeg or .png. Maximum file size must be 2 MB"
                          />
                        </span>
                        {stateFormData.filePath && (
                          <span>
                            <Button
                              variant="text"
                              underline="hover"
                              sx={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
                              onClick={() => {
                                handleFormDataChange('', 'filePath');
                                setImageKey(undefined);
                                setFileName(undefined);
                                const fileInput = document.querySelector('input[type="file"]');
                                const dataTransfer = new DataTransfer();
                                fileInput.files = dataTransfer.files;
                              }}>
                              Clear Photo
                            </Button>
                          </span>
                        )}
                      </span>
                    </Grid>

                    {console.log(imageUploadRef, 'ref debug image')}
                  </Grid>
                  {imageUploadFailed && (
                    <Alert severity="error"> Unable to Upload. Please Try Again</Alert>
                  )}
                  {imageTooLarge !== '' && <Alert severity="error"> {imageTooLarge}</Alert>}
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}></Grid>
                    {/* {imageLoaded && (
                      <Grid item xs={6} sx={{ width: 450, marginLeft: 10 }}>
                        <img
                          width="400"
                          height="200"
                          src={`https://tptm-aold.s3.ap-south-1.amazonaws.com/${imageKey}`}
                        />
                      </Grid>
                    )} */}
                  </Grid>
                </Paper>
                <Paper
                  container
                  sx={{
                    marginTop: '32px',
                    marginLeft: '48px',
                    marginRight: '48px',
                    marginBottom: '10px',
                    backgroundColor: '#4286d4',
                    color: 'white',
                    borderRadius: '8px',
                    padding: '10px'
                  }}>
                  <h3>Contact Information</h3>
                </Paper>
                <Paper
                  //  ref={contactRef}
                  sx={{ paddingBottom: '20px', marginLeft: '48px', marginRight: '48px' }}>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Email ID <span className="required">*</span>
                    </Grid>
                    <Grid item xs={10}>
                      <span>
                        {' '}
                        <OutlinedInput
                          {...register('emailId', {
                            // required: 'Please enter your email address',
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: 'Invalid Email Address'
                            }
                          })}
                          id="emailId"
                          error={
                            (!!fieldValidationErrors.emailId && watch('emailId') === '') ||
                            emailValidationError
                          }
                          type="text"
                          name="emailId"
                          placeholder="Enter Email ID"
                          sx={{ width: 450, marginLeft: 10 }}
                          onChange={() => {
                            setEmailValidationError(false);
                            console.log(emailValidationError, ' .   ==>> . asd');
                          }}
                          size="small"

                          // onChange={e => delayedEmailValue(e.target.value)}
                          // onChange={() => delayedEmailTrigger()}
                          // required="true"
                          // onClick={() => {
                          //   setAlternateNumber(true);
                          // }}
                        />
                      </span>

                      <span style={{ color: 'red', marginLeft: '16px' }}>
                        {errors.emailId?.message}
                        {fieldValidationErrors.emailId && watch('emailId') === '' && 'Required'}
                        {emailValidationError && 'Email Already Exists'}
                      </span>
                      <Grid item xs={6} sx={{ width: 450, marginLeft: 10 }}>
                        <FormHelperText>Please Enter Harmony Email ID</FormHelperText>
                      </Grid>
                    </Grid>

                    {/* <Grid
                      item
                      xs={4}
                      sx={{ marginLeft: 20, display: 'flex', alignItems: 'center', color: 'red' }}>
                      <span>{errors.emailId?.message}</span>
                    </Grid> */}
                  </Grid>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Mobile <span className="required">*</span>
                    </Grid>
                    <Grid container item xs={10}>
                    {console.log(courseInfoData, 'test 456')}
                      <Multiselect
                        // style={ chips: { background: "red" }, searchBox: { border: "none", "border-bottom": "1px solid blue", "border-radius": "0px" } }
                        style={
                          isFormSubmitted && !stateFormData.mobile1Code
                            ? { searchBox: { borderColor: 'red' } }
                            : {}
                        }
                        displayValue="key"
                        // singleSelect="true"
                        placeholder=""
                        hidePlaceholder="true"
                        closeOnSelect={true}
                        // onKeyPressFn={function noRefCheck() {}}
                        onRemove={() => {
                          handleFormDataChange(null, 'mobile1Code');
                        }}
                        // onSearch={function noRefCheck() {}}
                        selectedValueDecorator={(string) => `+ ${string.split(' ')[1]}`}
                        // optionValueDecorator={string => string.split(' ').at(-1)}
                        onSelect={(e) => {
                          handleFormDataChange(e[0].code, 'mobile1Code');
                        }}
                        options={countryTelData.allCountries.map((item) => {
                          return {
                            key: `+ ${item.dialCode}  ${item.name}`,
                            value: `+ ${item.dialCode}`,
                            code: item.dialCode
                          };
                        })}
                        selectionLimit={1}
                      />

                      <span>
                        <OutlinedInput
                          {...register('mobile1')}
                          id="mobile1"
                          type="number"
                          name="mobile1"
                          placeholder="Enter Mobile Number"
                          error={
                            (!!fieldValidationErrors.mobile1 && watch('mobile1') === '') ||
                            (watch('mobile1') && watch('mobile1').toString().length > 13)
                          }
                          // error={!!errors.firstName}
                          // helperText={errors?.firstName?.message}
                          sx={{ width: 330, height: '40px' }}
                          size="small"
                          // required="true"
                        />
                      </span>
                      <span
                        style={{
                          color: 'red',
                          marginLeft: '16px',
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                        {console.log(watch('mobile1'), 'major debug mobile 1')}
                        {fieldValidationErrors.mobile1 &&
                          (watch('mobile1') === '' || !stateFormData.mobile1Code) &&
                          'Required'}
                        {watch('mobile1') &&
                          watch('mobile1').toString().length > 13 &&
                          'cannot be greater than 13 digits'}
                      </span>
                      {!alternateNumber && (
                        <Button
                          variant="text"
                          underline="hover"
                          sx={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
                          onClick={() => {
                            setAlternateNumber(true);
                          }}>
                          + Alternate Phone
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                  {alternateNumber && (
                    <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        Alternate Number <span className="required">*</span>
                      </Grid>
                      <Grid container item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Multiselect
                          // className="MuiSelect-select MuiSelect-outlined MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputSizeSmall css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input"
                          // style={{ width: 120, marginLeft: 20 }}
                          displayValue="key"
                          placeholder=""
                          closeOnSelect="true"
                          hidePlaceholder="true"
                          onKeyPressFn={function noRefCheck() {}}
                          onRemove={() => {
                            handleFormDataChange(null, 'mobile2Code');
                          }}
                          onSearch={function noRefCheck() {}}
                          selectedValueDecorator={(string) => `+ ${string.split(' ')[1]}`}
                          style={
                            isFormSubmitted && !stateFormData.mobile2Code
                              ? { searchBox: { borderColor: 'red' } }
                              : {}
                          }
                          // optionValueDecorator={string => string.split(' ').at(-1)}
                          onSelect={(e) => {
                            handleFormDataChange(e[0].code, 'mobile2Code');
                          }}
                          options={countryTelData.allCountries.map((item) => {
                            return {
                              key: `+ ${item.dialCode}  ${item.name}`,
                              value: `+ ${item.dialCode}`,
                              code: item.dialCode
                            };
                          })}
                          selectionLimit={1}
                        />
                        {console.log(
                          fieldValidationErrors,
                          stateFormData,
                          watch('mobile2'),
                          'field validation errors'
                        )}
                        <span>
                          <OutlinedInput
                            {...register('mobile2')}
                            id="mobile2"
                            type="number"
                            name="mobile2"
                            placeholder="Enter Alternate Number"
                            error={fieldValidationErrors.mobile2 && watch('mobile2') === ''}
                            // error={!!errors.firstName}
                            // helperText={errors?.firstName?.message}
                            sx={{ width: 330, height: '40px' }}
                            size="small"
                          />
                        </span>
                        <span style={{ color: 'red', marginLeft: '16px' }}>
                          {watch('mobile2') &&
                            watch('mobile2').toString().length > 13 &&
                            'cannot be greater than 13 digits'}
                          {fieldValidationErrors.mobile2 &&
                            (watch('mobile2') === '' || !stateFormData.mobile2Code) &&
                            'Required'}
                        </span>
                        <Button
                          variant="text"
                          underline="hover"
                          sx={{
                            textTransform: 'none',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                          onClick={() => {
                            setAlternateNumber(false);
                            handleFormDataChange(null, 'mobile2Code');
                            reset({ mobile2: '' });
                          }}>
                          Remove Alternate Phone
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Address Line 1 <span className="required">*</span>
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                      <span>
                        <OutlinedInput
                          {...register('addLine1')}
                          id="addLine1"
                          type="text"
                          name="addLine1"
                          placeholder="Enter Address Line 1"
                          // required="true"
                          // error={!!errors.firstName}
                          // helperText={errors?.firstName?.message}
                          sx={{ width: 450, marginLeft: 10 }}
                          error={!!fieldValidationErrors.addLine1 && watch('addLine1') === ''}
                          size="small"
                        />
                      </span>
                      <span style={{ color: 'red', marginLeft: '16px' }}>
                        {fieldValidationErrors.addLine1 && watch('addLine1') === '' && 'Required'}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Address Line 2
                    </Grid>
                    <Grid item xs={6}>
                      <OutlinedInput
                        {...register('addLine2')}
                        id="addLine2"
                        type="text"
                        name="addLine2"
                        placeholder="Enter Address Line 2"
                        // error={!!errors.firstName}
                        // helperText={errors?.firstName?.message}
                        sx={{ width: 450, marginLeft: 10 }}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Country <span className="required">*</span>
                    </Grid>
                    <Grid item xs={8}>
                      <span>
                        <Select
                          id="country"
                          // value={age}
                          // {...register('country')}
                          // required="true"
                          sx={{ width: 450, marginLeft: 10 }}
                          inputProps={{ 'aria-label': 'Without label' }}
                          size="small"
                          // value="Bahamas"
                          value={stateFormData.country}
                          onChange={(e, data) => {
                            handleFormDataChange(e.target.value, 'country');
                            handleFormDataChange(data.key, 'countryCode');
                            const token = sessionStorage.getItem('user');
                              axios
                              .get(`${process.env.REACT_APP_BASE_API_URL}/teachers/getstatebycountryid`, {
                                params: {
                                  id: e.target.value
                                },
                                headers: { authorization: `Bearer ${token}` }
                              })
                              .then((response) => {
                                setStateData(response.data);
                              });


                            // const stateData = cityData.Countries.find(
                            //   (item) => item.CountryName === e.target.value
                            // ).States;
                            // setStateList(stateData);
                          }}
                          MenuProps={{ sx: { height: '500px' } }}
                          error={!!fieldValidationErrors.country && !stateFormData.country}
                          // onChange={handleChange}
                        >
                          {
                            countryData.map(item => {
                              return (
                                <MenuItem key={item.isoCode} name={item.isoCode} value={item.countryid}>
                                  {item.countryname}
                                </MenuItem>
                              );
                            })
                          }
                        </Select>
                      </span>
                      <span style={{ color: 'red', marginLeft: '16px' }}>
                        {fieldValidationErrors.country && !stateFormData.country && 'Required'}
                      </span>
                      <Grid item xs={6} sx={{ width: 450, marginLeft: 10 }}>
                        <FormHelperText>Current country of residence</FormHelperText>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      State <span className="required">*</span>
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                      <span>
                        <Select
                          id="state"
                          // value={age}
                          // {...register('state')}
                          sx={{ width: 450, marginLeft: 10 }}
                          inputProps={{ 'aria-label': 'Without label' }}
                          size="small"
                          // required="true"
                          MenuProps={{ sx: { height: '500px' } }}
                          value={stateFormData.state}
                          error={!!fieldValidationErrors.state && !stateFormData.state}
                          onChange={(e, data) => {
                            handleFormDataChange(e.target.value, 'state');
                            handleFormDataChange(data.key, 'stateCode');
                            console.log(data, 'state major debug 12345');

                            // const cityData = stateList.find(
                            //   (item) => item.StateName === e.target.value
                            // ).Cities;

                            // setCityList(cityData);
                          }}>
                          {stateFormData.countryCode &&
                            stateData.length > 0 &&
                            stateData.map((item) => {
                              return (
                                <MenuItem key={item.isoCode} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          {stateFormData.countryCode &&
                            stateData.length === 0 && (
                              <MenuItem key="0" value="13040">
                                None
                              </MenuItem>
                            )}
                        </Select>
                      </span>
                      <span style={{ color: 'red', marginLeft: '16px' }}>
                        {fieldValidationErrors.state && !stateFormData.state && 'Required'}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid
                    ref={teachingRef}
                    container
                    sx={{ marginTop: '0px', marginLeft: '20px' }}
                    spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      City <span className="required">*</span>
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                      <span>
                        <OutlinedInput
                          {...register('city')}
                          id="city"
                          type="text"
                          name="city"
                          placeholder="City"
                          // required="true"
                          // error={!!errors.firstName}
                          // helperText={errors?.firstName?.message}
                          sx={{ width: 450, marginLeft: 10 }}
                          error={!!fieldValidationErrors.city && watch('city') === ''}
                          size="small"
                          onChange={(e) => handleFormDataChange(e.target.value, 'city')}
                        />
                      </span>
                      <span style={{ color: 'red', marginLeft: '16px' }}>
                        {fieldValidationErrors.city && !stateFormData.city && 'Required'}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      ZIP / PIN Code
                    </Grid>
                    <Grid item xs={6}>
                      <OutlinedInput
                        {...register('pincode')}
                        id="pincode"
                        type="text"
                        name="pincode"
                        placeholder="Enter ZIP / PIN CODE"
                        // error={!!errors.firstName}
                        // helperText={errors?.firstName?.message}
                        sx={{ width: 450, marginLeft: 10 }}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Paper>
                {
                  <RenderCourseInformation
                    courseInfoData={courseInfoData}
                    setCourseInfoData={setCourseInfoData}
                    countryData={countryData}
                    courseTypeData={courseTypeData}
                    ttpCertificationTypeData={ttpCertificationTypeData}
                    ttpTypeData={ttpTypeData}
                    ttpLocationData={ttpLocationData}
                    courseInfoRowKey={courseInfoRowKey}
                    setCourseInfoRowKey={setCourseInfoRowKey}
                    isFormSubmitted={isFormSubmitted}
                    // myRef={myRef}
                  />
                }
                <Paper
                  container
                  sx={{
                    marginTop: '32px',
                    marginLeft: '48px',
                    marginRight: '48px',
                    marginBottom: '10px',
                    backgroundColor: '#4286d4',
                    color: 'white',
                    borderRadius: '8px',
                    padding: '10px'
                  }}>
                  <h3>Teaching Information</h3>
                </Paper>
                <Paper sx={{ paddingBottom: '20px', marginLeft: '48px', marginRight: '48px' }}>
                  {/* <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                  <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                    Teacher Code <span className="required">*</span>
                  </Grid>
                  <Grid item xs={3}>
                  <OutlinedInput
                      {...register('teacherCode')}
                      id="teacherCode"
                      type="text"
                      name="teacherCode"
                      placeholder="Enter Teacher Code"
                      sx={{ width: 450, marginLeft: 10 }}
                      size="small"
                      required="true"
                    />
                  </Grid/>
                  <Grid item xs={3} sx={{}}>
                    {generatedCode ? (
                      generatedCode
                    ) : (
                      <Button
                        variant="text"
                        sx={{ display: 'flex', alignItems: 'center' }}
                        onClick={() => {
                          const token = sessionStorage.getItem('user');
                          axios
                            .get(`${process.env.REACT_APP_BASE_API_URL}/teachers/generatecode` , headers: {authorization : `Bearer ${token}`})
                            .then((response) => {
                              setGeneratedCode(response.data);
                            });
                        }}>
                        Generate
                      </Button>
                    )}
                  </Grid>

                </Grid> */}

                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      <span>Teacher Activity Status </span> <span className="required">*</span>
                    </Grid>
                    <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
                      <span>
                        {console.log(stateFormData, teacherStatus, 'teacher status debug')}
                        {!statusLoader && (
                          <Select
                            id="teacherStatus"
                            // value={age}
                            {...register('teacherStatus')}
                            sx={{ width: 450, marginLeft: 10 }}
                            size="small"
                            // required="true"
                            value={stateFormData.teacherStatus}
                            // onChange={(e) => handleFormDataChange(e.target.value, 'teacherStatus')}
                            onChange={(e) => {
                              if (e.target.value === 'Active') {
                                handleFormDataChange(e.target.value, 'teacherStatus');
                                setTeacherStatus(e.target.value);
                              }

                              if (e.target.value === 'Inactive') {
                                e.preventDefault();
                                setOpenInactiveStatusPopUp(true);
                              }
                              if (e.target.value === 'View only') {
                                e.preventDefault();
                                setOpenViewOnlyStatusPopup(true);
                              }
                            }}
                            error={
                              !!fieldValidationErrors.teacherStatus && !stateFormData.teacherStatus
                            }
                            inputProps={{ 'aria-label': 'Without label' }}>
                            <MenuItem value={'Active'}>Active</MenuItem>
                            <MenuItem value={'Inactive'}>Inactive</MenuItem>
                            <MenuItem value={'View only'}>View Only</MenuItem>
                          </Select>
                        )}
                      </span>
                      <span style={{ color: 'red', marginLeft: '16px' }}>
                        {fieldValidationErrors.teacherStatus &&
                          !stateFormData.teacherStatus &&
                          'Required'}
                      </span>
                    </Grid>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      {teacherStatus === 'Inactive' && (
                        <>
                          Teacher Activity Sub Status <span className="required">*</span>
                        </>
                      )}
                    </Grid>
                    {teacherStatus === 'Inactive' && (
                      <Grid item xs={10} sx={{ width: 450 }}>
                        <span>
                          <Select
                            id="teacherStatus2"
                            inputProps={{ 'aria-label': 'Without label' }}
                            // value={age}
                            {...register('teacherStatus2')}
                            sx={{ width: 450, marginLeft: 10 }}
                            size="small"
                            error={
                              !!fieldValidationErrors.teacherStatus2 &&
                              !stateFormData.teacherStatus2
                            }
                            onChange={(e) => {
                              handleFormDataChange(e.target.value, 'teacherStatus2');
                            }}>
                            <MenuItem value={'Not with Organization'}>
                              Not with Organization
                            </MenuItem>
                            <MenuItem value={'Return Kriya Tape and Manual'}>
                              Return Kriya Tape and Manual
                            </MenuItem>
                            <MenuItem value={'Deceased'}>Deceased</MenuItem>
                            <MenuItem value={'Suspended'}>Suspended</MenuItem>
                            <MenuItem value={'Not Teaching'}>Not Teaching</MenuItem>
                          </Select>
                        </span>
                        <span style={{ color: 'red', marginLeft: '16px' }}>
                          {fieldValidationErrors.teacherStatus2 &&
                            !stateFormData.teacherStatus2 &&
                            'Required'}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Harmony/CRM Code
                    </Grid>
                    <Grid item xs={6}>
                      <OutlinedInput
                        {...register('harmonyCode')}
                        id="harmonyCode"
                        type="text"
                        name="harmonyCode"
                        placeholder="Enter Harmony Code"
                        sx={{ width: 450, marginLeft: 10 }}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                  {/* <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                  <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    Current Country <span className="required">*</span>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      id="currentCountry"
                      // value={age}
                      {...register('currentCountry')}
                      sx={{ width: 450, marginLeft: 10 }}
                      size="small"
                      required="true"
                      // onChange={handleChange}
                      label="Enter Current Country">
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </Grid>
                </Grid> */}
                  {/* <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                  <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    Teacher Type <span className="required">*</span>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl>
                      <RadioGroup
                        sx={{ width: 450, marginLeft: 10 }}
                        row
                        {...register('teacherType')}
                        required="true"
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group">
                        <FormControlLabel
                          value="fullTime"
                          {...register('teacherType')}
                          control={<Radio required="true" />}
                          label="Full Time"
                        />
                        <FormControlLabel
                          value="partTime"
                          {...register('teacherType')}
                          control={<Radio required="true" />}
                          label="Part Time"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid> */}
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Swami Ji / Sadhvi Ji <span className="required">*</span>
                    </Grid>
                    {console.log(tempTeachingCountryData, teachingCountryData, '127896')}
                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                      <span>
                        <FormControl>
                          <RadioGroup
                            sx={{ width: 450, marginLeft: 10 }}
                            row
                            // {...register('swami')}
                            // required="true"
                            value={stateFormData.swami}
                            onChange={(e) => handleFormDataChange(e.target.value, 'swami')}
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group">
                            <FormControlLabel
                              value="yes"
                              {...register('swami')}
                              control={
                                <Radio
                                //  required="true"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              value="no"
                              {...register('swami')}
                              control={
                                <Radio
                                //  required="true"
                                />
                              }
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </span>
                      <span style={{ color: 'red', marginLeft: '16px' }}>
                        {fieldValidationErrors.swami && !stateFormData.swami && 'Required'}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Full Time Office Bearer <span className="required">*</span>
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                      <span>
                        <FormControl>
                          <RadioGroup
                            sx={{ width: 450, marginLeft: 10 }}
                            row
                            value={stateFormData.officeBearer}
                            onChange={(e) => handleFormDataChange(e.target.value, 'officeBearer')}
                            name="officeBearer"
                            id="officeBearer"
                            // {...register('officeBearer')}
                            // required="true"
                            aria-labelledby="demo-row-radio-buttons-group-label">
                            <FormControlLabel
                              value="yes"
                              {...register('officeBearer')}
                              control={
                                <Radio
                                //  required="true"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              value="no"
                              {...register('officeBearer')}
                              control={
                                <Radio
                                //  required="true"
                                />
                              }
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </span>
                      <span style={{ color: 'red', marginLeft: '16px' }}>
                        {fieldValidationErrors.officeBearer &&
                          !stateFormData.officeBearer &&
                          'Required'}
                      </span>
                    </Grid>
                  </Grid>
                  {/* <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                  <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    Travelling Teacher <span className="required">*</span>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl>
                      <RadioGroup
                        sx={{ width: 450, marginLeft: 10 }}
                        row
                        id="travellingTeacher"
                        {...register('travellingTeacher')}
                        required="true"
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="travellingTeacher">
                        <FormControlLabel
                          value="yes"
                          {...register('travellingTeacher')}
                          control={<Radio required="true" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          {...register('travellingTeacher')}
                          control={<Radio required="true" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid> */}
                  {/* </Grid> */}

                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Launguages <span className="required">*</span>
                    </Grid>
                    <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
                      <span>
                        {console.log(
                          fieldValidationErrors,
                          watch('languages'),
                          'language erroor',
                          language
                        )}

                        <Select
                          id="languages"
                          value={language}
                          {...register('languages')}
                          MenuProps={{ sx: { height: '500px' } }}
                          multiple
                          sx={{ width: 450, marginLeft: 10 }}
                          size="small"
                          // required="true"
                          inputProps={{ 'aria-label': 'Without label' }}
                          error={!!fieldValidationErrors.languages && language.length === 0}
                          onChange={(e) => {
                            setLanguage([...e.target.value]);
                          }}
                          renderValue={(selected) => (
                            <div
                            // className={classes.chips}
                            >
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={value}
                                  clickable
                                  deleteIcon={
                                    <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
                                  }
                                  // className={classes.chip}
                                  onDelete={(e, v1) => {
                                    const index = selected.findIndex((item) => item === value);
                                    selected.splice(index, 1);
                                    setLanguage([...selected]);
                                  }}
                                  onClick={() => console.log('clicked chip')}
                                />
                              ))}
                            </div>
                          )}>
                          {Object.keys(languagesData).map((item) => (
                            <MenuItem key={item} value={languagesData[item]}>
                              {languagesData[item]}
                            </MenuItem>
                          ))}
                        </Select>
                        <span style={{ color: 'red', marginLeft: '16px' }}>
                          {fieldValidationErrors.languages && language.length === 0 && 'Required'}
                        </span>
                        <Grid item xs={6} sx={{ width: 450, marginLeft: 10 }}>
                          <FormHelperText style={{ width: '550px' }}>
                            This teacher can teach courses in the selected language
                          </FormHelperText>
                        </Grid>
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        textAlign: 'left'
                      }}>
                      <span
                      // style={{ whiteSpace: 'nowrap' }}
                      >
                        Teacher Agreement Signed?{' '}
                        <span style={{ marginRight: 'auto' }} className="required">
                          *
                        </span>
                      </span>
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                      <span>
                        <FormControl>
                          <RadioGroup
                            sx={{ width: 450, marginLeft: 10 }}
                            row
                            id="teacherAgreementSigned"
                            // {...register('teacherAgreementSigned')}
                            // required="true"

                            value={stateFormData.teacherAgreementSigned}
                            onChange={(e) =>
                              handleFormDataChange(e.target.value, 'teacherAgreementSigned')
                            }
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group">
                            <FormControlLabel
                              value="yes"
                              {...register('teacherAgreementSigned')}
                              control={
                                <Radio
                                //  required="true"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              value="no"
                              {...register('teacherAgreementSigned')}
                              control={
                                <Radio
                                //  required="true"
                                />
                              }
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </span>
                      <span style={{ color: 'red', marginLeft: '16px' }}>
                        {fieldValidationErrors.teacherAgreementSigned &&
                          watch('teacherAgreementSigned') === null &&
                          'Required'}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Teacher Agreement Signed On
                    </Grid>
                    <Grid item xs={6} sx={{ width: 450, marginLeft: 10 }}>
                      <TextField
                        {...register('signedOn')}
                        type="date"
                        id="signedOn"
                        name="signedOn"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    ref={myRef}
                    container
                    sx={{ marginTop: '0px', marginLeft: '20px' }}
                    spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      Teacher Agreement Expires On
                    </Grid>
                    <Grid item xs={6} sx={{ width: 450, marginLeft: 10 }}>
                      <TextField
                        {...register('expiredOn')}
                        type="date"
                        id="expiredOn"
                        name="expiredOn"
                        error={expiredOnError() || errors.expiredOn}
                      />
                    </Grid>
                    {console.log(errors, 'error debug')}
                  </Grid>
                  {errors.expiredOn ||
                    (expiredOnError() && (
                      <Alert sx={{ marginTop: '12px' }} severity="error">
                        {expiredOnError()
                          ? 'Teaching Expiry Date cannot be less than Teaching Start Date'
                          : ''}
                      </Alert>
                    ))}
                  <Grid container sx={{ marginTop: '0px', marginLeft: '20px' }} spacing={2}>
                    <Grid
                      item
                      xs={2}
                      sx={{ display: 'flex', alignItems: 'top', flexDirection: 'row' }}>
                      <span> Teaching Country </span>
                      <span className="required">*</span>
                    </Grid>
                    <Grid item xs={10}>
                      <Grid sx={{ width: 450, marginLeft: 10 }}>
                        <Link
                          href="#"
                          underline="hover"
                          // sx={{ width: 450, marginLeft: 10 }}
                          // onClick={() => {
                          //   const courseInfoData = courseInfoData;
                          //   courseInfoData.push({});
                          //   setCourseInfoData(courseInfoData);
                          // }}

                          onClick={() => {
                            // if (teachingCountryData.length === 0) {
                            // let tempData = teachingCountryData.push({ key: rowKey });
                            // tempData.push({ key: rowKey });

                            console.log('moved out of country debug', stateFormData);
                            if (teachingCountryData.length === 0) {
                              let temp = JSON.parse(JSON.stringify(teachingCountryData));
                              if (stateFormData.teacherStatus === 'Inactive') {
                                temp.push({
                                  key: rowKey,
                                  countryStatus: 'Inactive',
                                  endDate: moment().format('YYYY-MM-DD')
                                });
                              } else if (stateFormData.teacherStatus === 'View only') {
                                temp.push({
                                  key: rowKey,
                                  countryStatus: 'Moved out of country',
                                  endDate: moment().format('YYYY-MM-DD')
                                });
                              } else {
                                temp.push({ key: rowKey });
                              }
                              setTempTeachingCountryData(temp);
                            } else {
                              setTempTeachingCountryData(
                                JSON.parse(JSON.stringify(teachingCountryData))
                              );
                            }
                            setSavedUndisabledRow(unDisabledRow);
                            // setTeachingCountryData([{ key: rowKey }]);
                            setRowKey(rowKey + 1);
                            // }
                            setOpenModal(true);
                          }}>
                          Add / Edit Teaching Country
                        </Link>
                      </Grid>
                      {/* <Grid style={{ color: 'red' }}>

                      </Grid> */}
                    </Grid>
                    {/* <Grid container sx={{ marginTop: '0px', marginLeft: '0px' }} spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sx={{ display: 'flex', alignItems: 'top', flexDirection: 'row' }}> */}

                    {/* </Grid>
                    </Grid> */}

                    <Grid container sx={{ marginTop: '12px', marginLeft: '20px' }}>
                      {teachingCountryData.length > 0 && (
                        <TableContainer
                          sx={{ width: 800, maxHeight: 240 }}
                          // component={Paper}
                        >
                          <Table sx={{}} size="small" aria-label="a dense table">
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }} align="left">
                                  Country Name
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="left">
                                  Teaching Start Date
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="left">
                                  Teaching Expiry Date
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="left">
                                  Country Level Status
                                </TableCell>
                                {/* <TableCell align="right">Country  Type</TableCell> */}
                                <TableCell sx={{ fontWeight: 'bold' }} align="left">
                                  Teacher Type
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="left">
                                  Course Type
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="left">
                                  Primary Country
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {tcd.map((row) => (
                                <TableRow
                                  key={row.name}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                  <TableCell align="left">{getCountryName(row.country)}</TableCell>
                                  <TableCell align="left">
                                    {row.startDate &&
                                      format(new Date(row.startDate.substring(0, 10)))}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.endDate && format(new Date(row.endDate.substring(0, 10)))}
                                  </TableCell>
                                  <TableCell align="left">{row.countryStatus}</TableCell>
                                  <TableCell align="left">{row.teacherType}</TableCell>
                                  <TableCell align="left">
                                    {row.courseTypes &&
                                      row.courseTypes.map((item, i) => {
                                        if (i !== row.courseTypes.length - 1) return `${getCourseNameById(item,courseTypeData)}, `;
                                        return getCourseNameById(item,courseTypeData);
                                      })}
                                  </TableCell>

                                  <TableCell align="left">
                                    {+row.primaryCountry === 1 ? 'Yes' : ''}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </Grid>
                  </Grid>
                  {errors.teachingCountryTable && teachingCountryData.length === 0 && (
                    // 'Required'
                    <div>
                      <Alert severity="error">
                        {' '}
                        {errors.teachingCountryTable ? errors.teachingCountryTable.message : ''}
                      </Alert>
                    </div>
                  )}
                </Paper>

                <div
                  sx={{
                    marginLeft: '48px',
                    marginTop: '32px'
                  }}>
                  {' '}
                  {submitError && <Alert severity="error"> {'SUBMIT FAILED'}</Alert>}
                  {emailValidationError && (
                    <Alert severity="error"> {'Email Already Exists'}</Alert>
                  )}
                  {showFieldValidationErrorOnSubmit && (
                    <Alert severity="error">
                      {' '}
                      {'Please provide required input for all fields and resubmit'}
                    </Alert>
                  )}
                  {errors.mobile1 && (
                    <Alert severity="error"> {errors.mobile1 ? errors.mobile1.message : ''}</Alert>
                  )}
                  {errors.mobile2 && (
                    <Alert severity="error"> {errors.mobile2 ? errors.mobile2.message : ''}</Alert>
                  )}
                  {errors.expiredOn && (
                    <Alert severity="error">
                      {' '}
                      {errors.expiredOn ? errors.expiredOn.message : ''}
                    </Alert>
                  )}
                </div>

                <Grid
                  container
                  spacing={2}
                  sx={{ marginTop: '32px', marginLeft: '48px', paddingBottom: '20px' }}>
                  <Grid item>
                    <Grid item>
                      <Button
                        type="submit"
                        onClick={() => {
                          // window.scrollTo(0, 0);
                          // navigate('/teachers')
                        }}
                        sx={{ textTransform: 'none' }}
                        variant="contained">
                        Save and Done
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Button
                      sx={{ textTransform: 'none' }}
                      onClick={() => {
                        // window.scrollTo(0, 0);
                        setIsSaveAndNew(true);
                        // navigate('/teachers')
                      }}
                      type="submit"
                      variant="contained">
                      Save and New
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="text"
                      sx={{ textTransform: 'none', textDecoration: 'underline' }}
                      onClick={() => {
                        // window.scrollTo(0, 0);

                        navigate(-1);
                      }}>
                      Cancel
                    </Button>
                    {/* {openModal && (
          <TeacherModal
            ttpCertificationTypeData={ttpCertificationTypeData}
            openModal={openModal}
            teachingCountryData={teachingCountryData}
            setOpenModal={(val) => setOpenModal(val)}
            setTeachingCountryData={(data) => setTeachingCountryData(data)}
          />
        )} */}
                  </Grid>
                </Grid>
              </Paper>
            </form>
          )}
        </Box>
        <Modal
          open={openModal}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={modalStyle}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ marginBottom: '16px', fontWeight: 'bold', overflowX: 'hidden' }}>
              Add/Edit Teaching Country
            </Typography>
            <TableContainer
              sx={{
                marginBottom: '16px',
                maxHeight: '40vh'
                // overflowX: 'hidden'
              }}
              component={Paper}>
              {/* <Table
                sx={{
                  minWidth: 650
                  // overflowX: 'hidden'
                }}
                aria-label="simple table"> */}
              {console.log('data grid', modalRows, modalColumns)}

              <DataGrid
                localeText={{
                  noRowsLabel: 'No records found'
                }}
                rows={modalRows}
                autoHeight
                sx={{
                  '.MuiDataGrid-columnSeparator': {
                    display: 'none'
                  },
                  '&.MuiDataGrid-root': {
                    border: 'none'
                  },
                  '.MuiDataGrid-columnHeader': {
                    fontSize: 16,
                    fontWeight: 'bold'
                  },
                  '.MuiDataGrid-sortIcon': {
                    opacity: 'inherit !important',
                    color: 'black'
                  },
                  '& .MuiDataGrid-iconButtonContainer': {
                    visibility: 'visible !important'
                  },
                  '& .css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 700,
                    fontSize: '14px'
                  },
                  '& .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root': {
                    color: 'black'
                  },
                  '& .MuiDataGrid-iconButtonContainer': {
                    display: 'none'
                  },
                  '& .MuiDataGrid-iconButtonContainer1': {
                    paddingLeft: '5px',
                    color: 'black'
                  }
                }}
                columns={modalColumns}
                hideFooter={true}
              />

              {/* </Table> */}
            </TableContainer>
            <Link
              sx={{ marginBottom: '16px' }}
              className="hoverPointer"
              onClick={() => {
                const data = tempTeachingCountryData;
                if (stateFormData.teacherStatus === 'Inactive') {
                  data.push({
                    key: rowKey,
                    countryStatus: 'Inactive',
                    endDate: moment().format('YYYY-MM-DD')
                  });
                } else if (stateFormData.teacherStatus === 'View only') {
                  data.push({
                    key: rowKey,
                    countryStatus: 'Moved out of country',
                    endDate: moment().format('YYYY-MM-DD')
                  });
                } else {
                  data.push({ key: rowKey });
                }
                setRowKey(rowKey + 1);

                // const newData = [...teachingCountryData, { key: rowKey + 1 }];
                setTempTeachingCountryData(data);
              }}>
              +Add New Row
            </Link>
            {/* {showError && <>'PLEASE FILL ALL REQUIRED FIELDS'</>} */}
            {/* {showError && ( */}
            {openSnack && (
              <Alert sx={{ marginTop: '16px' }} severity="error">
                Please fill all the Required Fields
              </Alert>
            )}
            {!openSnack && openPrimaryCountrySnack && (
              <Alert sx={{ marginTop: '16px' }} severity="error">
                At least one country should be primary
              </Alert>
            )}

            {countryAlreadySelected && (
              <Alert sx={{ marginTop: '16px' }} severity="error">
                Country already selected, please select a different country{' '}
              </Alert>
            )}
            {openEndDateSnack && (
              <Alert sx={{ marginTop: '16px' }} severity="error">
                Teaching Expiry Date cannot be less than Teaching Start Date{' '}
              </Alert>
            )}

            <Grid container spacing={2} sx={{ marginTop: '32px' }}>
              <Grid item>
                <Grid item>
                  <Button
                    type="submit"
                    onClick={() => {
                      let error = false;
                      let count = 0;
                      let primaryCountryError = false;
                      let endDateError = false;
                      tempTeachingCountryData.map((item) => {
                        if (!item.teacherType) {
                          error = true;
                        }
                        if (!item.country) {
                          error = true;
                        }
                        if (!item.startDate) {
                          error = true;
                        }
                        if (!item.countryStatus) {
                          error = true;
                        }
                        if (+item.primaryCountry === 1) {
                          count++;
                        }
                        if (item.endDate) {
                          if (
                            moment(item.startDate && item.startDate.substring(0, 10)).isAfter(
                              item.endDate && item.endDate.substring(0, 10)
                            )
                          ) {
                            endDateError = true;
                          }
                        }
                      });
                      if (count === 0 && tempTeachingCountryData.length > 0) {
                        // error = true;
                        primaryCountryError = true;
                      }
                      if (error) {
                        setOpenSnack(true);
                      }
                      if (primaryCountryError) {
                        setOpenPrimaryCountrySnack(true);
                      }

                      if (endDateError) {
                        setOpenEndDateSnack(true);
                      }

                      if (!error && !primaryCountryError && !endDateError) {
                        setTeachingCountryData([...tempTeachingCountryData]);

                        setOpenModal(false);
                      }
                    }}
                    variant="contained">
                    Save and Close
                  </Button>
                  <Button
                    sx={{ marginLeft: '16px' }}
                    onClick={() => {
                      setOpenModal(false);
                      setUnDisabledRow(savedUnDisabledRow);
                      setOpenSnack(false);
                      setOpenPrimaryCountrySnack(false);
                      setOpenEndDateSnack(false);
                      setCountryAlreadySelected(false);
                    }}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Dialog
          open={openInactiveStatusPopUp}
          onClose={() => setOpenInactiveStatusPopUp(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          // maxWidth={'xs'}
          style={{ width: '70%vw' }}>
          <DialogContent style={{ padding: '50px' }}>
            <DialogContentText>
              Teacher level status and all the teaching country(ies) level status will be changed to
              Inactive. Are you sure you want to continue?{' '}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: '15px' }}>
            <Link
              // to={link_url}
              style={{ textDecoration: 'none' }}>
              <Button
                variant="contained"
                style={{ paddingLeft: '20px', paddingRight: '20px' }}
                onClick={() => handleMakeCountryStatusInactive()}
                autoFocus>
                Yes
              </Button>
              <Button
                variant="contained"
                style={{ paddingLeft: '20px', paddingRight: '20px', marginLeft: '12px' }}
                onClick={() => {
                  handleFormDataChange(null, 'teacherStatus');
                  setTeacherStatus(null);
                  setOpenInactiveStatusPopUp(false);
                  resetField('teacherStatus');
                  setStatusLoader(true);
                  setTimeout(() => {
                    setStatusLoader(false);
                  }, 1);
                }}
                autoFocus>
                No
              </Button>
            </Link>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openViewOnlyStatusPopup}
          onClose={() => setOpenViewOnlyStatusPopup(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          style={{ width: '70%vw' }}>
          <DialogContent style={{ padding: '50px' }}>
            <DialogContentText>
              All the teaching country(ies) level status will be changed to Moved Out of Country.
              Are you sure you want to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: '15px' }}>
            <Link
              // to={link_url}
              style={{ textDecoration: 'none' }}>
              <Button
                variant="contained"
                style={{ paddingLeft: '20px', paddingRight: '20px' }}
                onClick={() => hanldeMakeCountryStatusOutOfCountry()}
                autoFocus>
                Yes
              </Button>
              <Button
                variant="contained"
                style={{ paddingLeft: '20px', paddingRight: '20px', marginLeft: '12px' }}
                onClick={() => {
                  handleFormDataChange(null, 'teacherStatus');
                  setTeacherStatus(null);
                  setOpenViewOnlyStatusPopup(false);
                  setStatusLoader(true);
                  setTimeout(() => {
                    setStatusLoader(false);
                  }, 1);
                }}
                autoFocus>
                No
              </Button>
            </Link>
          </DialogActions>
        </Dialog>
      </PageTemplate>
    </div>
  );
}

export default TeachersNewPage;

import React, { useState } from 'react';
import styles from '../Global.module.css';
import AOLlogo from '../AOLlogo.png';
import { Link } from 'react-router-dom';
import * as axios from 'axios';
import * as yup from 'yup';
import Form from '../../../UiCore/FormComponent/FormFeild/FormFeild';
import InputFormFeild from '../../../UiCore/FormComponent/InputFormFeild/InputFormFeild';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { blue } from '@mui/material/colors';

const forgotpasswordSchema = yup.object().shape({
  Username: yup.string().required()
});

var msg, link_url;
export default function ForgotPassword() {
  // const onSubmit = (data) => {
  //   console.log("data is", data);
  // };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let onSubmit = (data) => {
    // console.log(data)
    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/ad/forgotpwd`, {
        params: { username: data.Username.toLowerCase() }
      })
      .then((response) => {
        {
          if (response.data.message === 'yes') {
            msg = `We have sent a password reset link to your email ${data.Username}. <br> <br> Please check your email to access the link.`;
            link_url = '/login';
            setOpen(true);
          } else {
            // setOpen(true)
            msg = 'Please enter a valid Username';
            link_url = '/forgot-password';
            setOpen(true);
          }
        }
      });
  };
  return (
    <div className={styles.outerContainer}>
      <div className={styles.container}>
        <img src={AOLlogo} className={styles.img} />
        <div className={styles.heading} style={{ fontSize: '20px' }}>
          Forgot Password
        </div>
        <Form onSubmit={onSubmit} schema={forgotpasswordSchema}>
          <label className={styles.label}>USERNAME</label>
          <InputFormFeild
            placeholder="Enter email address"
            name="Username"
            className={styles.input}
            variant={'outlined'}></InputFormFeild>
          <br />
          <Link
            to="/login"
            style={{
              textDecoration: 'none',
              color: 'blue',
              marginRight: 'auto',
              marginTop: '10px',
              color: '#94a6fb',
              fontWeight: '600'
            }}>
            <div className={styles.link}>Go back to login page</div>
          </Link>
          <Button
            type="submit"
            variant="contained"
            style={{
              marginTop: '20px',
              marginBottom: '20px',
              height: '50px',
              fontWeight: '700',
              textTransform: 'uppercase'
            }}
            className={styles.button}>
            Send
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            maxWidth={'xs'}
            fullWidth={true}
            aria-describedby="alert-dialog-description">
            <DialogContent style={{ margin: '5px', marginBottom: '0px' }}>
              <DialogContentText style={{ color: 'black'}}>
                <p dangerouslySetInnerHTML={{ __html: msg }}></p>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ margin: '5px', marginTop: '0px' }}>
              <Link to={link_url} style={{ textDecoration: 'none' }}>
                <Button
                  variant="contained"
                  style={{ paddingLeft: '40px', paddingRight: '40px' }}
                  className={'dialogActionBtn'}
                  onClick={handleClose}
                  autoFocus>
                  Ok
                </Button>
              </Link>
            </DialogActions>
          </Dialog>
        </Form>
      </div>
    </div>
  );
}

/* eslint-disable react/prop-types */
import React from 'react';
import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import data from './data.json';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import { Menu, MenuItem } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
export default function Data_table(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rowData, setRowData] = React.useState([]);
  const [listOrder, setListOrder] = React.useState('ASC');

  const [isParentSelectedRows, setIsParentSelectedRows] = useState(true);
  useEffect(() => {
    setIsParentSelectedRows(false);
  }, []);
  function handleSortHover(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }
  useEffect(() => {
    // console.log(props.data);
    if (props.quickSearch) {
      setRowData(
        props.data.filter((item) =>
          item.Country.toLowerCase().includes(props.quickSearch.toLowerCase())
        )
      );
    } else {
      setRowData(props.data);
    }
  }, [props.data, props.quickSearch]);

  function handleClose() {
    setAnchorEl(null);
  }
  const [selection, setselection] = useState([]);
  let rows = [];

  if (listOrder === 'ASC') {
    rows = rowData
      .map((row) => {
        return {
          id: row.Country,
          active: row.Active || 0,
          inactive: row.Inactive || 0,
          viewonly: row.ViewOnly || 0,
          totalteachers:
            parseInt(row.Active || 0) + parseInt(row.Inactive || 0) + parseInt(row.ViewOnly || 0)
        };
      })
      .sort((a, b) => {
        // console.log(a, b);
        const nameA = a.id.toUpperCase(); // ignore upper and lowercase
        const nameB = b.id.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
  } else {
    rows = rowData
      .map((row) => {
        return {
          id: row.Country,
          active: row.Active || 0,
          inactive: row.Inactive || 0,
          viewonly: row.ViewOnly || 0,
          totalteachers:
            parseInt(row.Active || 0) + parseInt(row.Inactive || 0) + parseInt(row.ViewOnly || 0)
        };
      })
      .sort((a, b) => {
        // console.log(a, b);
        const nameA = a.id.toUpperCase(); // ignore upper and lowercase
        const nameB = b.id.toUpperCase(); // ignore upper and lowercase
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        return 0;
      });
  }

  const columns = [
    {
      field: 'id',
      headerName: 'Country',
      minWidth: 230,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => {
        return (
          <span style={{ fontSize: '14px' }}>
            Country{' '}
            <IconButton>
              {' '}
              <UnfoldMoreIcon onMouseOver={handleSortHover} />{' '}
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{ marginTop: '8px' }}
                MenuListProps={{ onMouseLeave: handleClose }}>
                <MenuItem
                  sx={
                    listOrder === 'ASC'
                      ? {
                          '&:hover': {
                            backgroundColor: '#1976D2'
                          },
                          '&': {
                            backgroundColor: '#1976D2'
                          }
                        }
                      : {
                          '&:hover': {
                            backgroundColor: '#DAD9D9'
                          }
                        }
                  }
                  onClick={() => {
                    setRowData(
                      rowData.sort((a, b) => {
                        // console.log(a, b);
                        const nameA = a.Country.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.Country.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                          return -1;
                        }
                        if (nameA > nameB) {
                          return 1;
                        }
                        return 0;
                      })
                    );
                    setListOrder('ASC');
                  }}>
                  A <ArrowForwardIcon sx={{ transform: 'scale(0.8,0.8)' }} /> Z
                </MenuItem>
                <MenuItem
                  sx={
                    listOrder === 'DESC'
                      ? {
                          '&:hover': {
                            backgroundColor: '#1976D2'
                          },
                          '&': {
                            backgroundColor: '#1976D2'
                          }
                        }
                      : {
                          '&:hover': {
                            backgroundColor: '#DAD9D9'
                          }
                        }
                  }
                  onClick={() => {
                    props.data.sort((a, b) => {
                      // console.log(a, b);
                      const nameA = a.Country.toUpperCase(); // ignore upper and lowercase
                      const nameB = b.Country.toUpperCase(); // ignore upper and lowercase
                      if (nameA > nameB) {
                        return -1;
                      }
                      if (nameA < nameB) {
                        return 1;
                      }

                      // names must be equal
                      return 0;
                    });
                    setListOrder('DESC');
                  }}>
                  {' '}
                  Z <ArrowForwardIcon sx={{ transform: 'scale(0.8,0.8)' }} /> A
                </MenuItem>
              </Menu>
            </IconButton>
          </span>
        );
      }
    },
    {
      field: 'active',
      headerName: 'Active',
      minWidth: 190,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'inactive',
      headerName: 'Inactive',
      minWidth: 190,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'viewonly',
      headerName: 'View Only',
      minWidth: 190,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'totalteachers',
      headerName: 'Total Teachers',
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      disableColumnMenu: true
    }
  ];
  const ColumnMenuIcon = UnfoldMoreIcon;

  let components = {};
  if (props.renderToolbarMaterial) {
    components = {
      ColumnMenuIcon: ColumnMenuIcon,
      Toolbar: props.toolBar
    };
  } else {
    components = {
      ColumnMenuIcon: ColumnMenuIcon
    };
  }

  return (
    <>
      {props.toolBar}
      <div
        style={{
          height: props.height ? props.height : 'auto',
          width: '100%'
        }}>
        <DataGrid
          {...data}
          components={components}
          rows={rows}
          columns={columns}
          disableColumnSelector={true}
          localeText={{
            noRowsLabel: 'No records found'
          }}
          checkboxSelection
          loading={rows.length === 0}
          sx={{
            '.MuiTableSortLabel-icon': {
              display: 'block'
            },
            '.MuiDataGrid-columnSeparator': {
              display: 'none'
            },
            '&.MuiDataGrid-root': {
              border: 'none'
            },
            '.MuiDataGrid-columnHeader': {
              fontSize: 16,
              fontWeight: 'bold'
            },
            'MuiDataGrid-iconButtonContainer': {
              visibility: 'visible'
            },
            '.MuiDataGrid-sortIcon': {
              opacity: 'inherit !important',
              color: 'black'
            },
            '& .MuiDataGrid-iconButtonContainer': {
              visibility: 'visible !important'
            },
            '& .css-xa1zdc-MuiDataGrid-root .MuiDataGrid-menuOpen': {
              visibility: 'visible',
              width: 'auto'
            },
            '& .css-xa1zdc-MuiDataGrid-root .MuiDataGrid-menuIcon': {
              visibility: 'visible'
            },
            '& .css-pvqpge-MuiDataGrid-root .MuiDataGrid-menuIcon ': {
              visibility: 'visible !important'
            },
            '& .css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
              fontWeight: 700,
              fontSize: '14px'
            },
            '& .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root': {
              color: 'black'
            },
            '& .MuiDataGrid-iconButtonContainer': {
              display: 'none'
            },
            '& .MuiDataGrid-iconButtonContainer1': {
              paddingLeft: '5px',
              color: 'black'
            }
          }}
          hideFooter={false}
          selectionModel={props.selectedRows}
          onSelectionModelChange={(newSelection, e) => {
            console.log(newSelection, e, 'selection debug');
            /**
             * calling props.selectedRows only after completion of  intial render  with the help of isParentSelectRows, if we didn't this consotion it is doing empty the selectedRows also.
             */
            !isParentSelectedRows && props.setSelectedRows(newSelection);
          }}
        />
      </div>
    </>
  );
}
